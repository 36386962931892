import { action } from '@ember/object';
import { Section } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import type RouterService from 'tio-employee/services/router';

export default class SyfAccountLinkingRouteComponent extends Component {
  @service declare router: RouterService;

  @action
  didLink(institution: FinancialInstitutionModel) {
    this.router.transitionTo('authenticated.syf.linking-confirmation', institution.legacyId);
  }

  @action
  didCancel() {
    this.router.transitionTo('authenticated.syf');
  }

  <template>
    <Section>
      <:header>{{t "account_linking.add_account"}}</:header>
      <:body>
        {{!-- <AccountLinkingSyf @onSuccess={{this.didLink}} @onCanceled={{this.didCancel}} /> --}}
      </:body>
    </Section>
  </template>
}
