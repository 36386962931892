import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import Badge from 'tio-ui/components/badge';

interface DashboardTileSignature {
  Args: {
    headerText?: string;
    isUrgent?: boolean;
    showAdminBadge?: boolean;
  };
  Blocks: {
    body: [];
    description: [];
    footer: [];
    header: [];
    image: [];
  };
}

const DashboardTileComponent: TOC<DashboardTileSignature> = <template>
  <div class="shadow-sm border aspect-square hover:shadow-md w-full h-full relative bg-white">
    <div
      class="border-b border-gray-100 px-4 sm:px-2 py-3 h-10 flex flex-row justify-between items-center text-base md:text-base uppercase"
    >
      {{#if (has-block "header")}}
        {{yield to="header"}}
      {{else}}
        <span class="truncate">{{@headerText}}</span>
        {{#if @isUrgent}}
          <span class="font-semibold text-red-700">
            {{t "dashboard_tiles.to_do_now"}}
          </span>
        {{/if}}
        {{#if @showAdminBadge}}
          <Badge @intent="primary">
            {{t "dashboard_tiles.admin"}}
          </Badge>
        {{/if}}
      {{/if}}
    </div>
    <div
      class="p-4 sm:p-2 xl:p-4 flex flex-col w-full h-[calc(100%-2.5rem)] overflow-hidden text-gray-800 text-base md:text-sm xl:text-base"
    >
      {{#if (has-block "body")}}
        {{yield to="body"}}
      {{else}}
        <div class="mb-2">{{yield to="description"}}</div>
        <div class="flex justify-center items-center grow overflow-hidden">
          {{yield to="image"}}
        </div>
      {{/if}}
    </div>
    {{#if (has-block "footer")}}
      <div class="absolute bottom-0 right-0">
        {{yield to="footer"}}
      </div>
    {{/if}}
  </div>
</template>;

export default DashboardTileComponent;
