import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';

<template>
  <LinkTo @route="authenticated.repaying-student-debt.loan-forgiveness">
    <Tile @headerText={{t "dashboard_tiles.loan_forgiveness"}}>
      <:description>
        <span>{{t "dashboard_tiles.learn_about_fed_forgiveness"}}</span>
        <p class="font-semibold my-4 text-xl">{{t "dashboard_tiles.what_is_lf"}}</p>
        <span>{{t "dashboard_tiles.lf_criteria"}}</span>
      </:description>
    </Tile>
  </LinkTo>
</template>
