import { VStack } from 'tio-ui/components/layout';
import NewTypeTwoOrThreeApp from 'tio-employee/components/tas/applications/new/type-two-or-three';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';
import type { TOC } from '@ember/component/template-only';

export interface S {
  Args: {
    model: {
      application: TasApplicationModel;
      programInstance: TASProgramInstanceModel;
      selectedSchool: TasUnifiedInstitutionModel | undefined;
    };
  };
}

const tmpl: TOC<S> = <template>
  <VStack class="max-h-[calc(100vh-4.75rem)] overflow-y-auto">
    <NewTypeTwoOrThreeApp @model={{@model}} />
  </VStack>
</template>;
export default tmpl;
