import { VStack } from 'tio-ui/components/layout';
import NewTypeOneOrFourProgram from 'tio-employee/components/tas/programs/new/type-one-or-four';
import type { TOC } from '@ember/component/template-only';
import type TasGroupModel from 'tio-common/models/tas-group';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasUnifiedInstitutionModel from 'tio-common/models/tas-unified-institution';

export interface S {
  Args: {
    model: {
      activeInstances: TASProgramInstanceModel[];
      programInstance: TASProgramInstanceModel;
      selectedSchool: TasUnifiedInstitutionModel | undefined;
      tasGroups: TasGroupModel[];
    };
  };
}

const tmpl: TOC<S> = <template>
  <VStack>
    <NewTypeOneOrFourProgram @model={{@model}} />
  </VStack>
</template>;

export default tmpl;
