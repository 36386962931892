import { t } from 'ember-intl';
import QuestionsLoanTypesCard from 'tio-employee/components/questions/loan-types-card';
import type { TOC } from '@ember/component/template-only';

interface CircleSignature {
  Args: {
    label: string;
  };
}

const Circle: TOC<CircleSignature> = <template>
  <div
    class="shrink-0 w-6 h-6 bg-cyan-600 mr-2 text-white rounded-full text-center items-center inline-block"
  >
    {{@label}}
  </div>
</template>;

<template>
  <h3 class="text-center font-semibold">
    {{t "questions_you_have.types_of_student_loans.pros.pros_federal"}}
  </h3>
  <div class="flex-row grid grid-cols-3 mt-4 justify-center">
    <QuestionsLoanTypesCard
      @src="/assets/images/questions/icons/down-arrow.svg"
      @label={{t "questions_you_have.types_of_student_loans.pros.lower_interest"}}
    />
    <div class="border-x">
      <QuestionsLoanTypesCard
        @src="/assets/images/questions/icons/upward-joining-arrow.svg"
        @label={{t "questions_you_have.types_of_student_loans.pros.repayment_options"}}
      />
    </div>
    <QuestionsLoanTypesCard
      @src="/assets/images/questions/icons/like.svg"
      @label={{t "questions_you_have.types_of_student_loans.pros.check_or_collateral"}}
    />
  </div>
  <div class="flex-row mt-4 grid grid-cols-2 justify-center">
    <QuestionsLoanTypesCard
      @src="/assets/images/questions/icons/consolidated.svg"
      @label={{t "questions_you_have.types_of_student_loans.pros.consolidation"}}
    />
    <QuestionsLoanTypesCard
      @src="/assets/images/questions/icons/figure-with-slash.svg"
      @label={{t "questions_you_have.types_of_student_loans.pros.do_not_accrue"}}
    />
  </div>

  <h3 class="text-center font-semibold mt-4">
    {{t "questions_you_have.types_of_student_loans.cons.cons_federal"}}
  </h3>
  <div class="flex-row mt-4 grid grid-cols-3 justify-center">
    <QuestionsLoanTypesCard
      @src="/assets/images/questions/icons/alert.svg"
      @label={{t "questions_you_have.types_of_student_loans.cons.subsidized_limit"}}
    />
    <QuestionsLoanTypesCard
      @src="/assets/images/questions/icons/bars.svg"
      @label={{t "questions_you_have.types_of_student_loans.cons.unsubsidized_limit"}}
      class="border-x"
    />
    <QuestionsLoanTypesCard
      @src="/assets/images/questions/icons/double-chevrons-pointing-inward.svg"
      @label={{t "questions_you_have.types_of_student_loans.cons.higher_interest"}}
    />
  </div>
  <div class="p-4">
    <h1 class="py-4 font-medium text-xl">
      {{t "questions_you_have.how_to_apply.default"}}
    </h1>
    <div class="py-4">
      <h3 class="font-semibold truncate text-left">
        <Circle @label={{t "number_one"}} />
        {{t "questions_you_have.how_to_apply.submit_your_fafsa.default" htmlSafe=true}}
      </h3>
      <ul class="list-disc ml-12 mb-4">
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.submit_your_fafsa.point_1" htmlSafe=true}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.submit_your_fafsa.point_2"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.submit_your_fafsa.point_3"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.submit_your_fafsa.point_4"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.submit_your_fafsa.point_5"}}
        </li>
      </ul>
    </div>
    <div class="py-4">
      <h3 class="font-semibold truncate text-left">
        <Circle @label={{t "number_two"}} />
        {{t "questions_you_have.how_to_apply.receive_your_SAR.default" htmlSafe=true}}
      </h3>
      <ul class="list-disc ml-12 mb-4">
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.receive_your_SAR.point_1"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.receive_your_SAR.point_2"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.receive_your_SAR.point_3"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.receive_your_SAR.point_4"}}
        </li>
      </ul>
    </div>
    <div class="py-4">
      <h3 class="font-semibold truncate text-left">
        <Circle @label={{t "number_three"}} />
        {{t "questions_you_have.how_to_apply.check_application_requirements.default" htmlSafe=true}}
      </h3>
      <ul class="list-disc ml-12 mb-4">
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.check_application_requirements.point_1"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.check_application_requirements.point_2"}}
        </li>
      </ul>
    </div>
    <div class="py-4">
      <h3 class="font-semibold truncate text-left">
        <Circle @label={{t "number_four"}} />
        {{t "questions_you_have.how_to_apply.receive_award_letter.default" htmlSafe=true}}
      </h3>
      <ul class="list-disc ml-12 mb-4">
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.receive_award_letter.point_1"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.receive_award_letter.point_2"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.receive_award_letter.point_3"}}
        </li>
      </ul>
    </div>
    <div class="py-4">
      <h3 class="font-semibold truncate text-left">
        <Circle @label={{t "number_five"}} />
        {{t "questions_you_have.how_to_apply.entrance_counseling.default" htmlSafe=true}}
      </h3>
      <ul class="list-disc ml-12 mb-4">
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.entrance_counseling.point_1"}}
        </li>
      </ul>
    </div>
    <div class="py-4">
      <h3 class="font-semibold truncate text-left">
        <Circle @label={{t "number_six"}} />
        {{t "questions_you_have.how_to_apply.accept_fa_package.default" htmlSafe=true}}
      </h3>
      <ul class="list-disc ml-12 mb-4">
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.accept_fa_package.point_1"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.accept_fa_package.point_2"}}
        </li>
        <li class="m-4">
          {{t "questions_you_have.how_to_apply.accept_fa_package.point_3"}}
        </li>
      </ul>
    </div>
  </div>
</template>
