import { Header, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import ApplicationConfigReview from 'tio-common/components/tuition-assistance/program-template-review/application-config-review';
import ApproverInstructionsAndFaqs from 'tio-common/components/tuition-assistance/program-template-review/approver-instructions-and-faqs';
import CourseApplicationSetup from 'tio-common/components/tuition-assistance/program-template-review/course-application-setup';
import Notifications from 'tio-common/components/tuition-assistance/program-template-review/notifications';
import ProgramSetupReview from 'tio-common/components/tuition-assistance/program-template-review/program-setup-review';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import type { TOC } from '@ember/component/template-only';

interface S {
  Args: {
    model: TasProgramTemplate;
  };
}

const tmpl: TOC<S> = <template>
  <VStack>
    <Header>{{t "common.program_review"}}</Header>
    <ProgramSetupReview @model={{@model}} />
    <ApplicationConfigReview @model={{@model}} />
    <CourseApplicationSetup @model={{@model}} />
    <ApproverInstructionsAndFaqs @model={{@model}} />
    <Notifications @model={{@model}} />
  </VStack>
</template>;

export default tmpl;
