import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';

<template>
  <QuestionsLoanForgiveness
    @indexRoute="authenticated.repaying-student-debt.loan-forgiveness.index"
    @incomeRoute="authenticated.repaying-student-debt.loan-forgiveness.income-driven"
    @teacherRoute="authenticated.repaying-student-debt.loan-forgiveness.teacher"
    @stateRoute="authenticated.repaying-student-debt.loan-forgiveness.state-and-local"
  >
    {{outlet}}
  </QuestionsLoanForgiveness>
</template>
