import { t } from 'ember-intl';
import { Header, Section, VStack } from 'tio-ui/components/layout';

<template>
  {{! Public Service Loan Forgiveness }}
  <VStack @collapsed={{true}}>
    <Header>{{t "pslf.questions.public_service.pslf_title"}}</Header>
    <Section>
      <VStack>
        <p>{{t "pslf.questions.public_service.pslf_description"}}</p>
        <ul class="pl-4 list-disc">
          <li>{{t "pslf.questions.public_service.do_not_refinance"}}</li>
        </ul>
      </VStack>
    </Section>
  </VStack>

  {{! Important Information }}
  <VStack @collapsed={{true}}>
    <Header>{{t "pslf.questions.public_service.important_info"}}</Header>
    <Section>
      <VStack>
        <p>{{t "pslf.questions.public_service.info_description"}}</p>
        <VStack @collapsed={{true}}>
          <Section>
            <:body>
              <h1 class="font-semibold text-gray-600">
                {{t "pslf.questions.public_service.repayment_plan_eligibility.title"}}
              </h1>
              <ul class="pl-4 list-disc">
                <li>
                  {{t "pslf.questions.public_service.repayment_plan_eligibility.item_1"}}
                </li>
                <li>
                  {{t "pslf.questions.public_service.repayment_plan_eligibility.item_2"}}
                </li>
                <li>
                  {{t "pslf.questions.public_service.repayment_plan_eligibility.item_3"}}
                </li>
              </ul>
            </:body>
          </Section>
          <Section>
            <:body>
              <h1 class="font-semibold text-gray-600">
                {{t "pslf.questions.public_service.ffel_or_perkins.title"}}
              </h1>
              <ul class="pl-4 list-disc">
                <li>
                  {{t "pslf.questions.public_service.ffel_or_perkins.item_1" htmlSafe=true}}
                </li>
                <li>{{t "pslf.questions.public_service.ffel_or_perkins.item_2"}}</li>
                <li>{{t "pslf.questions.public_service.ffel_or_perkins.item_3"}}</li>
              </ul>
            </:body>
          </Section>

          <Section>
            <:body>
              <h1 class="font-semibold text-gray-600">
                {{t "pslf.questions.public_service.direct_loans.title"}}
              </h1>
              <ul class="pl-4 list-disc">
                <li>
                  {{t "pslf.questions.public_service.direct_loans.item_1" htmlSafe=true}}
                </li>
                <li>
                  {{t "pslf.questions.public_service.direct_loans.item_2" htmlSafe=true}}
                </li>
              </ul>
            </:body>
          </Section>
          <Section>
            <:body>
              <h1 class="font-semibold text-gray-600">
                {{t "pslf.questions.public_service.unconsolidated_plus.title"}}
              </h1>
              <ul class="pl-4 list-disc">
                <li>
                  {{t "pslf.questions.public_service.unconsolidated_plus.item_1" htmlSafe=true}}
                </li>
                <li>
                  {{t "pslf.questions.public_service.unconsolidated_plus.item_2"}}
                </li>
              </ul>
            </:body>
          </Section>
          <Section>
            <h1 class="font-semibold text-gray-600">
              {{t "pslf.questions.public_service.questions.title"}}
            </h1>
            <ul class="pl-4 list-disc">
              <li>
                {{t "pslf.questions.public_service.questions.item_1" htmlSafe=true}}
              </li>
              <li>
                {{t "pslf.questions.public_service.questions.item_2" htmlSafe=true}}
              </li>
            </ul>
          </Section>
        </VStack>
      </VStack>
    </Section>
  </VStack>

  {{! Am I Elgible? }}
  <VStack @collapsed={{true}}>
    <Header>{{t "pslf.questions.am_i_eligible"}}</Header>
    <Section>
      <VStack>
        <div class="flex flex-col sm:grid sm:grid-cols-2 gap-4">
          <div>
            <h1 class="font-semibold text-gray-600">
              {{t "pslf.questions.public_service.borrower_eligibility.title"}}
            </h1>
            <p class="pt-4">
              {{t "pslf.questions.public_service.borrower_eligibility.description" htmlSafe=true}}
            </p>
            <div>
              <h1 class="pt-4 sm:pt-0 font-semibold text-gray-600">
                {{t "pslf.questions.public_service.which_repayment.title"}}
              </h1>
              <ul class="p-4 list-disc">
                <li>
                  {{t "pslf.questions.public_service.which_repayment.item_1"}}
                </li>
                <li>
                  {{t "pslf.questions.public_service.which_repayment.item_2"}}
                </li>
                <li>
                  {{t "pslf.questions.public_service.which_repayment.item_3"}}
                </li>
                <li>
                  {{t "pslf.questions.public_service.which_repayment.item_4"}}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h1 class="font-semibold text-gray-600">
              {{t "pslf.questions.public_service.loan_types.title"}}
            </h1>
            <ul class="p-4 list-disc">
              <li>{{t "pslf.questions.public_service.loan_types.item_1"}}</li>
              <li>{{t "pslf.questions.public_service.loan_types.item_2"}}</li>
            </ul>
          </div>
        </div>
      </VStack>
    </Section>
  </VStack>

  {{! Plan Details }}
  <VStack @collapsed={{true}}>
    <Header>{{t "pslf.questions.plan_details"}}</Header>
    <Section>
      <VStack>
        <div class="flex flex-col sm:grid sm:grid-cols-3">
          <div>
            <p class="font-semibold text-gray-600">
              {{t "pslf.questions.public_service.plan.forgiven_after.title"}}
            </p>
            <p>
              {{t "pslf.questions.public_service.plan.forgiven_after.description"}}
            </p>
          </div>
          <div class="sm:ml-5">
            <p class="font-semibold text-gray-600">
              {{t "pslf.questions.public_service.plan.max_forgiveness.title"}}
            </p>
            <p>
              {{t "pslf.questions.public_service.plan.max_forgiveness.description"}}
            </p>
          </div>
          <div>
            <p class="font-semibold text-gray-600">
              {{t "pslf.questions.public_service.plan.tax_info.title"}}
            </p>
            <p>{{t "pslf.questions.public_service.plan.tax_info.description"}}</p>
          </div>
        </div>

        <div class="flex flex-col sm:grid sm:grid-cols-2 gap-4">
          <div>
            <h1 class="font-semibold text-lg text-gray-600">
              {{t "pslf.questions.public_service.plan.pros.title"}}
            </h1>
            <ul class="pl-4 list-disc">
              <li>{{t "pslf.questions.public_service.plan.pros.item_1"}}</li>
              <li>{{t "pslf.questions.public_service.plan.pros.item_2"}}</li>
            </ul>
          </div>
          <div>
            <h1 class="font-semibold text-lg text-gray-600">
              {{t "pslf.questions.public_service.plan.cons.title"}}
            </h1>
            <ul class="pl-4 list-disc">
              <li>{{t "pslf.questions.public_service.plan.cons.item_1"}}</li>
              <li>{{t "pslf.questions.public_service.plan.cons.item_2"}}</li>
            </ul>
          </div>
        </div>
      </VStack>
    </Section>
  </VStack>

  {{! Next Steps }}
  <VStack @collapsed={{true}}>
    <Header>{{t "pslf.questions.next_steps"}}</Header>
    <Section>
      <VStack>
        <div class="flex flex-col sm:grid sm:grid-cols-2">
          <div class="pr-3">
            <h1 class="font-semibold text-gray-600">
              {{t "pslf.questions.public_service.make_the_switch.title"}}
            </h1>
            <ul class="p-4 list-disc">
              <li>{{t "pslf.questions.public_service.make_the_switch.item_1"}}</li>
              <li>
                {{t "pslf.questions.public_service.make_the_switch.item_2" htmlSafe=true}}
              </li>
              <li>
                {{t "pslf.questions.public_service.make_the_switch.item_3" htmlSafe=true}}
              </li>
              <li>{{t "pslf.questions.public_service.make_the_switch.item_4"}}</li>
            </ul>
            <h1 class="pt-6 font-semibold text-gray-600">
              {{t "pslf.questions.need_more_help.title"}}
            </h1>
            <p class="pt-4">
              {{t "pslf.questions.need_more_help.description" htmlSafe=true}}
            </p>
          </div>
          <div class="sm:pt-0 sm:pl-3">
            <h1 class="font-semibold text-gray-600">
              {{t "pslf.questions.public_service.stay_on_track.title"}}
            </h1>
            <ul class="p-4 list-disc">
              <li>
                {{t "pslf.questions.public_service.stay_on_track.item_1" htmlSafe=true}}
              </li>
              <li>
                {{t "pslf.questions.public_service.stay_on_track.item_2" htmlSafe=true}}
              </li>
              <li>{{t "pslf.questions.public_service.stay_on_track.item_3"}}</li>
            </ul>
          </div>
        </div>
      </VStack>
    </Section>
  </VStack>
</template>
