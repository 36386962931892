import { t } from 'ember-intl';
import TioFooter from 'tio-common/components/tio/footer';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

<template>
  <div class="flex flex-col h-screen container mx-auto overflow-auto">
    <div class="grow mx-auto mt-6 bg-white drop-shadow-md z-index-10 px-3">
      <div class="p-10">
        <div class="flex-col pr-16 pb-2">
          <p class="mb-3 font-normal"><b>{{t "auth.failed.title"}}</b></p>

          <p class="mb-5 text-gray-400">{{t "auth.failed.instructions"}}</p>
        </div>
        <div class="text-center mt-3 -mb-3">
          <a class="text-ocean-600 hover:text-ocean-800 font-semibold" href="/login">
            {{t "auth.failed.sign_in_button"}}
          </a>
        </div>
      </div>
    </div>
    <div class="flex justify-self-start items-center h-full">
      {{svgJar "growth" role="img" desc=(t "svg.growth")}}
    </div>
    <TioFooter />
  </div>
</template>
