import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';

<template>
  <LinkTo @route="authenticated.admin.tuition-assistance.applications">
    <Tile
      @headerText={{t "dashboard_tiles.tuition_assistance_applications"}}
      @showAdminBadge={{true}}
    >
      <:description>
        {{t "dashboard_tiles.tuition_assistance_admin" htmlSafe=true}}
      </:description>
      <:image>
        {{svgJar
          "mentoring"
          width="100%"
          height="100%"
          role="img"
          desc=(t "svg.mentoring")
          class="lg:max-h-56 xl:max-h-full"
        }}
      </:image>
    </Tile>
  </LinkTo>
</template>
