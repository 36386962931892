import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { currencyStringToNumber, generateRandomString } from 'tio-common/utils/format';
import { fn } from '@ember/helper';
import { getAllValuesForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { Input } from 'tio-ui/components/forms';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import Component from '@glimmer/component';
import Drawer from 'tio-ui/components/drawer';
import inputmask from 'tio-common/modifiers/inputmask';
import { lowercase } from 'tio-ui/utilities';
import type { ReceivedScholarship } from 'tio-common/types/tuition-assistance';
import type { TASCourseModelFieldsSignature } from 'tio-common/models/tas-course';
import type TasApplication from 'tio-common/models/tas-application';
import type TasApplicationModel from 'tio-common/models/tas-application';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';
import type Owner from '@ember/owner';

interface AddScholarshipsDrawerSignature {
  Args: {
    isOpen: boolean;
    application: TasApplication;
    onClose: () => void;
  };
  Element: HTMLDivElement;
}

export type Scholarship = {
  id?: string;
  scholarshipAmount: number;
  scholarshipName: string;
  error?: string;
};

export default class AddScholarshipsDrawerComponent extends Component<AddScholarshipsDrawerSignature> {
  constructor(owner: Owner, args: AddScholarshipsDrawerSignature['Args']) {
    super(owner, args);
    this.applicationFieldsCopy = new TrackedObject(this.args.application.fields);
  }
  @tracked scholarships: Scholarship[] = [];
  applicationFieldsCopy!: TasApplicationModel['fields'];
  inputClasses = { base: 'w-full mb-6' };

  getErrorForInput(id: string = '', scholarships: Scholarship[]): string {
    return scholarships.find((scholarship: Scholarship) => scholarship.id === id)?.error ?? '';
  }

  get programTemplate() {
    return this.args.application.tasProgramInstance.tasProgramTemplate;
  }

  get scholarshipName() {
    return this.programTemplate.fields['SCHOLARSHIP_NAME'];
  }

  get scholarshipAmount() {
    return this.programTemplate.fields['SCHOLARSHIP_AMOUNT'];
  }

  get scholarshipReceived() {
    return this.programTemplate.fields['SCHOLARSHIPS_RECEIVED'];
  }

  @action
  addScholarship() {
    this.scholarships.push({
      scholarshipName: '',
      scholarshipAmount: 0,
      error: '',
      id: generateRandomString(),
    });
    this.scholarships = [...this.scholarships];
  }

  @action
  removeScholarship(id: string = '') {
    this.scholarships = this.scholarships.filter((scholarship) => scholarship.id !== id);
  }

  @action
  closeDrawer() {
    this.scholarships = [];
    this.args.onClose();
  }

  @action
  setName(index: number, value: string) {
    this.scholarships[index]!.scholarshipName = value;
  }

  @action
  setAmount(index: number, value: string) {
    this.scholarships[index]!.scholarshipAmount = currencyStringToNumber(value);
  }

  @action
  async checkForMissingFields() {
    this.scholarships.forEach((scholarship) => {
      if (
        (scholarship.scholarshipName === '' && this.scholarshipName.required) ||
        (scholarship.scholarshipAmount === 0 && this.scholarshipAmount.required)
      ) {
        scholarship.error = 'A required field is missing';
      } else {
        scholarship.error = '';
      }
      this.scholarships = [...this.scholarships];
    });
  }
  @action
  async saveScholarships() {
    await this.checkForMissingFields();
    const hasErrors = this.scholarships.some((scholarship) => scholarship.error !== '');
    if (hasErrors) {
      return;
    }
    //save scholarships
    let existingScholarships = getAllValuesForTasField(
      'SCHOLARSHIPS_RECEIVED' as keyof TASCourseModelFieldsSignature,
      this.args.application.fields as unknown as TASCourseModelFieldsSignature
    );

    for (const scholarship of this.scholarships) {
      existingScholarships.push({
        scholarshipName: scholarship.scholarshipName,
        scholarshipAmount: scholarship.scholarshipAmount,
        scholarshipId: scholarship.id,
      });
    }
    this.applicationFieldsCopy['SCHOLARSHIPS_RECEIVED'] = {
      values: existingScholarships.filter(
        (scholarship) => scholarship !== null
      ) as ReceivedScholarship[],
    };
    this.args.application.fields = this.applicationFieldsCopy;
    this.scholarships = [];
    this.args.application.save();
    this.args.onClose();
  }

  <template>
    <Drawer @isOpen={{@isOpen}} @onClose={{@onClose}} @allowCloseButton={{false}} @size="xl">
      <div class="p-6 bg-gray-50">
        <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button {{on "click" @onClose}} type="button" data-test-close-scholarship-drawer>
            <XMark class="h-6 w-6" />
          </button>
        </div>
        <h2 class="text-grey-600 font-semibold">
          {{t "tas.add_scholarships_drawer.add_scholarships" label=this.scholarshipReceived.label}}
        </h2>
        <p class="text-gray-400 text-md">
          {{t
            "tas.add_scholarships_drawer.fill_out_scholarship_below"
            label=(lowercase this.scholarshipReceived.label)
          }}
        </p>
      </div>
      <div class="flex py-4 px-6">
        <div class="my-4">
          <h1 class="text-grey-600 text-2xl">
            {{t
              "tas.add_scholarships_drawer.scholarship_information"
              label=this.scholarshipReceived.label
            }}
          </h1>
          <p>
            <span class="text-error-400 text-xl">*</span>
            {{t "tas.add_education_drawer.required_fields"}}</p>
        </div>
      </div>
      {{#if this.scholarships}}
        {{#each this.scholarships as |scholarship index|}}
          <div class="px-6 flex w-full">
            <div class="flex flex-col w-full">
              <label for="scholarship_name4" class="font-semibold text-gray-600">
                {{this.scholarshipName.label}}
                {{#if this.scholarshipName.required}}
                  <span class="text-error-400">*</span>
                {{/if}}
              </label>
              <Input
                data-test={{concat "scholarship-name-" index}}
                @classes={{this.inputClasses}}
                @onChange={{fn this.setName index}}
                @isRequired={{true}}
              />
              <label for="scholarship_amount4" class="font-semibold text-gray-600">
                {{this.scholarshipAmount.label}}
                {{#if this.scholarshipAmount.required}}
                  <span class="text-error-400">*</span>
                {{/if}}
              </label>
              <Input
                data-test={{concat "scholarship-amount-" index}}
                @classes={{this.inputClasses}}
                @onChange={{fn this.setAmount index}}
                @isRequired={{true}}
                {{inputmask alias="currency" prefix="$" unmaskAsNumber=true digits="2"}}
              />
              <p class="text-danger">{{this.getErrorForInput scholarship.id this.scholarships}}</p>
            </div>
            <button
              class="ml-8 flex"
              {{on "click" (fn this.removeScholarship scholarship.id)}}
              type="button"
              data-test-remove-scholarship
            >
              <XMark class="w-4 h-4" />
            </button>
          </div>
          <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        {{/each}}
        <div class="flex w-full justify-between">
          <button
            class="flex mt-8 ml-4 py-3 px-4 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-800"
            {{on "click" this.addScholarship}}
            type="button"
            data-test-add-scholarship
          >
            {{t
              "tas.add_scholarships_drawer.add_another_scholarship"
              label=this.scholarshipReceived.label
            }}
          </button>
          <div class="mt-8 flex">
            <button
              type="button"
              class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-gray-800 focus:outline-none bg-white rounded-lg hover:bg-gray-50 hover:border hover:border-black"
              data-test-close-scholarship-drawer
              {{on "click" this.closeDrawer}}
            >
              {{t "cancel"}}
            </button>
            <button
              type="submit"
              class="flex py-3 px-4 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg border border-ocean-600 hover:bg-ocean-50 hover:text-ocean-800"
              data-test-save-scholarships
              {{on "click" this.saveScholarships}}
            >
              {{t "save"}}
            </button>
          </div>
        </div>
      {{else}}
        <div class="px-16">
          <h3 class="text-xl font-semibold leading-6 text-gray-900">
            {{t
              "tas.add_scholarships_drawer.no_scholarships_added"
              label=this.scholarshipReceived.label
            }}
          </h3>
          <div class="mt-2 sm:flex sm:items-start sm:justify-between">
            <div class="max-w-xl text-sm text-gray-500">
              <p>{{t
                  "tas.add_scholarships_drawer.no_scholarships_added_info"
                  label=(lowercase this.scholarshipReceived.label)
                }}</p>
            </div>
            <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
              <button
                type="button"
                class="inline-flex items-center rounded-md bg-ocean-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ocean-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                data-test-add-scholarship
                {{on "click" this.addScholarship}}
              >
                {{t
                  "tas.add_scholarships_drawer.add_scholarship"
                  label=this.scholarshipReceived.label
                }}
              </button>
            </div>
          </div>
        </div>
      {{/if}}
    </Drawer>
  </template>
}
