import { htmlSafe } from '@ember/template';
import { not } from 'tio-ui/utilities';
import { Section } from 'tio-ui/components/layout';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import inc from 'tio-common/helpers/inc';
import screenIs from 'tio-common/helpers/screen-is';
import Table from 'tio-common/components/table/index';
import TioDownloadButton from 'tio-common/components/tio/download-button';
import TioPdfPrintButton from 'tio-common/components/tio/pdf/print-button';
import type RecommendationModel from 'tio-common/models/recommendation';

export interface ForgivenessEstimatorResultInstructionsSignature {
  Args: {
    recommendation: RecommendationModel;
  };
}

export default class ForgivenessEstimatorResultInstructionsComponent extends Component<ForgivenessEstimatorResultInstructionsSignature> {
  get instructions() {
    return this.args.recommendation?.nextSteps || [];
  }

  <template>
    {{#if (screenIs "md")}}
      <Section>
        <:header>
          <div class="flex justify-end text-sm gap-x-4">
            <TioPdfPrintButton
              @url={{@recommendation.downloadUrl}}
              @filename={{@recommendation.downloadFilename}}
            />
            <TioDownloadButton
              @url={{@recommendation.downloadUrl}}
              @filename={{@recommendation.downloadFilename}}
            />
          </div>
        </:header>
        <:body>
          <Table>
            <:thead as |options|>
              <options.tr>
                {{#each this.instructions as |column|}}
                  <options.th>
                    {{column.title}}
                    {{#if column.subtitle}}
                      <div class="font-normal">
                        {{column.subtitle}}
                      </div>
                    {{/if}}
                  </options.th>
                {{/each}}
              </options.tr>
            </:thead>
            <:tbody as |options|>
              <options.tr>
                {{#each this.instructions as |row|}}
                  <options.td class="align-top">
                    {{#if row.subtitle}}
                      <p class="font-semibold mx-2 mt-4 min-h-12">{{row.subtitle}}:</p>
                    {{/if}}
                    <ul class="flex-col top-10">
                      {{#each row.instructions as |step index|}}
                        <li class="flex my-4 mx-2">
                          <div
                            class="rounded-full bg-ocean-600 text-white mr-2 w-6 h-6 flex items-center justify-center shrink-0"
                          >{{inc index}}</div>
                          <span class="step-by-step-table-links">
                            {{htmlSafe step}}
                          </span>
                        </li>
                      {{/each}}
                    </ul>
                  </options.td>
                {{/each}}
              </options.tr>
            </:tbody>
          </Table>
        </:body>
      </Section>
    {{else}}
      {{#each this.instructions as |card|}}
        <AppContentCard @title={{card.title}} class="m-4 even:bg-ocean-50">
          {{#if card.subtitle}}
            <h2 class="italicized">{{card.subtitle}}:</h2>
          {{/if}}
          <ul class="flex-col">
            {{#each card.instructions as |step index|}}
              <li class="flex my-4 mx-2">
                <div
                  class="rounded-full bg-ocean-600 text-white mr-2 w-6 h-6 flex items-center justify-center shrink-0"
                >{{inc index}}</div>
                <span class="step-by-step-table-links">
                  {{htmlSafe step}}
                </span>
              </li>
            {{/each}}
          </ul>
        </AppContentCard>
      {{/each}}

      {{#if (not (screenIs "md"))}}
        <div class="flex w-full items-center justify-around">
          <TioPdfPrintButton
            @url={{@recommendation.downloadUrl}}
            @filename={{@recommendation.downloadFilename}}
            class="border-none"
          />
          <TioDownloadButton
            @url={{@recommendation.downloadUrl}}
            @filename={{@recommendation.downloadFilename}}
            class="border-none"
          />
        </div>
      {{/if}}
    {{/if}}
  </template>
}
