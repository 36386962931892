import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { Header, Section, VStack } from 'tio-ui/components/layout';

export interface S {
  Args: {
    model: TasProgramInstanceModel;
  };
}

const tmpl: TOC<S> = <template>
  {{pageTitle (t "tuition_assistance.program_details.courses.courses_application")}}

  <VStack>
    <Header>{{t "tuition_assistance.default"}}</Header>
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.tas.dashboard" @label={{t "dashboard"}} />
      <b.crumb
        @route="authenticated.tuition-assistance.programs.instance.index"
        @label={{@model.tasProgramInstance.programName}}
      />
      <b.crumb
        @route="authenticated.tuition-assistance.programs.instance.course-applications.show"
        @label={{@model.displayName}}
      />
    </TioPageBreadcrumbs>

    <Section>
      <:body>
        {{outlet}}
      </:body>
    </Section>
  </VStack>
</template>;

export default tmpl;
