import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { concat } from '@ember/helper';
import { eq, not, or } from 'tio-ui/utilities';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { on } from '@ember/modifier';
import { registerDestructor } from '@ember/destroyable';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import Component from '@glimmer/component';
import EligibilityFilesDownloadEligibilityFile from 'tio-common/components/eligibility-files/download-eligibility-file';
import MaterialIcon from 'tio-common/components/material-icon';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatTime from 'tio-common/helpers/safe-format-time';
import Table from 'tio-common/components/table/index';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type { TOC } from '@ember/component/template-only';
import type EligibilityFileJobLogModel from 'tio-common/models/eligibility-file-job-log';
import type EligibilityUploadService from 'tio-common/services/eligibility-upload';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';

interface DetailsBoxSignature {
  Args: {
    title: string;
    preview: number;
    processed: number;
  };
}

const DetailsBox: TOC<DetailsBoxSignature> = <template>
  <div class="rounded-md text-center my-2 border text-lg bg-white">
    <div class="py-4 font-semibold text-purple-900">
      {{@title}}
    </div>
    <dl>
      <div class="grid grid-cols-2 p-4">
        <dt class="text-left">{{t "common.eligibility_file_upload.preview"}}</dt>
        <dd>{{@preview}}</dd>
        <dt class="text-left">{{t "common.eligibility_file_upload.processed"}}</dt>
        <dd>{{@processed}}</dd>
      </div>
    </dl>
  </div>
</template>;

interface AuthenticatedAdminEligibilityFilesShowRouteSignature {
  Args: {
    model: EligibilityFileJobLogModel;
  };
}

export default class AuthenticatedAdminEligibilityFilesShowRouteComponent extends Component<AuthenticatedAdminEligibilityFilesShowRouteSignature> {
  @service declare store: typeof Store;
  @service declare router: RouterService;
  @service declare eligibilityUpload: EligibilityUploadService;

  @tracked isProcessing = false;
  @tracked isAborting = false;
  @tracked canProcess = true;
  @tracked canAbort = true;
  @tracked buttonEnabled = true;

  constructor(owner: Owner, args: AuthenticatedAdminEligibilityFilesShowRouteSignature['Args']) {
    super(owner, args);

    registerDestructor(this, () => this.args.model.stopPollingForStatus());
    this.beginPolling();
  }

  get processEnabled() {
    return (
      this.args.model.canProcess &&
      this.canProcess === true &&
      this.args.model.status !== 'REQUIRES_REVIEWER_APPROVAL' &&
      this.buttonEnabled === true
    );
  }

  get abortEnabled() {
    return (this.args.model.canAbort && this.canAbort) === true && this.buttonEnabled === true;
  }

  async beginPolling() {
    await this.eligibilityUpload.uploadEligibilityFile();
    this.args.model.startPollingForStatus();
  }

  uploadedByData = trackedFunction(this, async () => {
    const { createdBy } = this.args.model;
    const user = await this.store.findRecord('user', createdBy, {
      include: 'person',
    });
    return user.person.firstName + ' ' + user.person.lastName;
  });

  get uploaderName() {
    return this.uploadedByData.value || '';
  }

  @action
  async processEligibilityFile() {
    const { legacyId, id } = this.args.model.company;
    const { processId } = this.args.model;
    this.buttonEnabled = false;
    this.canProcess = false;
    this.isProcessing = true;
    await this.store.adapterFor('eligibility-file-job-log').processJob(legacyId, id, processId);
    this.args.model.reload();
    this.isProcessing = false;
    this.buttonEnabled = true;
    this.canProcess = true;
  }

  @action
  async abortEligibilityFile() {
    const { legacyId, id } = this.args.model.company;
    const { processId } = this.args.model;
    this.buttonEnabled = false;
    this.canAbort = false;
    this.isAborting = true;
    await this.store.adapterFor('eligibility-file-job-log').abortJob(legacyId, id, processId);
    this.args.model.reload();
    this.isAborting = false;
    this.buttonEnabled = true;
    this.canAbort = true;
  }

  <template>
    <VStack>
      <Header>{{t "eligibility_files.default"}}</Header>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb
          class="!relative !inline-block ml-4"
          @route="authenticated.admin.eligibility-files.index"
          @label="Eligibility Files"
        />
        <span class="!relative !inline-block">
          {{@model.fileName}}
        </span>
      </TioPageBreadcrumbs>
      <div class="grid grid-cols-5 xs:grid-cols-1 mt-8 gap-4">
        <DetailsBox
          @title={{t "eligibility_files.unchanged_employees"}}
          @preview={{@model.previewCounts.unchanged}}
          @processed={{@model.counts.unchanged}}
        />
        <DetailsBox
          @title={{t "eligibility_files.new_employees"}}
          @preview={{@model.previewCounts.created}}
          @processed={{@model.counts.created}}
        />
        <DetailsBox
          @title={{t "eligibility_files.changed_employees"}}
          @preview={{@model.previewCounts.updated}}
          @processed={{@model.counts.updated}}
        />
        <DetailsBox
          @title={{t "eligibility_files.deactivated_employees"}}
          @preview={{@model.previewCounts.deactivated}}
          @processed={{@model.counts.deactivated}}
        />
        <DetailsBox
          @title={{t "eligibility_files.reactivated_employees"}}
          @preview={{@model.previewCounts.reactivated}}
          @processed={{@model.counts.reactivated}}
        />
      </div>

      <div class="flex gap-4">
        <Button
          @intent="primary"
          {{on "click" this.processEligibilityFile}}
          disabled={{or (not this.processEnabled) this.isProcessing}}
        >
          {{t "eligibility_files.process_eligibility_file"}}
        </Button>
        <Button
          @intent="primary"
          {{on "click" this.abortEligibilityFile}}
          disabled={{or (not this.abortEnabled) this.isAborting}}
        >
          {{t "eligibility_files.abort"}}
        </Button>
        <EligibilityFilesDownloadEligibilityFile
          @processId={{@model.processId}}
          @companyId={{@model.company.id}}
          @legacyId={{@model.company.legacyId}}
          @fileName={{@model.fileName}}
          @format="text-button"
          @title=""
        />
      </div>
      <Section>
        <:header>{{t "eligibility_files.process_details"}}</:header>
        <:body>
          <div class="grid grid-cols-4">
            <div class="col-span-2">
              <h2 class="text-sm inline-block">
                <div>
                  {{t "eligibility_files.status"}}
                  <span class="text-purple-900 font-bold">
                    {{t (concat "common.eligibility_file_upload.status." @model.status)}}
                  </span>
                  {{#if @model.isUploading}}
                    <div class="mr-12 mt-4">
                      <MaterialIcon
                        @icon="info"
                        class="text-md font-semibold align-bottom cursor-default text-gray-400"
                      />
                      <span
                        class="text-sm font-semibold align-bottom cursor-default text-ocean-600"
                      >
                        {{t "eligibility_files.upload_warning"}}
                      </span>
                    </div>
                  {{/if}}
                  <br />
                  {{t "eligibility_files.total_processed"}}
                  <span class="text-purple-900 font-bold">{{@model.totalProcessed}}
                  </span>
                  {{#if @model.isUploading}}
                    <TioLoadingSpinner />
                  {{/if}}
                </div>
              </h2>
            </div>
            <div class="col-span-4 grid grid-cols-2">
              <span class="col-span-1">{{t "eligibility_files.uploaded_by"}}</span>
              <span class="col-span-1 float-right font-semibold">{{this.uploaderName}}</span>
              <span class="col-span-1">{{t "eligibility_files.file_name"}}</span>
              <span class="col-span-1 float-right font-semibold">
                {{@model.fileName}}
              </span>
              <span class="col-span-1">{{t "eligibility_files.upload_type"}}</span>
              <span class="col-span-1 float-right font-semibold">
                {{#if (eq @model.strategy "MANUAL")}}
                  {{t "eligibility_files.manual"}}
                {{else if (eq @model.strategy "MANUAL_FORCE_UPDATE")}}
                  {{t "eligibility_files.forced"}}
                {{else}}
                  {{t "eligibility_files.automatic"}}
                {{/if}}
              </span>
              <span class="col-span-1">{{t "eligibility_files.start_time"}}</span>
              <span class="col-span-1 float-right font-semibold">
                {{safeFormatDate @model.startTime}}
                {{safeFormatTime @model.startTime}}
              </span>
              {{#if @model.abortedAt}}
                <span class="col-span-1">{{t "eligibility_files.time_aborted"}}</span>
                <span class="col-span-1 float-right font-semibold">{{@model.abortedAt}}</span>
              {{/if}}
              {{#if @model.endTime}}
                <span class="col-span-1">{{t "eligibility_files.time_completed"}}</span>
                <span class="col-span-1 float-right font-semibold">
                  {{safeFormatDate @model.endTime}}
                  {{safeFormatTime @model.endTime}}
                </span>
              {{/if}}
            </div>
          </div>
        </:body>
      </Section>
      <Section>
        <:header>{{t "eligibility_files.warning.default"}}</:header>
        <:body>
          <Table>
            <:thead as |options|>
              <options.tr>
                <options.th>{{t "eligibility_files.warning.type"}}</options.th>
                <options.th>{{t "eligibility_files.warning.field"}}</options.th>
                <options.th>{{t "eligibility_files.warning.description"}}</options.th>
              </options.tr>
            </:thead>
            <:tbody as |options|>
              {{#each @model.jobErrors as |warning|}}
                <options.tr>
                  <options.td>{{warning.key}}</options.td>
                  <options.td>{{warning.field}}</options.td>
                  <options.td>{{warning.message}}</options.td>
                </options.tr>
              {{/each}}
            </:tbody>
          </Table>
        </:body>
      </Section>
    </VStack>
  </template>
}
