import { service } from '@ember/service';
import Component from '@glimmer/component';
import TasProgramsReport from 'tio-common/components/reporting/tas/programs';
import type SessionContext from 'tio-employee/services/session-context';
import type CompanyModel from 'tio-common/models/company';
import type TasProgramsController from 'tio-employee/controllers/authenticated/admin/reporting/tas-programs';
import { type QueryKeys } from 'tio-employee/controllers/authenticated/admin/reporting/tas-programs';

export interface ReportingTasProgramsRouteSignature {
  Args: {
    model: CompanyModel;
    controller: TasProgramsController;
  };
}

export default class ReportingTasApplicationRouteComponent extends Component<ReportingTasProgramsRouteSignature> {
  @service declare sessionContext: SessionContext;

  updateQueryParameters = (object: Record<QueryKeys, boolean | string>) => {
    this.args.controller.setProperties(object);
  };

  <template>
    <TasProgramsReport
      @returnWithoutModel={{true}}
      @returnRoute="authenticated.admin.reporting.index"
      @applicationDetailRoute="authenticated.admin.tuition-assistance.applications.show"
      @programDetailRoute="authenticated.admin.tuition-assistance.program-instances.show.index"
      @company={{@model}}
      {{! Program Filters }}
      @programTemplateId={{@controller.programTemplateId}}
      @programCreatedAtStart={{@controller.programCreatedAtStart}}
      @programCreatedAtEnd={{@controller.programCreatedAtEnd}}
      {{! Application Filters }}
      @showIndividualApplications={{@controller.showIndividualApplications}}
      @applicationStatus={{@controller.applicationStatus}}
      @applicationCreatedAtStart={{@controller.applicationCreatedAtStart}}
      @applicationCreatedAtEnd={{@controller.applicationCreatedAtEnd}}
      {{! Course Filters }}
      @includeCourses={{@controller.includeCourses}}
      @coursesBeginDate={{@controller.coursesBeginDate}}
      @coursesEndDate={{@controller.coursesEndDate}}
      @approverId={{@controller.approverId}}
      {{! Payment Filters }}
      @includePayments={{@controller.includePayments}}
      {{! @glint-expect-error: will return to this later.
        Don't want a component to import a type from a controller. }}
      @updateQueryParameters={{this.updateQueryParameters}}
    />
  </template>
}
