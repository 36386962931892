import { action } from '@ember/object';
import { concat, fn } from '@ember/helper';
import { eq, or } from 'tio-ui/utilities';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { registerDestructor } from '@ember/destroyable';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import pageTitle from 'ember-page-title/helpers/page-title';
import Paginator from 'tio-common/components/tio/paginator';
import SelectGeneric from 'tio-common/components/select/generic';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import { SearchInput } from 'tio-ui/components/forms';
import TioTablist from 'tio-common/components/tio/tablist';
import type { Appearance } from 'tio-common/services/appearance';
import type {
  MultiDocumentAppearanceMap,
  MultiDocumentPersonAppearanceMap,
} from 'tio-common/services/multi-document-appearance';
import type AdminTasApplicationController from 'tio-employee/controllers/authenticated/admin/tuition-assistance/applications';
import type EnumModel from 'tio-common/models/enum';
import type IntlService from 'ember-intl/services/intl';
import type MultiDocumentAppearanceService from 'tio-common/services/multi-document-appearance';
import type PartnerService from 'tio-employee/services/partner';
import type SessionService from 'tio-employee/services/session';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import Table from 'tio-common/components/table/index';
import WhosViewingComponent from 'tio-common/components/tio/whos-viewing';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import { LinkTo } from '@ember/routing';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
import type Owner from '@ember/owner';

interface S {
  Args: {
    controller: AdminTasApplicationController;
    model: TasApplicationModel[];
  };
}
export default class AdminTasApplicationsIndexRoute extends Component<S> {
  @service declare intl: IntlService;
  @service declare store: StoreService;
  @service declare partner: PartnerService;
  @service declare multiDocumentAppearance: MultiDocumentAppearanceService;
  @service declare session: SessionService;

  @tracked selectedAppStateEnum?: EnumModel;
  @tracked selectedProgramFilter?: TasProgramTemplateModel;
  @tracked currentlyBeingViewedApplications: MultiDocumentAppearanceMap = {};

  constructor(owner: Owner, args: S['Args']) {
    super(owner, args);

    this.multiDocumentAppearance.connect(
      ENV.apiHost,
      ENV.apiKey,
      this.session.data.authenticated.access_token
    );

    const documentIds = this.args.model.map((application) => application.id) as string[];
    this.loadMultiDocumentAppearanceData();
    this.setupMenus();
    registerDestructor(this, () => {
      this.multiDocumentAppearance.unsubscribe('tas_course_application', documentIds);
    });
  }

  loadMultiDocumentAppearanceData() {
    const documentIds = this.args.model.map((application) => application.id) as string[];

    if (this.multiDocumentAppearance.subscription) {
      this.multiDocumentAppearance.unsubscribe('tas_course_application', documentIds);
    }

    this.multiDocumentAppearance.subscribe(
      'tas_course_application',
      documentIds,
      (message: MultiDocumentAppearanceMap) => {
        this.currentlyBeingViewedApplications = message;
      }
    );
  }

  @action
  currentlyBeingViewedApplicationsById(id: string): Appearance[] | undefined {
    return (
      this.currentlyBeingViewedApplications[id] as MultiDocumentPersonAppearanceMap[]
    )?.flatMap((e) => Object.values(e));
  }

  @action
  async setupMenus() {
    if (this.args.controller.state && !this.selectedAppStateEnum) {
      const selected = await this.store.query('enum', {
        filter: { dot: this.args.controller.state },
      });
      this.selectedAppStateEnum = selected[0];
    }

    // Same logic as above
    if (this.args.controller.program && !this.selectedProgramFilter) {
      const selected = this.store.peekRecord('tasProgramTemplate', this.args.controller.program);
      this.selectedProgramFilter = selected;
    }
  }

  stateOptionsData = trackedFunction(this, async () => {
    const states = await this.store.query('enum', {
      filter: {
        flavor: 'TAS.ApplicationState',
      },
    });
    return states;
  });

  get stateOptions() {
    return this.stateOptionsData.value || [];
  }

  programOptionsData = trackedFunction(this, async () => {
    if (!this.partner?.company) {
      return [];
    }
    const programs = await this.store.query('tas-program-template', {
      filter: {
        company: this.partner.company?.id,
      },
    });
    return programs;
  });

  get programOptions() {
    return this.programOptionsData.value || [];
  }

  @action
  didSearch(string: string) {
    this.args.controller.search = string;
  }

  @action
  setSelectedAppState(stateEnum: EnumModel) {
    this.args.controller.state = stateEnum?.dot || '';
    this.selectedAppStateEnum = stateEnum;
  }

  @action
  setSelectedProgramFilter(model: TasProgramTemplateModel) {
    this.selectedProgramFilter = model;
    this.args.controller.program = model?.id || '';
  }

  @action
  changePaginatorPage(page: number) {
    this.args.controller.page = page;
  }

  @action
  statusText(state: string): string {
    if (this.intl.exists(`common.status_message.to.${state}`)) {
      return this.intl.t(`common.status_message.to.${state}`);
    }

    return this.intl.t('common.status_message.to.TAS.ApplicationState.UPDATED_ON');
  }

  <template>
    {{pageTitle (t "tuition_assistance.applications.default")}}
    <VStack>
      <TioPageTabs as |tabs|>
        <tabs.tab
          @label={{t "tuition_assistance.applications.course_applications"}}
          @route="authenticated.admin.tuition-assistance.applications.index"
        />
        <tabs.tab
          @label={{t "tuition_assistance.program_instances.program_applications"}}
          @route="authenticated.admin.tuition-assistance.program-instances.index"
        />
      </TioPageTabs>
      <Section class="md:sticky md:top-0 z-5 bg-gray-50">
        <:body>
          <HStack>
            <div class="md:basis-80">
              <label class="text-xs" aria-hidden={{true}}>{{t "search"}}</label>
              <SearchInput
                @placeholder="App ID, Employee ID, Email"
                @searchString={{@controller.search}}
                @onInput={{this.didSearch}}
                @onSubmit={{this.didSearch}}
              />
            </div>
            <SelectGeneric
              @label={{t "tuition_assistance.applications.state_filter_label"}}
              @selected={{this.selectedAppStateEnum}}
              @options={{this.stateOptions}}
              @onChange={{this.setSelectedAppState}}
              {{! @glint-expect-error }}
              @allowClear={{true}}
              class="md:basis-80"
            >
              <:option as |state|>
                {{t (concat "common.status_message.to." state.dot)}}
              </:option>
            </SelectGeneric>

            <SelectGeneric
              @label="Filter by program"
              @selected={{this.selectedProgramFilter}}
              @options={{this.programOptions}}
              @onChange={{this.setSelectedProgramFilter}}
              {{! @glint-expect-error }}
              @allowClear={{true}}
              class="md:basis-80"
            >
              <:option as |program|>
                {{! @glint-expect-error: unify types }}
                {{getSingleValueForTasField "PROGRAM_NAME" program.fields}}
              </:option>
            </SelectGeneric>
          </HStack>
          <TioTablist class="mb-6 border-b" as |tablist|>
            <tablist.tab
              @title="Approvals Waiting On Me"
              @isSelected={{eq @controller.tab "urgent"}}
              @onClick={{fn (mut @controller.tab) "urgent"}}
              class="lg:min-w-72"
            />
            <tablist.tab
              @title="Approvals Waiting On Others"
              @isSelected={{eq @controller.tab "waiting"}}
              @onClick={{fn (mut @controller.tab) "waiting"}}
              class="lg:min-w-72"
            />
            <tablist.tab
              @title="All Other Applications"
              @isSelected={{eq @controller.tab "all"}}
              @onClick={{fn (mut @controller.tab) "all"}}
              class="lg:min-w-72"
            />
          </TioTablist>
        </:body>
      </Section>
      <Section class="overflow-x-auto">
        <:body>
          <Table @stickyHeader={{true}} @isLoading={{false}}>
            <:thead as |options|>
              <options.tr>
                <options.th>{{t "tuition_assistance.preapproval_app.application_id"}}</options.th>
                <options.th>{{t "tuition_assistance.applications.employee_name"}}</options.th>
                <options.th>{{t "tuition_assistance.applications.school_institution"}}</options.th>
                <options.th>{{t
                    "tuition_assistance.program_details.application_history.state"
                  }}</options.th>
                <options.th>{{t "tuition_assistance.applications.last_action_by"}}</options.th>
                <options.th />
              </options.tr>
            </:thead>
            <:tbody as |options|>
              {{#each @model as |item|}}
                <options.tr>
                  <options.td>
                    <LinkTo
                      @route="authenticated.admin.tuition-assistance.applications.show"
                      @model={{item.id}}
                      class="truncate text-ocean-600 underline"
                    >
                      {{item.id}}
                    </LinkTo>
                  </options.td>
                  <options.td>{{item.tasProgramInstance.employee.payrollId}}</options.td>
                  <options.td>{{item.tasProgramInstance.employee.fullName}}</options.td>
                  <options.td>{{item.tasProgramInstance.institutionName}}</options.td>
                  <options.td>
                    {{this.statusText item.state}}
                  </options.td>
                  <options.td>
                    {{or item.latestStateChangePerson "-"}}
                    <br />
                    {{safeFormatDate
                      item.latestStateChangeDate
                      month="short"
                      day="2-digit"
                      year="numeric"
                    }}
                  </options.td>
                  <options.td>
                    {{#let (this.currentlyBeingViewedApplicationsById item.id) as |appearances|}}
                      {{#if appearances}}
                        <WhosViewingComponent @appearances={{appearances}} />
                      {{/if}}
                    {{/let}}
                  </options.td>
                </options.tr>
              {{/each}}
            </:tbody>

            <:tfoot as |options|>
              <options.tr>
                <td colspan="7">
                  <Paginator
                    {{! @glint-expect-error: ed needs types}}
                    @totalItems={{@model.meta.page.total}}
                    @pageSize={{@controller.limit}}
                    @currentPage={{@controller.page}}
                    @onChange={{this.changePaginatorPage}}
                  />
                </td>
              </options.tr>
            </:tfoot>
          </Table>
        </:body>
      </Section>
    </VStack>
  </template>
}
