import { t } from 'ember-intl';

<template>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_0_q"}}
    </div>
    <div class="py-2 text-gray-600 font-normal">
      {{t "planning_for_college.five_two_nine.faq_0_a"}}
    </div>
  </div>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_1_q"}}
    </div>
    <div class="py-2 text-gray-600 font-normal">
      {{t "planning_for_college.five_two_nine.faq_1_a"}}
    </div>
  </div>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_2_q"}}
    </div>
    <div class="py-2 text-gray-600 font-normal">
      {{t "planning_for_college.five_two_nine.faq_2_a"}}
    </div>
  </div>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_3_q"}}
    </div>
    <div class="py-2 text-gray-600 font-normal">
      {{t "planning_for_college.five_two_nine.faq_3_a"}}
    </div>
  </div>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_4_q"}}
    </div>
    <div class="py-2 text-gray-600 font-normal">
      {{t "planning_for_college.five_two_nine.faq_4_a"}}
    </div>
  </div>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_5_q"}}
    </div>
    <div class="py-2 text-gray-600 font-normal">
      {{t "planning_for_college.five_two_nine.faq_5_a"}}
    </div>
  </div>
  <div class="flex justify-center items-center py-10 px-2">
    <img
      class="w-full max-w-96"
      alt={{t "planning_for_college.five_two_nine.saving_for_college_alt"}}
      src="/assets/images/planning-for-college/online-courses.png"
    />
  </div>
</template>
