import type { TOC } from '@ember/component/template-only';

interface SyfPaymentsRouteSignature {
  Blocks: {
    default: [];
  };
}

const tmpl: TOC<SyfPaymentsRouteSignature> = <template>{{outlet}}</template>;

export default tmpl;
