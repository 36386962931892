import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import type { TOC } from '@ember/component/template-only';

export interface S {
  Args: {
    model: TasProgramInstanceModel;
  };
}
const tmpl: TOC<S> = <template>
  {{pageTitle (t "tuition_assistance.preapproval_app.default")}}
  <VStack>
    <Header>{{t "tuition_assistance.default"}}</Header>
    <Section>
      {{outlet}}
    </Section>
  </VStack>
</template>;

export default tmpl;
