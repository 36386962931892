import { Header, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import PslfWhatNext from 'tio-employee/components/pslf/what-next';
import type PslfFormModel from 'tio-common/models/pslf-form';
import type { TOC } from '@ember/component/template-only';

interface S {
  Args: {
    model: PslfFormModel;
  };
}

const tmpl: TOC<S> = <template>
  <VStack>
    <Header>{{t "pslf.default_full"}}</Header>
    <PslfWhatNext @model={{@model}} />
  </VStack>
</template>;

export default tmpl;
