import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import RecommendationsResultsResultsDetails from 'tio-employee/components/recommendations/results/results-details';
import type RecomendationModel from 'tio-common/models/recommendation';
import type { TOC } from '@ember/component/template-only';

interface S {
  Args: {
    model: RecomendationModel;
  };
}

const tmpl: TOC<S> = <template>
  <TioPageBreadcrumbs class="mb-4" as |b|>
    <b.crumb @route="authenticated.idr.dashboard.index" @label="Dashboard" />
    <b.crumb
      @route="authenticated.idr.dashboard.forgiveness-estimator.index"
      @label="Forgiveness Estimator"
    />
    <b.crumb @route="authenticated.idr.dashboard.forgiveness-estimator.results" @label="Results" />
  </TioPageBreadcrumbs>
  <RecommendationsResultsResultsDetails @recommendation={{@model}} @programType="idr" />
</template>;

export default tmpl;
