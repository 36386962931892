import type { TOC } from '@ember/component/template-only';
import TuitionAssistanceFormsCertificateCertificateDetails from '../../../../../../components/tuition-assistance/forms/certificate/certificate-details';
import type TasApplication from 'tio-common/models/tas-application';
import type TasCourse from 'tio-common/models/tas-course';
import type TasProgramInstance from 'tio-common/models/tas-program-instance';

interface S {
  Args: {
    model: {
      instance: TasProgramInstance;
      application: TasApplication;
      course: TasCourse;
    };
  };
}
const tmpl: TOC<S> = <template>
  <TuitionAssistanceFormsCertificateCertificateDetails @model={{@model}} />
</template>;

export default tmpl;
