import { action } from '@ember/object';
import { ageFromBirthDate } from 'tio-common/utils/date/age-from-birth-date';
import { and, not } from 'tio-ui/utilities';
import { Button, Chip } from 'tio-ui/components/buttons';
import { concat, fn } from '@ember/helper';
import { Divider } from 'tio-ui/components/utilities';
import { dropTask } from 'ember-concurrency';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { hasInProgressInstanceOfCurrentProgramTemplate } from 'tio-common/utils/tuition-assistance/tas-grouping';
import { htmlSafe } from '@ember/template';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { VStack, HStack, Section, Header } from 'tio-ui/components/layout';
import AddDependent from 'tio-employee/components/tuition-assistance/forms/add-dependents';
import Component from '@glimmer/component';
import FormRadioGroup from '@frontile/forms-legacy/components/form-radio-group';
import pageTitle from 'ember-page-title/helpers/page-title';
import StartDate from 'tio-employee/components/tas/start-date-eligibility';
import TasFaqs from 'tio-common/components/tas/faqs';
import TioAlert from 'tio-common/components/tio/alert';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import TioHeader from 'tio-common/components/tio/page/hero/index';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type ConditionModel from 'tio-common/models/condition';
import type DependentModel from 'tio-common/models/dependent';
import type IntlService from 'ember-intl/services/intl';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasGroupModel from 'tio-common/models/tas-group';
import type TasParticipantModel from 'tio-common/models/tas-participant';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import Users from 'ember-static-heroicons/components/outline-24/users';
import VerticalProgressTracker from 'tio-common/components/tio/vertical-progress-tracker';

export interface S {
  Args: {
    model: {
      activeInstances: TASProgramInstanceModel[];
      programTemplate: TasProgramTemplate;
      tasParticipant: TasParticipantModel;
      tasGroups: TasGroupModel[];
    };
  };
}

const relationships = [
  { type: 'DependentRelationship.CHILD', name: 'Child' },
  { type: 'DependentRelationship.SPOUSE', name: 'Spouse' },
  { type: 'DependentRelationship.PARTNER', name: 'Partner' },
];

const getDependentRelationshipName = (dependent: DependentModel) => {
  return relationships.find((relationship) => relationship.type === dependent.relationship)!.name;
};

export default class AuthenticatedTuitionAssistanceGettingStarted extends Component<S> {
  @service declare intl: IntlService;
  @service declare progressBuilder: ProgressBuilder;
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  @tracked conditionToView?: ConditionModel;
  @tracked showTermsAndConditions = false;
  @tracked selectedDependent: string | null = null;

  get isActiveEligibility() {
    return this.tuitionAssistance.hasActiveEligibilityForProgramTemplate(this.programTemplate);
  }

  get isRestrictedByProgramExclusivity() {
    if (this.args.model.tasGroups.length === 0) {
      return false;
    }

    return hasInProgressInstanceOfCurrentProgramTemplate(
      this.args.model.tasGroups,
      this.programTemplate.id,
      this.args.model.activeInstances
    );
  }

  get eligibleBasedOnWaitingPeriod() {
    return !this.tuitionAssistance.ineligibleBasedOnWaitingPeriod(
      this.programTemplate.eligibilityWaitingPeriod,
      this.args.model.tasParticipant
    );
  }

  get waitingPeriod() {
    return String(
      getSingleValueForTasField('ELIGIBILITY_WAITING_PERIOD', this.programTemplate.fields) || 0
    );
  }

  get dateEligible() {
    const date = this.tuitionAssistance.waitingPeriodEndDate(
      this.programTemplate.eligibilityWaitingPeriod,
      this.args.model.tasParticipant
    );
    return this.intl.formatDate(date, { month: 'short', day: 'numeric', year: 'numeric' });
  }

  get activeProgramInstances() {
    return this.args.model.activeInstances || [];
  }

  get isHeldParticipant() {
    return !!this.args.model.tasParticipant.isHeld;
  }

  get requiredDependentSelectedAndNotEligible() {
    if (!this.isDependentProgram) {
      return false;
    }

    return this.selectedDependent ? this.selectedDependentFields?.eligible : true;
  }

  get selectedDependentFields() {
    const rawAgeLimits = getSingleValueForTasField(
      'DEPENDENT_PROGRAM_AGE_LIMITS',
      this.programTemplate.fields
    ) as Record<string, string>;

    const ageLimitsForDependents = rawAgeLimits || {};

    if (this.activeDependents.length === 0 || !this.selectedDependent) {
      return {
        eligible: false,
        limit: null,
        dependent: null,
      };
    }

    const dependent = this.activeDependents.find(
      (dependent) => dependent.id === this.selectedDependent
    );
    const dependentAge = dependent ? ageFromBirthDate(dependent.birthDate) : 0;

    let relationshipKey = '';
    if (dependent?.relationship && typeof dependent.relationship === 'string') {
      relationshipKey = dependent.relationship.replace('DependentRelationship.', '');
    } else {
      if (this.isDependentProgram) {
        console.warn('Invalid or undefined relationship:', dependent);
      }
    }

    const limit = relationshipKey ? (ageLimitsForDependents[relationshipKey] as string) : null;

    return {
      eligible: limit !== null ? dependentAge > parseInt(limit, 10) : false,
      limit: limit,
      dependent,
    };
  }

  get lowercaseRelationship() {
    return getDependentRelationshipName(this.selectedDependentFields.dependent!).toLowerCase();
  }

  get canApplyToProgram() {
    if (
      this.isHeldParticipant ||
      !this.isActiveEligibility ||
      this.isRestrictedByProgramExclusivity ||
      !this.eligibleBasedOnWaitingPeriod ||
      !this.sessionContext.currentEmployee.id ||
      !this.activeProgramInstances ||
      this.requiredDependentSelectedAndNotEligible
    ) {
      return false;
    }
    if (this.activeProgramInstances.length === 0 || this.isMultipleInstanceProgram) {
      return true;
    }
    const templateIds = this.activeProgramInstances.map(
      (instance: TASProgramInstanceModel) => instance.tasProgramTemplate.id
    );

    const hasActiveInstanceForProgram = templateIds.includes(this.programTemplate.id);
    return !hasActiveInstanceForProgram;
  }

  get instanceForExistingApp() {
    if (
      this.programTemplate.typeClassification === 'TAS.ProgramType.2' ||
      this.programTemplate.typeClassification === 'TAS.ProgramType.3'
    ) {
      return;
    }
    const templateId = this.programTemplate.id;
    const instances = this.activeProgramInstances;
    const activeInstanceMatchesTemplateId = instances.find(
      (instance: TASProgramInstanceModel) => templateId === instance.tasProgramTemplate.id
    );
    return activeInstanceMatchesTemplateId;
  }

  get programInstance() {
    return this.instanceForExistingApp || {};
  }

  get programTemplate() {
    return this.args.model.programTemplate;
  }

  get hideProcessDescriptionSteps() {
    return this.programTemplate.hideProcessDescriptionSteps;
  }

  get programSteps() {
    return this.progressBuilder.applicationProcess();
  }

  get isMultipleInstanceProgram() {
    return this.programTemplate.isMultipleInstanceProgram;
  }

  get buttonText() {
    return this.intl.t('common.progress_tracker.start_application');
  }

  get isDependentProgram() {
    return this.programTemplate.isDependentProgram;
  }

  get activeDependents() {
    const dependents = this.args.model.tasParticipant?.employee?.dependents;
    return Array.isArray(dependents)
      ? dependents.filter((dependent: DependentModel) => dependent.notDeleted)
      : [];
  }

  initializeTypedProgram = dropTask(async (route: string, date?: string) => {
    const employeeId = this.sessionContext.currentEmployee.id;
    try {
      const newProgram = await this.store
        .adapterFor('tas-program-template')
        .initializeTypedProgram(
          this.programTemplate.id,
          employeeId,
          date ? date : new Date().toLocaleDateString('en-CA'),
          this.selectedDependent
        );

      const queryParams: Record<string, unknown> = {};
      if (date) {
        queryParams.date = date;
      }
      this.router.transitionTo(route, newProgram.id, { queryParams });
    } catch (e) {
      console.error(e);
    }
  });

  get programTemplateConditions(): readonly ConditionModel[] {
    return Object.freeze([...this.programTemplate.conditions]);
  }

  get programFaqs() {
    return this.programTemplate.programFaqs;
  }

  get requiresDateEligibilityCheck() {
    const programIsTypeTwoOrThree =
      this.programTemplate.typeClassification === 'TAS.ProgramType.2' ||
      this.programTemplate.typeClassification === 'TAS.ProgramType.3';

    const lock = getSingleValueForTasField(
      'LOCK_EMPLOYEE_STATUS_BASED_ON',
      this.programTemplate.fields
    );

    return programIsTypeTwoOrThree && (lock === 'COURSE_START_DATE' || lock === 'COURSE_END_DATE');
  }

  @action
  applyToProgram(date?: string) {
    const programType = this.programTemplate.typeClassification;
    let route;
    switch (programType) {
      case 'TAS.ProgramType.1':
        route = 'authenticated.tas.programs.new';
        this.initializeTypedProgram.perform(route, date);
        break;
      case 'TAS.ProgramType.2':
        route = 'authenticated.tas.applications.type-two.new';
        this.initializeTypedProgram.perform(route, date);
        break;
      case 'TAS.ProgramType.3':
        route = 'authenticated.tas.applications.type-three.new';
        this.initializeTypedProgram.perform(route, date);
        break;
      case 'TAS.ProgramType.4':
      case 'TAS.ProgramType.4.2':
      case 'TAS.ProgramType.4.3':
        route = 'authenticated.tas.programs.new';
        this.initializeTypedProgram.perform(route);
        break;
      default:
        console.error('no program type found');
    }
  }

  @action
  applyWithoutEligibilityCheck() {
    this.applyToProgram();
  }

  @action
  setShowConditions() {
    this.showTermsAndConditions = !this.showTermsAndConditions;
  }

  @action
  close() {
    this.conditionToView = undefined;
  }

  @action
  setConditionToView(condition: ConditionModel) {
    this.conditionToView = condition;
  }

  @action
  setDependent(value: string) {
    this.selectedDependent = value;
  }

  <template>
    {{pageTitle
      (concat
        @model.programTemplate.programName " | " (t "tuition_assistance.program_details.default")
      )
    }}
    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb @route="authenticated.tas.dashboard" @label={{t "dashboard"}} />
        <b.crumb
          @route="authenticated.tuition-assistance.program-overview"
          @label={{t "tuition_assistance.program_details.default"}}
        />
      </TioPageBreadcrumbs>

      <HStack>
        <VStack class={{if this.isDependentProgram "lg:!w-2/3 md:!w-1/2" ""}}>
          {{#if @model.tasParticipant.isHeld}}
            <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
              <:header>
                <p class="font-bold">
                  {{t "tuition_assistance.held_participant_warnings.dashboard_title"}}
                </p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t "tuition_assistance.held_participant_warnings.dashboard_description"}}
                </p>
              </:body>
            </TioAlert>
          {{/if}}

          {{#unless this.isActiveEligibility}}
            <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
              <:header>
                <p class="font-bold">
                  {{t "tuition_assistance.inactive_participant_warnings.dashboard_title"}}
                </p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t "tuition_assistance.inactive_participant_warnings.dashboard_description"}}
                </p>
              </:body>
            </TioAlert>
          {{/unless}}

          {{#if this.isRestrictedByProgramExclusivity}}
            <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
              <:header>
                <p class="font-bold">
                  {{t "tuition_assistance.program_exclusivity_warnings.dashboard_title"}}
                </p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t "tuition_assistance.program_exclusivity_warnings.dashboard_description"}}
                </p>
              </:body>
            </TioAlert>
          {{/if}}

          {{#if (and @model.tasParticipant.isActive (not this.eligibleBasedOnWaitingPeriod))}}
            <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
              <:header>
                <p class="font-bold">
                  {{t "tuition_assistance.program_eligibility_warnings.dashboard_title"}}
                </p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t
                    "tuition_assistance.program_eligibility_warnings.dashboard_description"
                    time=this.waitingPeriod
                    date=this.dateEligible
                    htmlSafe=true
                  }}
                </p>
              </:body>
            </TioAlert>
          {{/if}}

          {{#if this.selectedDependentFields.eligible}}
            <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
              <:header>
                <p class="font-bold">
                  {{t
                    "tuition_assistance.dependent_program_warngings.dashboard_title"
                    name=this.selectedDependentFields.dependent.firstName
                  }}
                </p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t
                    "tuition_assistance.dependent_program_warngings.dashboard_description"
                    relationship=this.lowercaseRelationship
                    limit=this.selectedDependentFields.limit
                  }}
                </p>
              </:body>
            </TioAlert>
          {{/if}}
          <Section>
            <:header>
              <TioHeader
                @headerBeginning={{@model.programTemplate.programName}}
                @description={{@model.programTemplate.programDescription}}
              />
            </:header>
            <:body>
              {{#if this.requiresDateEligibilityCheck}}
                <StartDate
                  @instanceForExistingApp={{this.instanceForExistingApp}}
                  @isMultipleInstanceProgram={{this.isMultipleInstanceProgram}}
                  @canApplyToProgram={{this.canApplyToProgram}}
                  @buttonText={{this.buttonText}}
                  @applyToProgram={{this.applyToProgram}}
                  @programTemplate={{@model.programTemplate}}
                  @tasParticipant={{@model.tasParticipant}}
                />
              {{else}}
                <div class="my-4">
                  <Button
                    {{on "click" this.applyWithoutEligibilityCheck}}
                    disabled={{not this.canApplyToProgram}}
                    @isRunning={{this.initializeTypedProgram.isRunning}}
                    class="uppercase w-60"
                    @intent="primary"
                  >
                    {{this.buttonText}}
                  </Button>
                  {{#if this.instanceForExistingApp}}
                    <div class="flex text-sm py-2">
                      <span>
                        {{t "tuition_assistance.you_already_have_an_active_program"}}
                      </span>
                      <span class="text-center mx-1">
                        <TioClickableText
                          @linkTo={{if
                            this.isMultipleInstanceProgram
                            "authenticated.tas.dashboard"
                            "authenticated.tas.programs.show"
                          }}
                          @linkToModel={{unless
                            this.isMultipleInstanceProgram
                            this.instanceForExistingApp
                          }}
                          @textClass="text-sm font-medium text-ocean-600 hover:text-ocean-800 underline"
                        >
                          {{t "click_here"}}
                        </TioClickableText>
                      </span>
                      <span>
                        {{t "tuition_assistance.to_view_program_details"}}
                      </span>
                    </div>
                  {{/if}}
                </div>
              {{/if}}

              {{#if this.programTemplateConditions}}
                <p class="pt-4 pb-2 font-medium text-xl">
                  {{t "tas.terms_and_conditions"}}</p>
                <TioClickableText @onClick={{this.setShowConditions}}>
                  {{htmlSafe (t "tas.terms_you_will_agree_to")}}
                </TioClickableText>
              {{/if}}
            </:body>
          </Section>
          <TasFaqs @template={{@model.programTemplate}} />

          {{#unless this.hideProcessDescriptionSteps}}
            <Section>
              <:header>
                <h2 class="text-sm font-semibold mb-4">
                  {{t "tuition_assistance.program_steps.application_process"}}
                </h2>
              </:header>
              <:body>
                <VerticalProgressTracker @steps={{this.programSteps}} class="-mb-8 mx-4" />
              </:body>
            </Section>
          {{/unless}}
        </VStack>

        {{#if this.isDependentProgram}}
          <VStack class="lg:!w-1/3 md:!w-1/2">
            <Section
              @headerClass="flex items-center gap-2 py-2 px-5 text-sm font-medium tracking-wide -m-4"
            >
              <:header>
                <Users class="w-8 h-8" />
                {{t "common.tuition_assistance.dependent_select"}}
              </:header>
              <:body>
                <FormRadioGroup
                  data-legacy-input
                  @value="{{this.selectedDependent}}"
                  name="dependent"
                  {{! @glint-expect-error: frontile types}}
                  @onChange={{this.setDependent}}
                  as |Radio|
                >
                  {{#each this.activeDependents as |dependent|}}
                    <div class="flex flex-wrap items-center gap-8 py-4">
                      {{! @glint-expect-error }}
                      <Radio @value={{dependent.id}}>
                        {{dependent.fullName}}
                      </Radio>
                      <span class="flex grow justify-end gap-8">
                        <Chip @size="sm" @appearance="faded" @intent="default" class="px-2">
                          {{getDependentRelationshipName dependent}}
                        </Chip>
                        {{t "common.tuition_assistance.dependent_age"}}
                        {{ageFromBirthDate dependent.birthDate}}
                      </span>
                    </div>
                    <Divider class="my-2" />
                  {{/each}}
                </FormRadioGroup>
                <AddDependent @employee={{@model.tasParticipant.employee}} class="justify-center" />
              </:body>
            </Section>
          </VStack>
        {{/if}}
      </HStack>
    </VStack>

    <Modal
      @isOpen={{this.showTermsAndConditions}}
      @onClose={{this.setShowConditions}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Header>
        <p class="font-semibold">{{t "tas.terms_and_conditions"}}</p>
      </m.Header>
      <m.Body>
        {{#each this.programTemplateConditions as |condition|}}
          <div class="flex justify-between items-center my-2 w-11/12">
            <div class="flex items-center">
              <p class="text-pretty">{{condition.prompt}}</p>
            </div>
            {{#if condition.hasPdf}}
              <a
                type="button"
                class="text-ocean-600 font-medium text-sm uppercase ml-2"
                href={{condition.pdfUrl}}
                target="_blank"
                rel="noopener noreferrer"
              >
                {{t "view"}}
              </a>
            {{else}}
              <button
                type="button"
                class="text-ocean-600 font-medium text-sm uppercase ml-2"
                {{on "click" (fn this.setConditionToView condition)}}
              >
                {{t "view"}}
              </button>
            {{/if}}
          </div>
        {{/each}}
        {{#if this.conditionToView}}
          <Modal
            @isOpen={{true}}
            @onClose={{this.close}}
            @size="full"
            class="min-h-64"
            @backdrop="faded"
            as |m|
          >
            <m.Header>
              <p class="font-semibold">{{this.conditionToView.prompt}}</p>
            </m.Header>
            <m.Body>
              {{htmlSafe this.conditionToView.fullText}}
            </m.Body>
            <m.Footer>
              <Footer @isRunning={{false}} @onSubmit={{this.close}} @submitText={{t "close"}} />
            </m.Footer>
          </Modal>
        {{/if}}
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{this.setShowConditions}}
          @submitText={{t "close"}}
        />
      </m.Footer>
    </Modal>
  </template>
}
