import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';

<template>
  <LinkTo @route="authenticated.questions-you-have.paying-for-college">
    <Tile @headerText={{t "dashboard_tiles.types_of_student_loans"}}>
      <:description>
        <span>{{t "dashboard_tiles.learn_how_federal_loans"}}</span>
        <p class="font-semibold my-4 text-xl">
          {{t "dashboard_tiles.what_is_a_fed_loan"}}
        </p>
        <span>{{t "dashboard_tiles.fed_loan_blurb"}}</span>
      </:description>
    </Tile>
  </LinkTo>
</template>
