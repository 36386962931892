import { Divider } from 'tio-ui/components/utilities';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import AppPageHeroSection from 'tio-employee/components/app/page/hero-section';
import MaterialIcon from 'tio-common/components/material-icon';

<template>
  <VStack>
    <Section>
      <:body>
        <AppPageHeroSection class="-mx-4 -mt-4">
          <:title>
            {{t "planning_for_college.scholarship.hero_title"}}
          </:title>
          <:body>
            {{t "planning_for_college.scholarship.hero_detail"}}
          </:body>
        </AppPageHeroSection>
        <Section>
          {{! Search text & button }}
          <p class="pb-12 pt-6 font-semibold">
            {{t "planning_for_college.scholarship.learn_about" htmlSafe=true}}
          </p>
          <div class="flex justify-center">
            <a
              class="bg-ocean-600 text-white px-6 py-2 rounded-lg flex items-center"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.lendkey.com/scholarship-search/?sk=AFF-Tuitionio&utm_source=AFF-Tuitionio&utm_medium=referral&utm_campaign=AFF"
            >
              {{t "planning_for_college.scholarship.search_button" htmlSafe=true}}
            </a>
          </div>

          {{! Pros of a scholarship section }}
          <Section>
            <:header>
              {{t "planning_for_college.scholarship.pros_scholarship"}}
            </:header>
            <:body>
              <HStack>
                <div>
                  <MaterialIcon
                    @icon="thumb_up"
                    class="flex justify-center text-2xl text-ocean-600"
                  />
                  <p class="text-center text-lg">
                    {{t "planning_for_college.scholarship.pro_1"}}
                  </p>
                </div>
                <div>
                  <MaterialIcon
                    @icon="workspace_premium"
                    class="flex justify-center text-2xl text-ocean-600"
                  />
                  <p class="text-center text-lg">
                    {{t "planning_for_college.scholarship.pro_2"}}
                  </p>
                </div>
              </HStack>
            </:body>
          </Section>
        </Section>

        {{! cons of a scholarship section }}
        <Section>
          <:header>
            {{t "planning_for_college.scholarship.cons_scholarship"}}
          </:header>
          <:body>
            {{t "planning_for_college.scholarship.con_1"}}
          </:body>
        </Section>

        {{! How to apply section }}
        <Section>
          <:header>
            {{t "planning_for_college.scholarship.how_to_apply"}}
          </:header>
          <:body>
            {{! Step 1 - Search }}
            <div class="pt-10 flex flex-row">
              <div
                class="justify-self-center shrink-0 w-8 h-8 bg-ocean-600 border border-ocean-600 text-white rounded-full text-center items-center leading-8"
              >
                {{t "number_one"}}
              </div>
              <span class="ml-6 text-xl text-gray-500 font-bold text-center">
                {{t "planning_for_college.scholarship.search" htmlSafe=true}}
              </span>
            </div>
            <div class="py-4 sm:px-10">
              <p class="px-12">
                {{t "planning_for_college.scholarship.learn_about" htmlSafe=true}}
              </p>
            </div>
            <Divider class="my-2" />

            {{! Step 2 - Apply }}
            <div class="pt-10 flex flex-row">
              <div
                class="justify-self-center shrink-0 w-8 h-8 bg-ocean-600 border border-ocean-600 text-white rounded-full text-center items-center leading-8"
              >
                {{t "number_two"}}
              </div>
              <div class="ml-6 text-xl text-gray-500 font-bold flex items-center">
                {{t "planning_for_college.scholarship.apply" htmlSafe=true}}
              </div>
            </div>
            <div class="py-4 sm:px-10">
              <ul class="list-disc px-12">
                <li>
                  <p class="">
                    {{t "planning_for_college.scholarship.apply_li_1"}}
                  </p>
                </li>
                <li>
                  <p class="">
                    {{t "planning_for_college.scholarship.apply_li_2"}}
                  </p>
                </li>
              </ul>
            </div>
            <Divider class="my-2" />

            {{! Step 3 - Keep Up the Good Work }}
            <div class="pt-10 flex flex-row">
              <div
                class="justify-self-center shrink-0 w-8 h-8 bg-ocean-600 border border-ocean-600 text-white rounded-full text-center items-center leading-8"
              >
                {{t "number_three"}}
              </div>
              <span class="ml-6 text-xl text-gray-500 font-bold text-center">
                {{t "planning_for_college.scholarship.keep_up" htmlSafe=true}}
              </span>
            </div>
            <div class="py-4">
              <p class="px-12">
                {{t "planning_for_college.scholarship.keep_up_detail"}}
              </p>
            </div>
          </:body>
        </Section>
      </:body>
    </Section>
  </VStack>
</template>
