import type { TOC } from '@ember/component/template-only';
import TuitionAssistanceFormsCertificateCertificateDetails from 'tio-employee/components/tuition-assistance/forms/certificate/certificate-details';
import type { AuthenticatedTuitionAssistanceProgramsInstanceCertificateEditRouteModel } from 'tio-employee/routes/authenticated/tuition-assistance/programs/instance/certificate/edit';

interface S {
  Args: {
    model: AuthenticatedTuitionAssistanceProgramsInstanceCertificateEditRouteModel;
  };
}

const tmpl: TOC<S> = <template>
  {{! @glint-expect-error something is wierd with the types on route}}
  <TuitionAssistanceFormsCertificateCertificateDetails @model={{@model}} />
</template>;

export default tmpl;
