import { action } from '@ember/object';
import { Button, Chip } from 'tio-ui/components/buttons';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import TuitionAssistanceProgramCard from 'tio-common/components/tuition-assistance/program-card';
import type RouterService from '@ember/routing/router-service';
import type TasApplicationModel from 'tio-common/models/tas-application';

interface S {
  Args: {
    model: TasApplicationModel;
  };
}

export default class AdminTasDashboardRoute extends Component<S> {
  @service declare router: RouterService;

  get programTemplates() {
    return this.args.model.templates;
  }

  get requestedAmount() {
    return '$862,000.00';
  }

  get paidAmount() {
    return '$107,500.00';
  }

  @action
  goToReports() {
    this.router.transitionTo('authenticated.admin.reports');
  }

  @action
  goToApplications() {
    this.router.transitionTo('authenticated.admin.tuition-assistance.applications.index');
  }

  <template>
    <VStack>
      <Header>{{t "dashboard"}}</Header>
      <Button {{on "click" this.goToApplications}} @intent="primary">
        {{t "tuition_assistance.dashboard.go_to_applications"}}
      </Button>
      <Section>
        <:header>
          {{t "tuition_assistance.programs"}}
        </:header>
        <:body>
          <div class="flex flex-wrap gap-4 pb-16">
            {{#each this.programTemplates as |template|}}
              <TuitionAssistanceProgramCard
                @buttonText="View Program Setup"
                @buttonRoute="authenticated.admin.tuition-assistance.program-templates.show"
                @buttonRouteModel={{template.id}}
                @title={{template.programName}}
                @titleClass="text-center pt-6"
              >
                {{#if template.isTrial}}
                  <div class="flex justify-center items-center">
                    <Chip @size="sm" @intent="danger">
                      {{t "common.trial"}}
                    </Chip>
                  </div>
                {{/if}}
              </TuitionAssistanceProgramCard>
            {{else}}
              {{t "tuition_assistance.no_programs_launched"}}
            {{/each}}
          </div>
        </:body>
      </Section>
    </VStack>
  </template>
}
