import { Button } from 'tio-ui/components/buttons';
import { get } from '@ember/object';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { type TBodyOptions } from 'tio-common/components/table/index';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import Table from 'tio-common/components/table/index';
import tippy from 'ember-tippy/modifiers/tippy';
import type { TOC } from '@ember/component/template-only';
import type AccountModel from 'tio-common/models/account';
import type LoanModel from 'tio-common/models/loan';

interface TableRowSignature {
  Args: {
    loan: LoanModel;
    paid?: boolean;
    options: typeof TBodyOptions;
  };
}
const TableRow: TOC<TableRowSignature> = <template>
  <@options.tr>
    <@options.td>{{@loan.name}}</@options.td>
    <@options.td>{{@loan.loanTypeDisplayName}}</@options.td>
    <@options.td>
      {{#if (get @loan.observation "repayment-plan")}}
        {{if @paid (t "accounts.paid_in_full") (get @loan.observation "repayment-plan")}}
      {{else}}
        {{t "common.unknown"}}
      {{/if}}
    </@options.td>
    <@options.td>{{if @paid (t "accounts.paid_in_full") @loan.status}}</@options.td>
    <@options.td>
      {{safeFormatNumber @loan.originalAmount style="currency" currency="USD"}}
      {{t "common.on"}}
      {{safeFormatDate @loan.originalDate month="short" day="2-digit" year="numeric"}}
    </@options.td>
    <@options.td>
      {{t
        "common.accounts.interest_due"
        interestPercentage=@loan.interestRate
        interestDue=(safeFormatNumber @loan.interestBalance style="currency" currency="USD")
      }}
    </@options.td>
    <@options.td>
      {{safeFormatNumber @loan.principalBalance style="currency" currency="USD"}}
    </@options.td>
  </@options.tr>
</template>;

export interface AccountTableSignature {
  Args: {
    account: AccountModel;
    isReceivingContributions: boolean;
    toggleChangeAccountModal: () => void;
  };
  Element: HTMLElement;
}
export default class AccountTable extends Component<AccountTableSignature> {
  get account() {
    return this.args.account;
  }

  get accountNumber() {
    return this.account.number || 'unknown';
  }

  get loans() {
    return this.account.activeLoans.flat();
  }

  get unpaidLoans() {
    return this.loans.filter((l) => l.currentBalance);
  }

  get paidLoans() {
    return this.loans.filter((loan) => loan.status === 'PAID_IN_FULL');
  }

  get hasMultipleLoans() {
    return this.paidLoans.length + this.unpaidLoans.length > 1;
  }

  <template>
    <VStack @collapsed={{true}}>
      <Section>
        <div class="flex justify-between">
          <span>{{this.account.institutionName}}</span>
          {{#if @isReceivingContributions}}
            <div>
              <span class="pr-4 border-r border-r-gray-500">
                <pre class="text-green-500 inline">&bull;</pre>
                {{t "common.accounts.receiving_contributions"}}
              </span>
              <Button
                @size="sm"
                @appearance="minimal"
                @intent="primary"
                {{on "click" @toggleChangeAccountModal}}
              >
                {{t "slr.change"}}
              </Button>
            </div>
          {{/if}}
        </div>
        <div class="mb-2">
          <div class="block text-sm text-gray-700">
            {{t "accounts.last_update"}}
            {{safeFormatDate
              this.account.updatedAt
              month="short"
              day="2-digit"
              year="numeric"
              timeZone="UTC"
            }}
          </div>
          <div class="block text-sm text-gray-700">
            {{t "accounts.date_as_of"}}
            {{safeFormatDate
              this.account.providerSuccessAt
              month="short"
              day="2-digit"
              year="numeric"
              timeZone="UTC"
            }}
          </div>
        </div>
        <HStack @collapsed={{true}}>
          <Section>
            <:header>{{t "accounts.payment_details"}}</:header>
            <:body>
              <Table @isLoading={{false}}>
                <:thead as |options|>
                  <options.tr>
                    <options.th>{{t "accounts.account_number"}}</options.th>
                    <options.th>{{t "accounts.address"}}</options.th>
                  </options.tr>
                </:thead>
                <:tbody as |options|>
                  <options.tr class="align-top">
                    <options.td>{{this.accountNumber}}</options.td>
                    <options.td>
                      {{#if @account.postalAddress}}
                        {{@account.postalAddress.addressLine1}}<br />
                        {{#if @account.postalAddress.addressLine2}}
                          {{@account.postalAddress.addressLine2}}<br />
                        {{/if}}
                        {{#if @account.postalAddress.addressLine3}}
                          {{@account.postalAddress.addressLine3}}<br />
                        {{/if}}
                        {{@account.postalAddress.city}}
                        {{@account.postalAddress.state}}
                        {{@account.postalAddress.postalCode}}
                      {{else}}
                        {{t "common.no_data"}}
                      {{/if}}
                    </options.td>
                  </options.tr>
                </:tbody>
              </Table>
            </:body>
          </Section>
          <Section>
            <:header>{{t "accounts.account_details"}}</:header>
            <:body>
              <Table @isLoading={{false}}>
                <:thead as |options|>
                  <options.tr>
                    <options.th>{{t "accounts.monthly_payment"}}</options.th>
                    <options.th>{{t "accounts.principal"}}</options.th>
                    <options.th>{{t "common.interest"}}</options.th>
                  </options.tr>
                </:thead>
                <:tbody as |options|>
                  <options.tr>
                    <options.td>
                      {{safeFormatNumber
                        this.account.providerRegularMonthlyPayment
                        style="currency"
                        currency="USD"
                      }}
                    </options.td>
                    <options.td>
                      {{safeFormatNumber
                        this.account.principalBalance
                        style="currency"
                        currency="USD"
                      }}
                    </options.td>
                    <options.td>
                      {{safeFormatNumber
                        this.account.interestBalance
                        style="currency"
                        currency="USD"
                      }}
                    </options.td>
                  </options.tr>
                </:tbody>
              </Table>
            </:body>
          </Section>
        </HStack>
      </Section>
      <Section>
        {{! UNPAID LOANS - EXPANDABLE TABLE }}
        <Table @isLoading={{false}} ...attributes>
          <:thead as |options|>
            <options.tr>
              <options.th>{{t "common.name"}}</options.th>
              <options.th>
                <div class="flex items-center">
                  {{t "common.type"}}
                  <MaterialIcon
                    @icon="info"
                    class="text-lg ml-1 leading-none text-ocean-600"
                    {{tippy (t "accounts.type_info") placement="top"}}
                  />
                </div>
              </options.th>
              <options.th>
                <div class="flex items-center text-nowrap">
                  {{t "accounts.repayment_plan"}}
                  <MaterialIcon
                    @icon="info"
                    class="text-lg ml-1 leading-none text-ocean-600"
                    {{tippy (t "accounts.plan_info") placement="top"}}
                  />
                </div>
              </options.th>
              <options.th>
                <div class="flex items-center">
                  {{t "status"}}
                  <MaterialIcon
                    @icon="info"
                    class="text-lg ml-1 leading-none text-ocean-600"
                    {{tippy (t "accounts.status_info") placement="top"}}
                  />
                </div>
              </options.th>
              <options.th>{{t "accounts.date_borrowed"}}</options.th>
              <options.th>
                <div class="flex items-center text-nowrap">
                  {{t "common.interest"}}
                  <MaterialIcon
                    @icon="info"
                    class="text-lg ml-1 leading-none text-ocean-600"
                    {{tippy (t "accounts.rate_info") placement="top"}}
                  />
                </div>
              </options.th>
              <options.th>{{t "current_balance"}}</options.th>
            </options.tr>
          </:thead>
          <:tbody as |options|>
            {{#each this.unpaidLoans as |loan|}}
              <TableRow @loan={{loan}} @options={{options}} />
            {{/each}}
            {{#each this.paidLoans as |loan|}}
              <TableRow @loan={{loan}} @paid={{true}} @options={{options}} />
            {{/each}}
            {{#if this.hasMultipleLoans}}
              <options.tr>
                <options.td colspan="5" />
                <options.td>
                  {{safeFormatNumber this.account.interestBalance style="currency" currency="USD"}}
                </options.td>
                <options.td>
                  {{safeFormatNumber this.account.principalBalance style="currency" currency="USD"}}
                </options.td>
              </options.tr>
            {{/if}}
          </:tbody>
        </Table>
      </Section>
    </VStack>
  </template>
}
