import type TasCourseModel from 'tio-common/models/tas-course';
import { t } from 'ember-intl';
import TuitionAssistanceFormsCoursesCourseAddGrade from 'tio-employee/components/tuition-assistance/forms/courses/course-add-grade';
import type { TOC } from '@ember/component/template-only';

interface S {
  Args: {
    model: TasCourseModel;
  };
}

const tmpl: TOC<S> = <template>
  <h3 class="font-semibold mt-2 text-midnight">
    {{t "tuition_assistance.program_details.courses.course_information"}}
  </h3>
  <h4 class="font-semibold">
    {{t "tuition_assistance.program_details.courses.please_add_grades_and_attachments"}}
  </h4>

  <TuitionAssistanceFormsCoursesCourseAddGrade @course={{@model}} />
</template>;

export default tmpl;
