import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';

<template>
  <LinkTo @route="authenticated.planning-for-college.529-tools">
    <Tile @headerText={{t "dashboard_tiles.529_plans"}}>
      <:description>
        {{t "dashboard_tiles.save_now"}}
      </:description>
      <:image>
        {{svgJar
          "529-piggy"
          width="100%"
          height="100%"
          role="img"
          desc=(t "svg.five_two_nine_piggy")
        }}
      </:image>
    </Tile>
  </LinkTo>
</template>
