import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import type { TOC } from '@ember/component/template-only';
import type TasApplicationModel from 'tio-common/models/tas-application';
import { Header, VStack } from 'tio-ui/components/layout';
import { Chip } from 'tio-ui/components/buttons';

interface S {
  Args: {
    model: TasApplicationModel;
  };
}

const tmpl: TOC<S> = <template>
  {{pageTitle (t "tuition_assistance.application_details.default")}}
  <VStack>
    <Header>
      {{t "tuition_assistance.application_details.default"}}
      {{#if @model.migrationApplicationId}}
        <Chip @size="sm" @intent="success">
          {{t "tuition_assistance.migrated"}}
        </Chip>
      {{/if}}
      {{#if @model.isTrial}}
        <Chip @size="sm" @intent="danger">
          {{t "common.trial"}}
        </Chip>
      {{/if}}
    </Header>
    {{outlet}}
  </VStack>
</template>;

export default tmpl;
