import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';

<template>
  <Tile @headerText={{t "dashboard_tiles.starbucks_scap"}}>
    <:description>
      {{t "dashboard_tiles.starbucks_scap_description"}}
      <p class="font-semibold my-4 text-xl">{{t "dashboard_tiles.starbucks_scap_title_1"}}</p>
      {{t "dashboard_tiles.starbucks_scap_blurb_1" htmlSafe=true}}
      <p class="font-semibold my-4 text-l">
        {{t "dashboard_tiles.starbucks_scap_title_2"}}
      </p>
      <LinkTo
        class="underline text-ocean-600"
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard"
      >
        {{t "dashboard_tiles.starbucks_scap_blurb_2"}}
      </LinkTo>
    </:description>
  </Tile>
</template>
