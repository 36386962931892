import { Header, HStack, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import ApplicationHistory from 'tio-common/components/tas/application-history';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import NavTabs from 'tio-ui/components/nav-tabs';
import PaymentDetailsCard from 'tio-common/components/tas/payment-details-card';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type { TOC } from '@ember/component/template-only';

interface TasApplicationHistorySignature {
  Args: {
    model: TasApplicationModel;
  };
}

const tmpl: TOC<TasApplicationHistorySignature> = <template>
  <VStack>
    <Header>
      <div>
        {{! TAS.TODO: temporary routing until tas dashboard is complete }}
        <LinkTo class="flex justify-left items-center gap-4" @route="authenticated.tas.dashboard">
          <ChevronLeft class="w-5 inline" />
          {{t "tas.default"}}
        </LinkTo>
      </div>
    </Header>
    <NavTabs @model={{@model}} class="mb-4" as |navtabs|>
      <navtabs.item @route="authenticated.tas.applications.show" @model={{@model.id}}>
        {{t "tas.applications.default"}}
      </navtabs.item>
      <navtabs.item @route="authenticated.tas.applications.history" @model={{@model}}>
        {{t "tas.history.default"}}
      </navtabs.item>
    </NavTabs>
    <HStack>
      <ApplicationHistory @history={{@model.history}} @title={{@model.applicationName}} />
      <VStack class="md:w-1/3">
        <PaymentDetailsCard
          @isPaid={{@model.isPaid}}
          @requestedTotal={{@model.requestedTotal}}
          @payments={{@model.paymentsHistory}}
        />
      </VStack>
    </HStack>
  </VStack>
</template>;

export default tmpl;
