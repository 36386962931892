import { t } from 'ember-intl';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';

<template>
  <div class="flex flex-col h-screen container mx-auto">
    <h2 class="mt-6 text-center text-lg">{{t "login.in_progress"}}</h2>

    <div class="grow flex items-center">
      <TioLoadingSpinner />
    </div>
  </div>
</template>
