import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import TuitionAssistanceFormsChangeRequestAlert from '../../../../../../components/tuition-assistance/forms/change-request-alert';
import TuitionAssistanceFormsPreApprovalEmployeeInformation from '../../../../../../components/tuition-assistance/forms/pre-approval/employee-information';
import DependentInformation from '../../../../../../components/tuition-assistance/program-details/dependent-information';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import type TasProgramInstance from 'tio-common/models/tas-program-instance';
import { Section } from 'tio-ui/components/layout';

interface TAProgramInstanceApplyEmployeeInformationRouteSignature {
  Args: {
    model: TasProgramInstance;
  };
}

export default class TuitionAssistanceProgramInstanceApplyEmployeeInformationRouteComponent extends Component<TAProgramInstanceApplyEmployeeInformationRouteSignature> {
  @service declare store: typeof Store;
  @service declare router: RouterService;

  @action
  cancel() {
    this.router.transitionTo('authenticated.tas.dashboard');
  }

  @action
  async saveForLater(
    fieldsCopy: TasProgramInstance['fields'],
    customFieldsCopy: TasProgramInstance['customFields']
  ) {
    await this.saveFields.perform(fieldsCopy, customFieldsCopy);
    this.router.transitionTo('authenticated.tas.dashboard');
  }

  @action
  async saveAndNext(
    fieldsCopy: TasProgramInstance['fields'],
    customFieldsCopy: TasProgramInstance['customFields'],
    isFormValid: boolean
  ) {
    if (!isFormValid) {
      return;
    }
    await this.saveFields.perform(fieldsCopy, customFieldsCopy);
    this.router.transitionTo(
      'authenticated.tuition-assistance.programs.instance.apply.program-information'
    );
  }

  saveFields = dropTask(async (fieldsCopy, customFieldsCopy) => {
    this.args.model.fields = fieldsCopy;
    this.args.model.customFields = customFieldsCopy;
    try {
      await this.args.model.save();
    } catch (e) {
      console.error(e);
    }
  });

  <template>
    {{#if @model.tasProgramTemplate.isDependentProgram}}
      <Section>
        <:body>
          <DependentInformation @programInstance={{@model}} />
        </:body>
      </Section>
    {{else}}
      <Section>
        <:header>{{t "tuition_assistance.preapproval_app.employee_info"}}</:header>
        <:body>
          <h4>{{t "tuition_assistance.preapproval_app.subtitle_step1"}}</h4>
          <TuitionAssistanceFormsChangeRequestAlert
            @transitionState="PROGRAM_NOT_APPROVED"
            @transitionLog={{@model.transitionLog}}
          />

          <TuitionAssistanceFormsPreApprovalEmployeeInformation
            @programInstance={{@model}}
            @saveAndNext={{this.saveAndNext}}
            @saveForLater={{this.saveForLater}}
            @cancel={{this.cancel}}
            @showActionButtons={{true}}
            @saveTaskIsRunning={{this.saveFields.isRunning}}
          />
        </:body>
      </Section>
    {{/if}}
  </template>
}
