import type PslfFormModel from 'tio-common/models/pslf-form';
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import type { IntlService } from 'ember-intl';
import { Header, Section, VStack } from 'tio-ui/components/layout';

interface AdminPslfFormSuccessRouteSignature {
  Args: {
    model: PslfFormModel;
  };
}
export default class AdminPslfFormSuccessRoute extends Component<AdminPslfFormSuccessRouteSignature> {
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get borrowerName() {
    return this.args.model.borrowerFields['borrower-name'];
  }

  get dateEmployerSigned() {
    const dateSigned = this.args.model.employerCompletedAt;
    return dateSigned ? dateSigned : new Date();
  }

  get employerMessage() {
    return this.intl.t('pslf.complete_pslf.done_employer_subheader', {
      date: this.intl.formatDate(this.dateEmployerSigned),
      name: this.borrowerName,
    });
  }

  <template>
    <VStack>
      <Header>{{t "pslf.default_full"}}</Header>
      <Section>
        {{#if this.dateEmployerSigned}}
          <h1 class="text-2xl font-semibold my-4"> {{t "pslf.complete_pslf.title_success"}}</h1>
          <p class="text-lg my-4">{{this.employerMessage}}.</p>
          <p class="text-lg">{{t "pslf.complete_pslf.done_employer"}}</p>
        {{/if}}
      </Section>
    </VStack>
  </template>
}
