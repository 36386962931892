import { array } from '@ember/helper';
import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TuitionAssistanceProgramDetailsCancelRequest from 'tio-employee/components/tuition-assistance/program-details/cancel-request';
import type TasApplication from 'tio-common/models/tas-application';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import type { TOC } from '@ember/component/template-only';

interface S {
  Args: {
    model: TasApplication;
  };
}

const tmpl: TOC<S> = <template>
  {{pageTitle (t "tuition_assistance.program_details.courses.courses_application")}}

  <VStack>
    <Header>{{t "tuition_assistance.default"}}</Header>
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.tas.dashboard" @label={{t "dashboard"}} />
      <b.crumb
        @route="authenticated.tuition-assistance.programs.instance.index"
        @label={{@model.tasProgramInstance.programName}}
      />
      <b.crumb
        @route="authenticated.tuition-assistance.programs.instance.course-applications.show"
        @label={{@model.displayName}}
      />
    </TioPageBreadcrumbs>

    <Section>
      <:body>
        {{outlet}}
        <TuitionAssistanceProgramDetailsCancelRequest
          @application={{@model}}
          @modelTypes={{array "tas-application"}}
          @linkText={{t "tuition_assistance.program_details.cancel_app.delete_course"}}
          @canCancel={{@model.canCancelApplication}}
        />
      </:body>
    </Section>
  </VStack>
</template>;

export default tmpl;
