import type { TOC } from '@ember/component/template-only';

interface QuestionsLoanTypesCardSignature {
  Args: {
    imageAlt?: string;
    label: string;
    src: string;
  };
  Element: HTMLDivElement;
}

const QuestionsLoanTypesCardComponent: TOC<QuestionsLoanTypesCardSignature> = <template>
  <div
    class="text-center md:col-4 col-12 p-5 block max-w-2/6 whitespace-normal relative border-b"
    ...attributes
  >
    <img class="mx-auto mb-4 h-12 w-12 min-w-12" alt={{@imageAlt}} src={{@src}} />
    <span>{{@label}}</span>
  </div>
</template>;

export default QuestionsLoanTypesCardComponent;
