import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import FormInput from '@frontile/forms-legacy/components/form-input';
import type { TOC } from '@ember/component/template-only';
import { Button } from 'tio-ui/components/buttons';
import { VStack } from 'tio-ui/components/layout';

export interface EmailLookupComponentSignature {
  Args: {
    email: string;
    emailLookupIsLoading: boolean;
    validationErrors: string[];
    submitEmailLookup: () => void;
  };
}

const EmailLookupComponent: TOC<EmailLookupComponentSignature> = <template>
  <form {{on "submit" @submitEmailLookup}}>
    <VStack>
      <div>
        <FormInput
          data-legacy-input
          @label={{t "login.email"}}
          @value={{@email}}
          @onInput={{fn (mut @email)}}
          @errors={{@validationErrors}}
          @type="email"
        />
      </div>
      <div class="flex flex-col items-center">
        <Button
          {{on "click" @submitEmailLookup}}
          @appearance="outlined"
          @intent="primary"
          @isRunning={{@emailLookupIsLoading}}
          class="w-36 mb-10"
        >
          {{t "submit"}}
        </Button>
      </div>
    </VStack>
  </form>
</template>;

export default EmailLookupComponent;
