import { VStack } from 'tio-ui/components/layout';
import NewTypeFourApp from 'tio-employee/components/tas/applications/new/type-four';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type { TOC } from '@ember/component/template-only';

export interface S {
  Args: {
    model: {
      application: TasApplicationModel;
      programInstance: TASProgramInstanceModel;
    };
  };
}

const tmpl: TOC<S> = <template>
  <VStack>
    <NewTypeFourApp @model={{@model}} />
  </VStack>
</template>;

export default tmpl;
