import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import transitionTo from 'ember-router-helpers/helpers/transition-to';

<template>
  <EmailLookupWrapper @route="forgot-password/confirmation">
    <div class="flex flex-col p-6">
      <h1 class="text-lg mb-6">{{t "login.forgot_password_confirmation_title"}}</h1>
      <p class="mb-6">{{t "login.forgot_password_confirmation_body"}}</p>
      <Button @appearance="outlined" class="w-36 mx-auto" {{on "click" (transitionTo "login")}}>
        {{t "login.back_to_login"}}
      </Button>
    </div>
  </EmailLookupWrapper>
</template>
