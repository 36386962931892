import { action } from '@ember/object';
import { and, not } from 'tio-ui/utilities';
import { Button } from 'tio-ui/components/buttons';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { getProvider, getStatus } from 'tio-common/helpers/observability';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import AccountTable from 'tio-employee/components/accounts/account-table';
import AppAlertNoLinkedLoans from 'tio-employee/components/app/alert/no-linked-loans';
import Badge from 'tio-ui/components/badge';
import ChangeActiveAccountModal from 'tio-common/components/account/change-active-account-modal';
import Component from '@glimmer/component';
import didIntersect from 'ember-scroll-modifiers/modifiers/did-intersect';
import DocumentArrowUp from 'ember-static-heroicons/components/outline-24/document-arrow-up';
import embedPdf from 'tio-common/modifiers/embed-pdf';
import Envelope from 'ember-static-heroicons/components/outline-24/envelope';
import pageTitle from 'ember-page-title/helpers/page-title';
import Paginator from 'tio-common/components/tio/paginator';
import RouterService from '@ember/routing/router-service';
import Table from 'tio-common/components/table/index';
import TioPdfModal from 'tio-common/components/tio/pdf-modal';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type { Intent } from 'tio-ui/utilities';
import type AccountModel from 'tio-common/models/account';
import type EmployeeService from 'tio-employee/services/employee';
import type IntlService from 'ember-intl/services/intl';
import type { NotificationsService } from 'tio-ui/components/notifications';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import type PersonModel from 'tio-common/models/person';
import type StoreService from 'tio-common/services/store';

const getStatusIntent = (status: string): Intent | undefined => {
  switch (status) {
    case 'PROCESSING':
      return 'primary';
    case 'INVALID':
    case 'FAILED':
    case 'NOT_APPLIED':
      return 'error';
    case 'PROCESSED':
    case 'APPLIED':
      return 'success';
    case 'IN_DISPUTE':
    case 'PARTIALLY_APPLIED':
      return 'warning';
  }
};

const getSubmittedIcon = (via: string) => {
  if (via === 'EMAIL') {
    return Envelope;
  }
  return DocumentArrowUp;
};

const getCreatedAt = (document: ObservableDocumentModel) => {
  const createdAtDate = new Date(document.createdAt);
  return createdAtDate.toLocaleString();
};

interface RouteSignature {
  Args: {
    model: PersonModel;
  };
}

export default class AccountActivityAccountRouteComponent extends Component<RouteSignature> {
  @service declare employee: EmployeeService;
  @service declare notifications: NotificationsService;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  @tracked showAddLoansModal = false;
  @tracked showChangeAccountModal = false;

  get person() {
    return this.args.model;
  }

  get activeAccounts() {
    return this.person?.activeAccounts || [];
  }

  get paidAccounts() {
    return this.person?.paidAccounts || [];
  }

  get unpaidAccounts() {
    return this.activeAccounts
      .filter((account) => !account.isPaidOff)
      .sort((a, b) => a.publicOrdinalValue - b.publicOrdinalValue);
  }

  get loans() {
    return this.activeAccounts.map((account) => account.activeLoans).flat() || [];
  }

  get loansTotal() {
    const total = this.loans.reduce((sum, loan) => {
      return (sum += loan.currentBalance);
    }, 0);

    return total.toFixed(2) as unknown as number;
  }

  get hasContributions() {
    return this.employee.hasContributions;
  }

  get showContributionSelector() {
    return this.hasContributions && this.activeAccounts.length > 1;
  }

  @action
  isReceivingContributions(account: AccountModel) {
    return this.person.accountReceivingContributionsId === account.id;
  }

  @action
  toggleChangeAccountModal() {
    this.showChangeAccountModal = !this.showChangeAccountModal;
  }

  updateAccountTask = dropTask(async ({ id }: AccountModel) => {
    try {
      const orderedAccountIds = [
        id,
        ...this.activeAccounts.filter((account) => account.id !== id).map((account) => account.id),
      ];
      await this.store
        .adapterFor('person')
        .updateContributionAccount(this.person.id, orderedAccountIds);
      this.router.refresh('authenticated.account-activity.accounts');
      this.notifications.add(this.intl.t('common.accounts.change_modal.success'), {
        appearance: 'success',
      });
    } catch (error) {
      console.error('Error updating contribution account for account:', id, error);
      this.notifications.add(this.intl.t('common.accounts.change_modal.error'), {
        appearance: 'error',
      });
    }
  });

  <template>
    {{pageTitle "Student Loan Summary"}}
    <VStack>
      <Header>
        <div class="flex justify-between items-center">
          <p>{{t "accounts.my_accounts"}}</p>
          <Button
            @intent="primary"
            @appearance="outlined"
            {{on "click" (transitionTo "authenticated.observability.upload")}}
          >
            {{t "common.accounts.add_account"}}
          </Button>
        </div>
      </Header>

      <ChangeActiveAccountModal
        @onClose={{this.toggleChangeAccountModal}}
        @show={{this.showChangeAccountModal}}
        @accountOptions={{this.activeAccounts}}
        @accountReceivingContributions={{@model.accountReceivingContributions}}
        @submitAction={{this.updateAccountTask}}
      />

      {{#if (and (not this.activeAccounts.length) @model.needsToAnswerWhyNoLinkedLoans)}}
        <div class="col-span-6 mb-4">
          <AppAlertNoLinkedLoans @person={{@model}} />
        </div>
      {{/if}}
      {{#each this.unpaidAccounts as |account|}}
        <Section class="overflow-x-auto">
          <:body>
            <AccountTable
              @account={{account}}
              @isReceivingContributions={{this.isReceivingContributions account}}
              @toggleChangeAccountModal={{this.toggleChangeAccountModal}}
            />
          </:body>
        </Section>
      {{/each}}
      {{! TODO: only show section if there are documents to display }}
      <Section>
        <:header>{{t "accounts.my_documents"}}</:header>
        <:body>
          <DocumentsTable @person={{@model}} />
        </:body>
      </Section>
      {{#if this.paidAccounts.length}}
        <Section>
          <:header>{{t "accounts.paid_in_full"}}</:header>
          <:body>
            <Table>
              <:thead as |options|>
                <options.tr>
                  <options.th>{{t "accounts.servicer"}}</options.th>
                  <options.th>{{t "accounts.number_of_loans"}}</options.th>
                </options.tr>
              </:thead>
              <:tbody as |options|>
                {{#each this.paidAccounts as |account|}}
                  <options.tr>
                    <options.td>{{account.institutionName}}</options.td>
                    <options.td>{{account.activeLoans.length}}</options.td>
                  </options.tr>
                {{/each}}
              </:tbody>
            </Table>
          </:body>
        </Section>
      {{/if}}
    </VStack>
  </template>
}

const PAGE_SIZE = 10;

interface S {
  Args: {
    person: PersonModel;
  };
}
class DocumentsTable extends Component<S> {
  @service declare store: typeof StoreService;

  @tracked page = 1;
  @tracked limit = PAGE_SIZE;
  @tracked showPreview = false;
  @tracked isInViewport = false;
  @tracked pdfSrc = '';

  documentsData = trackedFunction(this, async () => {
    const person = this.args.person;
    const query = {
      filter: { person: person.id },
      include: 'observable-asset',
      sort: '-created-at',
      page: { limit: PAGE_SIZE, offset: (this.page - 1) * PAGE_SIZE },
    };

    return this.store.query('observable-document', query);
  });

  get documents() {
    return this.documentsData?.value || [];
  }

  @action
  changePaginatorPage(page: number) {
    this.page = page;
  }

  @action
  closePreviewModal() {
    this.showPreview = false;
  }

  @action
  async getDocument(document: ObservableDocumentModel) {
    // TODO: Can we query the asset directly?
    const doc = await this.store.findRecord('observable-document', document.id, {
      include: 'observable-asset',
    });
    this.pdfSrc = doc.observableAsset.signedUrl;
    this.showPreview = true;
  }

  <template>
    <Table {{didIntersect onEnter=(fn (mut this.isInViewport) true)}} @isLoading={{false}}>
      <:thead as |options|>
        <options.tr>
          <options.th>{{t "observability.documents.index.headers.filename"}}</options.th>
          <options.th>{{t "observability.documents.index.headers.provider"}}</options.th>
          <options.th>{{t "observability.documents.index.headers.status"}}</options.th>
          <options.th>{{t "observability.documents.index.headers.submitted"}}</options.th>
        </options.tr>
      </:thead>
      <:tbody as |options|>
        {{#if this.isInViewport}}
          {{#each this.documents as |document|}}
            <options.tr>
              <options.td>
                <LinkTo
                  @route="authenticated.observability.documents.show"
                  @model={{document}}
                  class="tio-anchor"
                >
                  {{document.observableAsset.filename}}
                </LinkTo>
              </options.td>
              <options.td>
                {{t "observable_document.provider" provider=(getProvider document)}}
              </options.td>
              <options.td>
                <Badge @intent={{getStatusIntent (getStatus document)}}>
                  {{t "observable_document.status" state=(getStatus document)}}
                </Badge>
              </options.td>
              <options.td>
                <div class="flex flex-row flex-nowrap justify-left items-center">
                  {{#let (getSubmittedIcon document.via) as |SubmittedIcon|}}
                    <SubmittedIcon class="w-6 mr-2" />
                  {{/let}}
                  {{getCreatedAt document}}
                </div>
              </options.td>
            </options.tr>
          {{/each}}
        {{/if}}
      </:tbody>
      <:tfoot as |options|>
        <options.tr>
          <options.td colspan="4">
            <Paginator @totalItems={{this.documents.meta.page.total}} @pageSize={{PAGE_SIZE}} />
          </options.td>
        </options.tr>
      </:tfoot>
    </Table>
    <TioPdfModal @show={{this.showPreview}} @onClose={{this.closePreviewModal}}>
      {{#if this.pdfSrc}}
        <div {{embedPdf this.pdfSrc}}></div>
      {{/if}}
    </TioPdfModal>
  </template>
}
