import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';

<template>
  <QuestionsLoanForgiveness
    @indexRoute="authenticated.pslf.questions.index"
    @incomeRoute="authenticated.pslf.questions.income-driven"
    @teacherRoute="authenticated.pslf.questions.teacher"
    @stateRoute="authenticated.pslf.questions.state-and-local"
  >
    {{outlet}}
  </QuestionsLoanForgiveness>
</template>
