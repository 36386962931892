import { action } from '@ember/object';
import { applicationBadgeState } from 'tio-employee/utils/tas/applicationStateMap';
import { Button } from 'tio-ui/components/buttons';
import { dropTask } from 'ember-concurrency';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { instanceBadgeState } from 'tio-employee/utils/tas/instanceStateMap';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { type TBodyOptions } from 'tio-common/components/table/index';
import { VStack, HStack, Section } from 'tio-ui/components/layout';
import Badge from 'tio-ui/components/badge';
import Component from '@glimmer/component';
import formatCentsToDollars from 'tio-common/helpers/format-cents-to-dollars';
import NavTabs from 'tio-ui/components/nav-tabs';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import StartDate from 'tio-employee/components/tas/start-date-eligibility';
import Table from 'tio-common/components/table/index';
import TASProgramDetailsWithdrawComplete from 'tio-employee/components/tuition-assistance/program-details/withdraw-complete';
import TioAlert from 'tio-common/components/tio/alert';
import type { Intent } from 'tio-ui/utilities';
import type { Step } from 'tio-common/components/tio/progress-tracker';
import type { TasProgramInstanceState } from 'tio-common/types/tuition-assistance';
import type { TOC } from '@ember/component/template-only';
import type IntlService from 'ember-intl/services/intl';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TasParticipantModel from 'tio-common/models/tas-participant';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
import VerticalProgressTracker from 'tio-common/components/tio/vertical-progress-tracker';

interface RouteSignature {
  Args: {
    model: {
      activeInstances: TASProgramInstanceModel[];
      programInstance: TASProgramInstanceModel;
      tasParticipant: TasParticipantModel;
    };
  };
}

interface ApplicationRowSignature {
  Args: {
    options: typeof TBodyOptions;
    appName: string;
    programInstanceBadgeState: Record<string, Intent>;
    application: TasApplicationModel;
  };
}

const ApplicationRow: TOC<ApplicationRowSignature> = <template>
  <@options.tr>
    <@options.td>
      <LinkTo
        @route="authenticated.tas.applications.show"
        @model={{@application.id}}
        class="flex items-end gap-x-4"
      >
        <span class="text-xl font-medium underline text-ocean-600">{{@appName}}</span>
        <Badge @intent={{@programInstanceBadgeState.intent}}>
          {{@programInstanceBadgeState.label}}
        </Badge>
      </LinkTo>
    </@options.td>
    <@options.td>{{@application.tasCourses.length}} {{t "tas.program.courses"}}</@options.td>
    <@options.td>{{formatCentsToDollars @application.requestedTotal}}</@options.td>
  </@options.tr>

  {{!-- <LinkTo
    @route="authenticated.tas.applications.show"
    @model={{@application.id}}
    class="flex justify-between py-6 border-b hover:bg-gray-200"
  >
    <span class="flex md:flex-initial md:w-96 items-center">
      <h1 class="flex text-xl font-medium mr-2">{{@appName}}</h1>
      <Badge
        @intent={{@programInstanceBadgeState.intent}}
      >{{@programInstanceBadgeState.label}}</Badge>
    </span>
    <div class="flex">
      {{@application.tasCourses.length}}
      {{t "tas.program.courses"}}
    </div>
    <div class="flex">
      {{formatCentsToDollars @application.requestedTotal}}
    </div>
    <div class="flex">
      <MaterialIcon @icon="chevron_right" class="text-gray-600" />
    </div>
  </LinkTo> --}}
</template>;

export default class AuthenticatedTasProgramsShow extends Component<RouteSignature> {
  @service declare intl: IntlService;
  @service declare progressBuilder: ProgressBuilder;
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare tuitionAssistance: TuitionAssistanceService;

  @tracked isOpen = true;

  appBadgeState(application: TasApplicationModel) {
    return applicationBadgeState(
      application.state,
      application.programInstance?.approvalRequired
    ) as Record<string, Intent>;
  }

  getAppName(application: TasApplicationModel) {
    return getSingleValueForTasField('APPLICATION_NAME', application.fields) as string;
  }

  get programTemplate() {
    return this.args.model.programInstance.tasProgramTemplate;
  }

  get programInstanceBadgeState() {
    const approvalRequired = this.args.model.programInstance.approvalRequired;
    return instanceBadgeState(this.args.model.programInstance.state, approvalRequired) as Record<
      string,
      Intent
    >;
  }

  get institution() {
    return getSingleValueForTasField(
      'SCHOOL_INSTITUTION_NAME',
      this.args.model.programInstance.fields
    ) as string;
  }

  get isInProgress() {
    return this.args.model.programInstance.programInstanceInProgress;
  }

  get steps() {
    return this.progressBuilder.applicationProcess() as Step[];
  }

  get programProgress() {
    return this.progressBuilder.programProgress(this.args.model.programInstance) as Step[];
  }

  get hasActiveApplications() {
    return !!this.args.model.programInstance.tasApplications.length;
  }

  get isHeldParticipant() {
    return !!this.args.model.tasParticipant.isHeldParticipant;
  }

  get isActiveEligibility() {
    return this.tuitionAssistance.hasActiveEligibilityForProgramTemplate(this.programTemplate);
  }

  get ineligibleBasedOnWaitingPeriod() {
    return this.tuitionAssistance.ineligibleBasedOnWaitingPeriod(
      this.programTemplate.eligibilityWaitingPeriod,
      this.args.model.tasParticipant
    );
  }

  get isSingleApplicationProgram() {
    return !!getSingleValueForTasField('SINGLE_APPLICATION_PROGRAM', this.programTemplate.fields);
  }

  get canCreateApplications() {
    const { programApprovalRequired } = this.programTemplate;

    const validStates: TasProgramInstanceState[] = [
      'TAS.ProgramInstanceState.PRIMED',
      'TAS.ProgramInstanceState.PENDING_COMPLETION_APPROVAL',
    ];

    if (!programApprovalRequired) {
      validStates.push(
        'TAS.ProgramInstanceState.PROGRAM_APPROVED',
        'TAS.ProgramInstanceState.PENDING_PROGRAM_APPROVAL'
      );
    }
    return validStates.includes(this.args.model.programInstance.state);
  }

  get programHasExistingApplications() {
    return !!this.args.model.programInstance?.tasApplications?.length;
  }

  get typeClassification() {
    return this.programTemplate.typeClassification;
  }

  get canApplyForApplication() {
    if (
      this.programTemplate.state === 'TAS.ProgramTemplateState.SUSPENDED' ||
      this.typeClassification === 'TAS.ProgramType.1' ||
      this.isHeldParticipant ||
      !this.isActiveEligibility ||
      !this.sessionContext.currentEmployee.id
    ) {
      return false;
    } else if (this.isSingleApplicationProgram) {
      return this.canCreateApplications && !this.programHasExistingApplications;
    }
    return this.canCreateApplications;
  }

  get isPendingApproval() {
    if (!this.programTemplate.programApprovalRequired) {
      return false;
    }
    return (
      this.args.model.programInstance.state === 'TAS.ProgramInstanceState.PENDING_PROGRAM_APPROVAL'
    );
  }

  get showEligibility() {
    let lock = getSingleValueForTasField(
      'LOCK_EMPLOYEE_STATUS_BASED_ON',
      this.programTemplate.fields
    ) as string;
    const hasLock = () => ['COURSE_START_DATE', 'COURSE_END_DATE'].includes(lock);
    return hasLock();
  }

  get programTemplateName() {
    return getSingleValueForTasField('PROGRAM_NAME', this.programTemplate.fields) as string;
  }

  @action
  newApplicationAction() {
    const date = new Date().toLocaleDateString('en-CA');
    this.createApplicationAndApply.perform(date);
  }

  createApplicationAndApply = dropTask(async (date?: string) => {
    const route = 'authenticated.tas.applications.type-four.new';
    try {
      const application = this.store.createRecord('tas-application', {
        tasProgramInstance: this.args.model.programInstance,
      });
      await application.save();
      const queryParams: Record<string, unknown> = {};
      if (date) {
        queryParams.date = date;
      }
      this.router.transitionTo(route, application.id, { queryParams });
    } catch (error) {
      console.error('Failed to save application:', error);
    }
  });

  <template>
    <VStack class="w-full">
      <NavTabs class="w-full" as |navtabs|>
        <navtabs.item @route="authenticated.tas.programs.show">
          {{t "tas.program.program"}}
        </navtabs.item>
        <navtabs.item @route="authenticated.tas.programs.details">
          {{t "tas.program.details"}}
        </navtabs.item>
        <navtabs.item
          @route="authenticated.tas.programs.history"
          @model={{@model.programInstance.id}}
        >
          {{t "tas.program.history"}}
        </navtabs.item>
      </NavTabs>
      <HStack>
        <Section class="w-full lg:!w-2/3 md:!w-1/2">
          <:header>
            <div class="flex flex-row justify-between">
              <div class="flex items-center">
                <p class="mr-4">{{this.programTemplateName}}</p>
                <Badge @intent={{this.programInstanceBadgeState.intent}}>
                  {{this.programInstanceBadgeState.label}}
                </Badge>
              </div>
            </div>
          </:header>
          <:actions>
            <TASProgramDetailsWithdrawComplete
              @programInstance={{@model.programInstance}}
              @isHeldParticipant={{@model.tasParticipant.isHeld}}
            />
          </:actions>
          <:body>
            <VStack>
              <p>{{@model.programInstance.institutionName}}</p>
              {{#if @model.programInstance.estimatedProgramBegin}}
                <p>
                  {{safeFormatDate @model.programInstance.estimatedProgramBegin}}
                  -
                  {{safeFormatDate @model.programInstance.estimatedProgramCompletion}}
                </p>
              {{else}}
                <p class="italic">{{t "tas.program.start_date_not_set"}}</p>
              {{/if}}
              {{#if this.hasActiveApplications}}
                <VStack @collapsed={{true}}>
                  <Section>
                    <:body>
                      <div class="w-full flex justify-between items-center">
                        <h2 class="text-lg font-medium">
                          {{t "tuition_assistance.applications.course_applications"}}
                        </h2>
                        {{#if this.canApplyForApplication}}
                          <Button @intent="primary" {{on "click" this.newApplicationAction}}>
                            {{t "tas.program.new_application"}}
                          </Button>
                        {{/if}}
                      </div>
                      <Table>
                        <:thead as |options|>
                          <options.tr>
                            <options.th>{{t "tuition_assistance.application_name"}}</options.th>
                            <options.th>
                              {{t "tuition_assistance.program_details.courses.default"}}
                            </options.th>
                            <options.th>
                              {{t "tuition_assistance.program_details.courses.requested_total"}}
                            </options.th>
                          </options.tr>
                        </:thead>
                        <:tbody as |options|>
                          {{#each @model.programInstance.tasApplications as |application|}}
                            <ApplicationRow
                              @options={{options}}
                              @appName={{this.getAppName application}}
                              @programInstanceBadgeState={{this.appBadgeState application}}
                              @application={{application}}
                            />
                          {{/each}}
                        </:tbody>
                      </Table>
                    </:body>
                  </Section>
                </VStack>
              {{else if this.isPendingApproval}}
                <VStack @collapsed={{true}}>
                  <Section>
                    <TioAlert @type="primary" @allowDismiss={{false}} class="mb-4">
                      <:header>
                        <p class="font-bold">
                          {{t "tas.program.pending_program_approval"}}
                        </p>
                      </:header>
                      <:body>
                        <p class="text-sm text-violet-700">
                          {{t "tas.program.pending_program_approval_description"}}
                        </p>
                      </:body>
                    </TioAlert>
                  </Section>
                </VStack>
              {{else if this.canApplyForApplication}}
                <VStack @collapsed={{true}}>
                  <Section>
                    <h2 class="mb-4 text-lg font-medium">{{t "tas.program.apply_for_courses"}}</h2>
                    <p class="mb-4">{{t "tas.program.course_application_description"}}</p>
                    {{#if this.showEligibility}}
                      <StartDate
                        @canApplyToProgram={{this.canApplyForApplication}}
                        @buttonText="Get Started"
                        @isMultipleInstanceProgram={{true}}
                        @programTemplate={{this.programTemplate}}
                        @tasParticipant={{@model.tasParticipant}}
                        @applyToProgram={{this.createApplicationAndApply.perform}}
                      />
                    {{else}}
                      <Button
                        @intent="primary"
                        {{on "click" this.newApplicationAction}}
                        class="uppercase w-60"
                        data-test-submit-eligibility
                      >
                        {{t "login.get_started"}}
                      </Button>
                    {{/if}}
                  </Section>
                  <Section>
                    <h2 class="mb-4 text-lg font-medium">
                      {{t "tas.program.course_application_process"}}
                    </h2>
                    <VerticalProgressTracker class="m-6" @steps={{this.steps}} />
                  </Section>
                </VStack>
              {{/if}}
            </VStack>
          </:body>
        </Section>
        <Section class="w-full lg:!w-1/3 md:!w-1/2">
          <:header>{{t "common.progress_tracker.program_progress"}}</:header>
          <:body>
            {{! TODO: using lists to show numbers causes flow issues. The ml-4 class will force
                the numbers into the box, but this isn't ideal as mobile view will have issues.
                Looks like the component is just a stack anyway so might be target for update. }}
            <VerticalProgressTracker class="ml-4" @steps={{this.programProgress}} />
          </:body>
        </Section>
      </HStack>
    </VStack>
  </template>
}
