import { t } from 'ember-intl';
import AppPagePlanDetails from 'tio-employee/components/app/page/plan-details';

const details = [
  {
    title: 'questions_you_have.repayment_plans.eligible_loan_types',
    description:
      'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.eligible_loan_types',
  },
  {
    title: 'questions_you_have.repayment_plans.years_to_repay',
    description:
      'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.years_to_repay',
  },
  {
    title: 'questions_you_have.repayment_plans.loan_forgiveness',
    description:
      'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.loan_forgiveness',
  },
  {
    title: 'questions_you_have.repayment_plans.monthly_payment',
    description:
      'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.monthly_payment',
  },
];

const pros = [
  'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.pros.pro_1',
  'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.pros.pro_2',
  'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.pros.pro_3',
];

const cons = [
  'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.cons.con_1',
  'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.cons.con_2',
  'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.cons.con_3',
  'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.cons.con_4',
];

const moreSections = [
  {
    title: 'questions_you_have.repayment_plans.keep_in_mind',
    description:
      'questions_you_have.repayment_plans.graduated.graduated_plan.plan_details.keep_in_mind',
  },
];

<template>
  <div>
    <div class="p-4 bg-gray-50">
      <h1 class="text-midnight text-xl font-semibold">
        {{t "questions_you_have.repayment_plans.graduated.graduated_plan.title"}}
      </h1>
      <p class="pt-4">
        {{t "questions_you_have.repayment_plans.graduated.graduated_plan.description"}}
      </p>
    </div>

    <AppPagePlanDetails
      @details={{details}}
      @pros={{pros}}
      @cons={{cons}}
      @moreSections={{moreSections}}
    />

    <div class="p-4 mt-6 bg-gray-50">
      <h1 class="text-midnight text-xl font-semibold">
        {{t "questions_you_have.repayment_plans.next_steps"}}
      </h1>
      <div class="pt-6 flex flex-col sm:grid sm:grid-cols-2">
        <div class="pr-3">
          <h1 class="font-semibold text-gray-600">
            {{t "questions_you_have.repayment_plans.make_the_switch.title"}}
          </h1>
          <p class="pt-4">
            {{t "questions_you_have.repayment_plans.make_the_switch.description" htmlSafe=true}}
          </p>
          <h1 class="pt-6 font-semibold text-gray-600">
            {{t "questions_you_have.repayment_plans.need_more_help.title"}}
          </h1>
          <p class="pt-4">
            {{t "questions_you_have.repayment_plans.need_more_help.description" htmlSafe=true}}
          </p>
        </div>
        <div class="pl-3">
        </div>
      </div>
    </div>
  </div>
</template>
