import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { dropTask } from 'ember-concurrency';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import ErrorModal from 'tio-employee/components/auth/reset-password/error-modal';
import errorsForField from 'tio-common/helpers/errors-for-field';
import FormInput from '@frontile/forms-legacy/components/form-input';
import resetPasswordFormSchema from 'tio-common/validation-schema/forms/reset-password-form';
import type { NotificationsService } from 'tio-ui/components/notifications';
import type PreRegisterService from '../../services/pre-register.ts';
import type ResetPasswordController from 'tio-employee/controllers/reset-password';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';

interface ResetPasswordRouteComponentSignature {
  Args: {
    controller: ResetPasswordController;
    model?: { error: string };
  };
}

export default class ResetPasswordRoute extends Component<ResetPasswordRouteComponentSignature> {
  @service declare store: StoreService;
  @service declare router: RouterService;
  @service declare preRegister: PreRegisterService;
  @service declare notifications: NotificationsService;

  @tracked password = '';
  @tracked passwordConfirmation = '';

  @tracked isLoading = false;

  get isTokenInvalid() {
    return !!this.args.model?.error;
  }

  get passwordMinLength() {
    return this.preRegister.settings?.security?.passwordMinLength || 8;
  }

  get formModel() {
    return {
      password: this.password,
      passwordConfirmation: this.passwordConfirmation,
    };
  }

  get formValidationErrors() {
    try {
      resetPasswordFormSchema?.validateSync?.(this.formModel, {
        abortEarly: false,
        context: { passwordMinLength: this.passwordMinLength },
      });
    } catch (err) {
      return err.inner || [];
    }
    return [];
  }

  get isSubmitDisabled() {
    return this.formValidationErrors.length;
  }

  resetPassword = dropTask(async () => {
    try {
      await this.store.adapterFor('login').resetPassword({
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        token: this.args.controller.token,
      });
      this.router.transitionTo('reset-password.confirmation');
    } catch (e) {
      const errorMessage = e?.errors?.length ? e.errors[0] : 'An Error Occurred';
      this.notifications.add(errorMessage, {
        appearance: 'error',
      });
    }
    this.isLoading = false;
  });

  @action
  setPassword(value: string) {
    this.password = value;
  }

  @action
  setPasswordConfirmation(value: string) {
    this.passwordConfirmation = value;
  }

  @action
  submitResetPassword() {
    this.isLoading = true;
    this.resetPassword.perform();
  }

  @action
  onSubmitModal() {
    this.router.transitionTo('login');
  }

  <template>
    <div class="flex flex-col h-screen container mx-auto w-full overflow-auto">
      <EmailLookupWrapper @route="reset-password">
        <h1 class="text-xl my-4 text-center">{{t "login.reset_password_title"}}</h1>
        <p class="mb-4 text-center">{{t "login.reset_password_body"}}</p>
        <form {{on "submit" this.submitResetPassword}}>
          <div class="mb-10">
            <FormInput
              data-legacy-input
              @label={{t "login.new_password"}}
              @hint={{t "login.minimum_characters" min=this.passwordMinLength}}
              @value={{this.password}}
              @errors={{errorsForField "password" schemaErrors=this.formValidationErrors}}
              @onInput={{this.setPassword}}
              @type="password"
            />
          </div>
          <div class="mb-10">
            <FormInput
              data-legacy-input
              @label={{t "login.confirm_new_password"}}
              @hint={{t "login.minimum_characters" min=this.passwordMinLength}}
              @value={{this.passwordConfirmation}}
              @errors={{errorsForField
                "passwordConfirmation"
                schemaErrors=this.formValidationErrors
              }}
              @onInput={{this.setPasswordConfirmation}}
              @type="password"
            />
          </div>
          <div class="mb-6 text-center">
            <Button
              @isRunning={{this.isLoading}}
              {{on "click" this.submitResetPassword}}
              disabled={{this.isSubmitDisabled}}
              @intent="primary"
              class="w-36"
            >
              {{t "submit"}}
            </Button>
          </div>
          <div data-test-login-here class="mb-6 text-md text-center">
            {{t "login.need_to_register"}}
            <a class="underline text-ocean-600" href="/register">
              {{t "login.register_new_account"}}
            </a>
          </div>
        </form>
        <ErrorModal @isOpen={{this.isTokenInvalid}} @onSubmit={{this.onSubmitModal}} />
      </EmailLookupWrapper>
    </div>
  </template>
}
