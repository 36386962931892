import embedAwsQuicksight from 'tio-common/modifiers/embed-aws-quicksight';
import type { TOC } from '@ember/component/template-only';

interface S {
  Args: {
    model: {
      url: string;
    };
  };
}

const tmpl: TOC<S> = <template>
  <div class="w-full">
    <div {{embedAwsQuicksight @model.url}}></div>
  </div>
</template>;

export default tmpl;
