import { t } from 'ember-intl';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import divide from 'ember-math-helpers/helpers/div';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import type TasApplicationModel from 'tio-common/models/tas-application';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import { Section, VStack } from 'tio-ui/components/layout';
import type { TOC } from '@ember/component/template-only';
import { eq, or } from 'tio-ui/utilities';
import type TASCourseModel from 'tio-common/models/tas-course';

export interface S {
  Args: {
    model: {
      tasType: string;
      application: TasApplicationModel;
      evidence: string;
    };
  };
}

export default class AuthenticatedTasConfirmation extends Component<S> {
  @service declare router: RouterService;

  get isTypeTwo() {
    const { tasType } = this.args.model;
    return tasType === 'two';
  }

  get isTypeThree() {
    const { tasType } = this.args.model;
    return tasType === 'three';
  }

  get isTypeFour() {
    const { tasType } = this.args.model;
    return tasType === 'four';
  }

  get isEvidenceSubmission() {
    const { evidence } = this.args.model;
    return evidence ? JSON.parse(evidence) === true : false;
  }

  get applicationName() {
    return getSingleValueForTasField(
      'APPLICATION_NAME',
      this.args.model.application.fields
    ) as string;
  }

  get isDependentProgram() {
    return this.args.model.application.tasProgramInstance.isDependentProgram;
  }

  @action
  goToDashboard() {
    if (this.isDependentProgram) {
      this.router.transitionTo('authenticated.tas.dependent-programs');
    } else {
      this.router.transitionTo('authenticated.tas.dashboard');
    }
  }

  @action
  goToNewApplication() {
    const { id } = this.args.model.application.tasProgramInstance.tasProgramTemplate;
    this.router.transitionTo('authenticated.tas.getting-started', id);
  }

  @action
  goToEvidenceSubmission() {
    this.router.transitionTo('authenticated.tas.applications.show', this.args.model.application.id);
  }

  <template>
    <VStack>
      <Section>
        <:body>
          {{#if this.isTypeTwo}}
            <SubmissionBlock
              @type="two"
              @isEvidenceSubmission={{false}}
              @application={{@model.application}}
              @applicationName={{this.applicationName}}
              @goToDashboard={{this.goToDashboard}}
              @goToEvidenceSubmission={{this.goToNewApplication}}
            />
          {{/if}}

          {{#if this.isTypeThree}}
            <SubmissionBlock
              @type="three"
              @isEvidenceSubmission={{this.isEvidenceSubmission}}
              @application={{@model.application}}
              @applicationName={{this.applicationName}}
              @goToDashboard={{this.goToDashboard}}
              @goToEvidenceSubmission={{this.goToEvidenceSubmission}}
            />
          {{/if}}

          {{#if this.isTypeFour}}
            <SubmissionBlock
              @type="four"
              @isEvidenceSubmission={{this.isEvidenceSubmission}}
              @application={{@model.application}}
              @applicationName={{this.applicationName}}
              @goToDashboard={{this.goToDashboard}}
              @goToEvidenceSubmission={{this.goToEvidenceSubmission}}
            />
          {{/if}}
        </:body>
      </Section>
    </VStack>
  </template>
}

const courseName = (course: TASCourseModel) => {
  return getSingleValueForTasField('COURSE_NAME', course.fields) as string;
};

const courseNumber = (course: TASCourseModel) => {
  return getSingleValueForTasField('COURSE_NUMBER', course.fields) as string;
};

interface SubmissionBlockSigniture {
  Args: {
    isEvidenceSubmission: boolean;
    type: string;
    applicationName: string;
    application: TasApplicationModel;
    goToDashboard: () => void;
    goToEvidenceSubmission: () => void;
  };
}
const SubmissionBlock: TOC<SubmissionBlockSigniture> = <template>
  <div class="flex flex-col w-full mt-8 items-center p-8 md:p-0">
    <div class="flex mb-4">
      {{svgJar "mail-sent" width="212px" role="img" desc=(t "svg.mail_sent")}}
    </div>
    <div>
      <h1 class="text-4xl mb-4 text-center">
        {{#if @isEvidenceSubmission}}
          {{t "tuition_assistance.submitted.evidence_submitted"}}
        {{else}}
          {{t "tuition_assistance.submitted.application_submitted"}}
        {{/if}}
      </h1>
      <h3 class="font-semibold mb-4 text-center md:text-xl md:leading-9">
        {{t "tuition_assistance.submitted.what_happens_now"}}
      </h3>
    </div>

    {{#if @isEvidenceSubmission}}
      {{! Evidence Submission List }}
      <ul class="list-disc pl-4 leading-9 md:text-xl md:leading-9">
        <li>{{t "tuition_assistance.submitted.your_evidence_will_be_reviewed"}}</li>
        <li>{{t "tuition_assistance.submitted.you_can_review_your_application"}}</li>
      </ul>
      <div class="mt-6">
        <Button
          @intent="primary"
          class="flex justify-center w-96 py-3 px-6 mb-2 font-medium text-lg rounded-lg"
          {{on "click" @goToDashboard}}
        >
          {{t "tuition_assistance.submitted.go_to_dashboard"}}
        </Button>

        {{! For type "two" vs others, show different second button text }}
        {{#if (eq @type "two")}}
          <Button
            @appearance="outlined"
            class="flex justify-center w-96 py-3 px-6 mb-2 font-medium text-lg text-gray-800 bg-white rounded-lg hover:bg-gray-300"
            {{on "click" @goToEvidenceSubmission}}
          >
            {{t "tuition_assistance.submitted.submit_another_application"}}
          </Button>
        {{else}}
          <Button
            @appearance="outlined"
            class="flex justify-center w-96 py-3 px-6 mb-2 font-medium text-lg text-gray-800 bg-white rounded-lg hover:bg-gray-300"
            {{on "click" @goToEvidenceSubmission}}
          >
            {{t "tuition_assistance.view_details"}}
          </Button>
        {{/if}}
      </div>
    {{else}}
      {{! Application Submission List }}
      <ul class="list-disc pl-4 leading-9 md:text-xl md:leading-9">
        <li>
          {{t "tuition_assistance.submitted.your_application_name"}}
          <span class="font-semibold">&nbsp;{{@applicationName}}</span>
        </li>

        {{#if @application.activeCourses.length}}
          <li>{{t "tuition_assistance.submitted.courses_in_your_app"}}:</li>
          <ul class="list-inside list-disc">
            {{#each @application.activeCourses as |course|}}
              <li class="font-semibold">
                <span>{{courseName course}} {{courseNumber course}}</span>
              </li>
            {{/each}}
          </ul>
        {{/if}}

        {{#if @application.scholarships.length}}
          <li>{{t "tuition_assistance.submitted.scholarships_in_your_app"}}:</li>
          <ul class="list-inside list-disc">
            {{#each @application.scholarships as |award|}}
              <li class="font-semibold">
                {{award.scholarshipName}}
                -
                {{safeFormatNumber
                  (divide award.scholarshipAmount 100)
                  style="currency"
                  currency="USD"
                }}
              </li>
            {{/each}}
          </ul>
        {{/if}}

        <li>{{t "tuition_assistance.submitted.your_application_will_be_reviewed"}}</li>
        {{#if (or (eq @type "three") (eq @type "four"))}}
          <li>{{t "tuition_assistance.submitted.once_your_application_is_accepted"}}</li>
        {{/if}}
        <li>{{t "tuition_assistance.submitted.you_can_review_your_application"}}</li>
      </ul>

      <div class="mt-6">
        <Button
          class="flex justify-center w-96 py-3 px-6 mb-2 font-medium text-lg text-white bg-ocean-600 rounded-lg hover:bg-ocean-400"
          {{on "click" @goToDashboard}}
        >
          {{t "tuition_assistance.submitted.go_to_dashboard"}}
        </Button>

        {{#if (eq @type "two")}}
          <Button
            @appearance="outlined"
            class="flex justify-center w-96 py-3 px-6 mb-2 font-medium text-lg text-gray-800 bg-white rounded-lg hover:bg-gray-300"
            {{on "click" @goToEvidenceSubmission}}
          >
            {{t "tuition_assistance.submitted.submit_another_application"}}
          </Button>
        {{else}}
          <Button
            @appearance="outlined"
            class="flex justify-center w-96 py-3 px-6 mb-2 font-medium text-lg text-gray-800 bg-white rounded-lg hover:bg-gray-300"
            {{on "click" @goToEvidenceSubmission}}
          >
            {{t "common.progress_tracker.view_application"}}
          </Button>
        {{/if}}
      </div>
    {{/if}}
  </div>
</template>;
