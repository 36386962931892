import type { TOC } from '@ember/component/template-only';
import MaterialIcon from 'tio-common/components/material-icon';

interface AppHeaderInitialsIconSig {
  Args: {
    initials?: string;
  };
  Element: HTMLDivElement;
}

const AppHeaderInitialsIconComponent: TOC<AppHeaderInitialsIconSig> = <template>
  <div
    class="flex items-center justify-center text-sm text-black font-bold uppercase rounded-full h-10 w-10 bg-mint-300 p-1"
    ...attributes
  >
    {{#if @initials}}
      {{@initials}}
    {{else}}
      <MaterialIcon @icon="person" />
    {{/if}}
  </div>
</template>;

export default AppHeaderInitialsIconComponent;
