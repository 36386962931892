import { t } from 'ember-intl';
import TioAlert from 'tio-common/components/tio/alert';
import { Section, VStack } from 'tio-ui/components/layout';

<template>
  <VStack>
    <TioAlert @type="info" @allowDismiss={{true}} class="mb-4">
      <:body>
        <p>{{t "ten_twenty_cancellation.biden_announcement"}}</p>
      </:body>
    </TioAlert>
    <Section>
      <:header>{{t "ten_twenty_cancellation.default"}}</:header>
      <:body>
        <ul class="list-disc pl-4">
          <li class="mb-2">{{t "ten_twenty_cancellation.no_pell_detail"}}</li>
          <li>{{t "ten_twenty_cancellation.with_pell_detail"}}</li>
        </ul>
      </:body>
    </Section>
    <Section>
      <:header>{{t "ten_twenty_cancellation.am_i_eligible"}}</:header>
      <:body>
        <div class="flex flex-col md:flex-row md:grid-cols-2">
          <div class="flex flex-col md:col-span-1 mb-3">
            <div class="font-semibold mb-3">{{t
                "ten_twenty_cancellation.borrower_eligibility"
              }}</div>
            <ul class="list-disc pl-4">
              <li>{{t "ten_twenty_cancellation.who_qualifies"}}</li>
            </ul>
          </div>
          <div class="flex flex-col md:col-span-1">
            <div class="font-semibold mb-3">{{t
                "ten_twenty_cancellation.eligible_loan_types"
              }}</div>
            <ul class="list-disc pl-4">
              <li>{{t "ten_twenty_cancellation.only_fed_loans"}}</li>
              <li>{{t "ten_twenty_cancellation.not_held_by_ed"}}</li>
              <li>{{t "ten_twenty_cancellation.direct_consolidation"}}</li>
            </ul>
          </div>
        </div>
      </:body>
    </Section>
    <Section>
      <:header>{{t "ten_twenty_cancellation.plan_details"}}</:header>
      <:body>
        <div class="flex flex-col md:flex-row md:grid-cols-2">
          <div class="flex flex-col md:col-span-1 mb-3">
            <div class="font-semibold mb-3">{{t "ten_twenty_cancellation.max_forgiveness"}}</div>
            <ul class="list-disc pl-4">
              <li>{{t "ten_twenty_cancellation.no_pell"}} </li>
              <li>{{t "ten_twenty_cancellation.with_pell"}} </li>
            </ul>
          </div>
          <div class="flex flex-col md:col-span-1">
            <div class="font-semibold mb-3">{{t "ten_twenty_cancellation.tax_information"}}</div>
            <ul class="list-disc pl-4">
              <li>{{t "ten_twenty_cancellation.not_fed_maybe_state"}}</li>
            </ul>
          </div>
        </div>
      </:body>
    </Section>
    <Section>
      <:header>{{t "ten_twenty_cancellation.next_steps"}}</:header>
      <:body>
        <ul class="list-disc pl-4">
          <li>
            <div class="flex flex-row">
              {{t "ten_twenty_cancellation.click"}}
              <span class="text-ocean-600 font-semibold">{{t
                  "ten_twenty_cancellation.debt_relief_app_link"
                  htmlSafe=true
                }}</span>
              {{t "ten_twenty_cancellation.to_apply"}}
            </div>
          </li>
          <li>{{t "ten_twenty_cancellation.income_app"}}</li>
          <li>{{t "ten_twenty_cancellation.time_frame"}}</li>
          <li>{{t "ten_twenty_cancellation.cancellation_app"}}</li>
          <li>{{t "ten_twenty_cancellation.cannot_cancel"}}</li>
          <li>
            {{t "ten_twenty_cancellation.dept_ed_guidance"}}
            <span class="text-ocean-600 font-semibold">
              {{t "ten_twenty_cancellation.one_time_cancellation_link" htmlSafe=true}}
            </span>
          </li>
        </ul>
        <div class="mx-5 mt-10 flex justify-center">
          <a
            class="text-ocean-600 font-semibold rounded-sm p-4"
            href="https://studentaid.gov/debt-relief/application"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{t "dashboard_tiles.click_to_apply"}}
          </a>
        </div>
      </:body>
    </Section>
  </VStack>
</template>
