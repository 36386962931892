import { t } from 'ember-intl';
import type { TOC } from '@ember/component/template-only';

const AuthRegisterLoginHereComponent: TOC<{
  Args: {
    hideDescription?: boolean;
  };
}> = <template>
  <div data-test-login-here class="mb-6 text-md text-center">
    {{unless @hideDescription (t "login.already_have_account")}}
    <a class="underline text-ocean-600" href="/login">
      {{t "login.log_in_here"}}
    </a>
  </div>
</template>;

export default AuthRegisterLoginHereComponent;
