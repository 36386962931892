import { Header, Section, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import EmployeesCreateEmployee from 'tio-employee/components/employees/create-employee';
import MaterialIcon from 'tio-common/components/material-icon';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type EmployeeModel from 'tio-common/models/employee';
import type { TOC } from '@ember/component/template-only';

interface S {
  Args: {
    model: EmployeeModel;
  };
}
const tmpl: TOC<S> = <template>
  <VStack>
    <Header>
      {{t "partner.employee.create_employee"}}
    </Header>
    <TioPageBreadcrumbs as |b|>
      <b.crumb
        class="!relative !inline-block"
        @route="authenticated.admin.employees.index"
        @label="Employees"
      />
      <b.crumb
        class="!relative !inline-block"
        @route="authenticated.admin.employees.add"
        @label={{t "partner.employee.create_employee"}}
      />
    </TioPageBreadcrumbs>
    <div>
      <span class="text-sm text-gray-400">
        <MaterialIcon @icon="info" class="text-sm top-5" />
        {{t "partner.employee.alert_info" htmlSafe=true}}
      </span>
    </div>
    <Section>
      <EmployeesCreateEmployee @model={{@model}} />
    </Section>
  </VStack>
</template>;

export default tmpl;
