import MaterialIcon from 'tio-common/components/material-icon';
import type { TOC } from '@ember/component/template-only';

interface PslfDashboardTodoIndexSignature {
  Args: {
    isComplete: unknown;
    title: string;
  };
  Blocks: {
    actions: [];
    body: [];
    footer: [];
    leftHeader: [];
  };
  Element: HTMLLIElement;
}

const PslfDashboardTodoIndexComponent: TOC<PslfDashboardTodoIndexSignature> = <template>
  <div class="flex items-center leading-none">
    <div>
      {{yield to="leftHeader"}}
    </div>
    <div class="grow text-left font-semibold text-lg">{{@title}}</div>
    <MaterialIcon
      class={{if @isComplete "text-mint-300" "text-error-400"}}
      @icon={{if @isComplete "check_circle" "warning"}}
    />
  </div>
  <div class="pt-2 pb-3 mx-2 text-center flex justify-center">
    {{yield to="body"}}
  </div>
  <div class="mx-auto grid grid-cols-1 gap-2 max-w-72">
    {{yield to="actions"}}
  </div>
  <div class="mx-auto grid grid-cols-1 gap-2">
    {{yield to="footer"}}
  </div>
</template>;

export default PslfDashboardTodoIndexComponent;
