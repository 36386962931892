import { t } from 'ember-intl';
import TuitionAssistanceFormsApprovalCoursesCompletionReview from 'tio-employee/components/tuition-assistance/forms/approval/courses-completion-review';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type { TOC } from '@ember/component/template-only';

interface S {
  Args: {
    model: TasApplicationModel;
  };
}
const tmpl: TOC<S> = <template>
  <h3 class="font-semibold my-2 text-midnight">
    {{t "tuition_assistance.preapproval_app.application_review"}}
  </h3>

  <TuitionAssistanceFormsApprovalCoursesCompletionReview @model={{@model}} />
</template>;

export default tmpl;
