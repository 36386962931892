import Service from '@ember/service';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked, cached } from '@glimmer/tracking';
import { datadogRum } from '@datadog/browser-rum';
import ENV from 'tio-employee/config/environment';
import type SessionService from 'tio-employee/services/session';
import type ZendeskService from 'tio-employee/services/zendesk';
import type RootLoggerService from 'tio-employee/services/root-logger';
import type StoreService from 'tio-common/services/store';
import UserModel from 'tio-common/models/user';
import type RoleViewModel from 'tio-common/models/role-view';
import type RouterService from 'tio-employee/services/router';
import type PreRegisterService from 'tio-employee/services/pre-register';
import type EmployeeModel from 'tio-common/models/employee';
import type AgreementModel from 'tio-common/models/agreement';
import type VueIntegrationService from './vue-integration';
import { TrackedArray } from 'tracked-built-ins';

const meIncludes = [
  'person.employees.company',
  'role-views.company.company-setting',
  'role-views.company.logo',
  'logins',
  'usage-agreement',
  'privacy-agreement',
  'custom-register-agreements.condition',
  'email-addresses',
  'employees.person',
  'employees.jurisdictive-relationships',
  'employees.company',
  'employees.scholarships',
  'employees.plan',
  'employees.match-participant.match-plan',
  'employees.tas-participant',
];

/**
 * Contains in-memory data and actions relevant to a user's session (as opposed
 * to the persisted data kept in the `session` service).
 *
 * @memberof services
 */
class SessionContextService extends Service {
  @service declare rootLogger: RootLoggerService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare store: StoreService;
  @service declare zendesk: ZendeskService;
  @service declare preRegister: PreRegisterService;
  @service declare vueIntegration: VueIntegrationService;

  // @ts-expect-error: We shouldn't be using `this` outside of the constructor
  logger = this.rootLogger.get('service:session-context');

  /**
   * Internal property to make `currentRole` reactive; may not always represent
   * the actual ID of the current role (may be undefined)
   */
  get currentRoleId() {
    return localStorage.getItem('role') ?? undefined;
  }

  /**
   * The user object of the currently logged-in user, or an empty user object if
   * the user has not yet been loaded into the session context.
   */
  @tracked user!: UserModel;

  /**
   * An array of role objects of the currently logged-in user.
   *
   * NOTE: This is not actually the roles of the current user, but rather a
   *       filtered view. Research why this is the case. [twl 2.Aug.23]
   */
  roles: RoleViewModel[] = new TrackedArray([]);

  @tracked actualCurrentRelationshipType?: string;

  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);
    this.user = new UserModel();
    this.actualCurrentRelationshipType = this.currentRoleId;
  }

  /**
   * Whether the authenticated user and relationships have been loaded into the session context.
   */
  get isLoaded() {
    try {
      return !!this.user.id;
    } catch {
      return false;
    }
  }

  /**
   * Whether the user has been authenticated and has a current role.
   */
  get isFullyAuthenticated() {
    return !!(this.session.isAuthenticated && this.currentRole);
  }

  /**
   * The current role for the user, or `undefined` if none exists or if multiple
   * exist but no current role has been selected yet.
   */
  get currentRole() {
    // If there are multiple roles, the current role must be set explicitly
    return this.roles.length === 1
      ? this.roles[0]
      : this.roles.find((role) => role.relationshipType === this.currentRoleId);
  }

  /**
   * The current employee for the user, or an empty employee object if none
   * exists.
   */
  @cached
  get currentEmployee(): EmployeeModel {
    if (!this.user.employees?.length) {
      return this.store.createRecord('employee');
    }
    return (
      this.user.employees.find(
        (employee: EmployeeModel) => employee.company.id === this.currentRole?.company.id
      ) || this.store.createRecord('employee')
    );
  }

  @cached
  get person() {
    return this.user.person || this.store.createRecord('person');
  }

  /**
   * Whether the user is viewing the app as an employee.
   */
  get isEmployeeView() {
    return this.currentRole?.relationshipType === 'PARTICIPANT' || this.isFamilyMember;
  }

  /**
   * Whether the user is viewing the app as a partner admin.
   */
  get isAdminView() {
    if (this.isTasRole) {
      return true;
    }

    if (!this.currentRole?.relationshipType) {
      return false;
    }

    return [
      'ACCOUNT_OWNER',
      'EMPLOYEE_ADMIN',
      'PSLF_ADMIN',
      'TIO_ACCOUNT_MANAGER',
      'TIO_ADMIN',
    ].includes(this.currentRole?.relationshipType);
  }

  get isTasRole() {
    if (!this.currentRole?.relationshipType) {
      return false;
    }

    return (
      this.currentRole.relationshipType.startsWith('TAS.Approver') ||
      this.currentRole.relationshipType === 'TAS.Admin.SUPERUSER'
    );
  }

  get isTasTioApprover() {
    if (!this.currentRole?.relationshipType) {
      return false;
    }

    return this.currentRole.relationshipType === 'TAS.Approver.TIO_APPROVER';
  }

  get canEditTasApplication() {
    if (!this.currentRole?.relationshipType) {
      return false;
    }
    const approvedRoles = [
      'TAS.Admin.PROGRAM_ADMIN',
      'TAS.Admin.SUPERUSER',
      'TAS.Approver.ADMIN_APPROVER',
      'TAS.Approver.APPROVER',
      'TAS.Approver.CUSTOM_APPROVER',
      'TAS.Approver.FINANCE_APPROVER',
      'TAS.Approver.HYPERVISOR',
      'TAS.Approver.PPP_FINANCE_APPROVER',
      'TAS.Approver.TAP_FINANCE_APPROVER',
      'TAS.Approver.TIO_APPROVER',
      'TIO_ACCOUNT_MANAGER',
      'TIO_ADMIN',
      'EMPLOYEE_ADMIN',
      'ACCOUNT_OWNER',
    ];
    return approvedRoles.includes(this.currentRole.relationshipType);
  }

  get isPslfAdmin() {
    return this.currentRole?.relationshipType === 'PSLF_ADMIN';
  }

  get isPslfGroupApprover() {
    const relationships = this.currentEmployee.jurisdictiveRelationships;
    return relationships.some((jr) => jr.relationshipType === 'PSLF_GROUP_APPROVER');
  }

  get isFamilyMember() {
    return this.currentRole?.role === 'FAMILY_MEMBER';
  }

  get isEmailProviderLogin() {
    const userLogin = this.user.logins?.[0];
    return userLogin?.provider === 'EMAIL';
  }

  get isOtherProviderLogin() {
    if (!this.user.id) {
      return false;
    }
    return !this.isEmailProviderLogin;
  }

  get hasAcceptedAppTerms() {
    return !!(
      this.user.usageAgreement &&
      this.user.privacyAgreement &&
      this.hasAcceptedAllCustomRegisterTerms
    );
  }

  get needsToAcceptAppTerms() {
    return this.user && !this.hasAcceptedAppTerms;
  }

  @cached
  get hasAcceptedAllCustomRegisterTerms() {
    const customRegisterAgreementTermsIds = new Set(
      (this.user.customRegisterAgreements || []).map((a: AgreementModel) => a.condition?.id)
    );
    const customRegisterTermIds = (this.preRegister.customTerms || []).map((t) => t.id);

    if (customRegisterAgreementTermsIds.size < customRegisterTermIds.length) {
      return false;
    }
    return customRegisterTermIds.every((termId) => {
      return customRegisterAgreementTermsIds.has(termId);
    });
  }

  /**
   * Loads the user data for the current authenticated user.
   */
  @action
  async load() {
    const data = await fetch(ENV.apiHost + '/me?include=' + meIncludes.join(','), {
      headers: {
        'x-api-key': ENV.apiKey,
        'tio-auth-token': this.session.data.authenticated.access_token,
        Accept: 'application/vnd.api+json',
      },
    });

    if ([401, 403, 404].includes(data.status)) {
      this.logout();

      throw new Error(`Unauthorized status loading user data: ${data.status}}`);
    }

    const parsed = await data.json();

    this.store.pushPayload(parsed);
    this.user = this.store.peekRecord('user', parsed.data.id);
    this.roles = new TrackedArray([...this.user.uniqueRolesByRelationshipType]);
  }

  /**
   * Returns the employee IDs fo the current user.
   *
   * HACK: We are struggling with hard-to-reproduce timing bugs that are causing
   *       `sessionContext.user.person.employees` to evaluate as empty
   *       unexpectedly.
   */
  @action
  async getEmployeeIdsAsync() {
    let employees = this.user?.person?.employees || [];

    if (!employees.length) {
      const userWithEmployees = await this.store.findRecord('user', this.user.id, {
        include: 'person.employees',
        reload: true,
      });
      employees = userWithEmployees.person?.employees || [];
    }
    return employees.map((e) => e.id);
  }

  /**
   * Sets the current role for the authenticated user.
   *
   * @param {RoleView} role - A user role
   */
  @action
  setCurrentRole(role: RoleViewModel) {
    this.actualCurrentRelationshipType = role.relationshipType;
    localStorage.setItem('role', role.relationshipType);
    if (this.isEmployeeView) {
      this.router.transitionTo('authenticated.dashboard');
    } else {
      this.router.transitionTo('authenticated.admin');
    }

    try {
      datadogRum.setUserProperty('role', this.currentRole?.role);
      datadogRum.setUserProperty('relationshipType', this.currentRole?.relationshipType);
    } catch (e) {
      console.error('Error updating DataDog user current role', e);
    }
  }

  /**
   * Destroys the authenticated session and logs out the current user.
   */
  @action
  async logout() {
    this.vueIntegration.logout();

    try {
      this.zendesk.clearUser();
    } catch (e) {
      this.logger.error('Could not clear zendesk user', e);
    }

    try {
      datadogRum.clearUser();
    } catch (e) {
      this.logger.error('Could not clear datadog user', e);
    }

    // This must be last, since this causes a redirect once it is executed
    try {
      this.session.invalidate();
    } catch (e) {
      this.logger.error('Could not clear session context', e);
    }

    localStorage.removeItem('role');

    try {
      this.roles = [];
      this.user = new UserModel();
    } catch (e) {
      this.logger.error('Could not clear user and roles', e);
    }
  }
}

export default SessionContextService;
