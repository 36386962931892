import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import type { TOC } from '@ember/component/template-only';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type RecommendationModel from 'tio-common/models/recommendation';
import ForgivenessEstimatorResultInstructions from 'tio-employee/components/forgiveness-estimator/result-instructions';
import { Button } from 'tio-ui/components/buttons';

interface S {
  Args: {
    model: RecommendationModel;
  };
}

const tmpl: TOC<S> = <template>
  {{pageTitle (t "recommendations_estimator.next_steps")}}
  <TioPageBreadcrumbs class="mb-4" as |b|>
    <b.crumb
      @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.index"
      @label="Dashboard"
    />
    <b.crumb
      @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.index"
      @label="Strategy Finder"
    />
    <b.crumb
      @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.results.index"
      @model={{@model}}
      @label="Results"
    />
    <b.crumb
      @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.results.next-steps"
      @label="Next Steps"
    />
  </TioPageBreadcrumbs>
  <ForgivenessEstimatorResultInstructions @recommendation={{@model}} />
  <div class="flex justify-center">
    <Button
      @intent="primary"
      {{on
        "click"
        (transitionTo "authenticated.repaying-student-debt.repayment-strategy-finder.dashboard")
      }}
      class="w-fit m-4"
    >
      {{t "recommendations_estimator.go_to_dashboard"}}
    </Button>
  </div>
</template>;

export default tmpl;
