import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';
import emailSchema from 'tio-common/validation-schema/models/email';
import FormInput from '@frontile/forms-legacy/components/form-input';
import type ForgotPasswordController from 'tio-employee/controllers/forgot-password';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';

interface ForgotPasswordRouteComponentSignature {
  Args: {
    controller: ForgotPasswordController;
  };
}

export default class ForgotPasswordRoute extends Component<ForgotPasswordRouteComponentSignature> {
  @service declare store: StoreService;
  @service declare router: RouterService;

  @tracked email = '';
  @tracked isLoading = false;

  constructor(owner: Owner, args: ForgotPasswordRouteComponentSignature['Args']) {
    super(owner, args);
    this.email = this.emailFromQueryParams;
  }

  get validationErrors() {
    try {
      // @ts-expect-error: same as email-field.gts
      emailSchema.fields.email?.validateSync?.(this.email);
    } catch (err) {
      return err.errors || [];
    }
    return [];
  }

  get emailFromQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    return decodeURIComponent(urlParams.get('email') || '');
  }

  sendPasswordEmail = dropTask(async () => {
    try {
      await this.store.adapterFor('login').sendForgotPassword(this.email);
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
      this.router.transitionTo('forgot-password.confirmation');
    }
  });

  get isDisabled() {
    return !this.email || !!this.validationErrors.length;
  }

  @action
  async submitForgotPassword() {
    this.isLoading = true;
    if (this.validationErrors.length) return;
    this.sendPasswordEmail.perform();
  }

  @action
  backToLogin() {
    this.router.transitionTo('login');
  }

  <template>
    <div class="flex flex-col h-screen container mx-auto w-full overflow-auto">
      <EmailLookupWrapper @route="forgot-password">
        <h1 class="text-xl my-4 text-center">{{t "login.forgot_password_question"}}</h1>
        <p class="mb-4">{{t "login.forgot_password_body"}}</p>
        <form {{on "submit" this.submitForgotPassword}}>
          <div class="mb-10">
            <FormInput
              @label={{t "login.email"}}
              @value={{this.email}}
              @onInput={{fn (mut this.email)}}
              @errors={{this.validationErrors}}
              @type="email"
            />
          </div>
          <div class="flex justify-center items-center space-x-4 mb-10">
            <Button @appearance="outlined" {{on "click" this.backToLogin}}>
              {{t "login.back_to_login"}}
            </Button>
            <Button
              @intent="primary"
              @isRunning={{this.isLoading}}
              {{on "click" this.submitForgotPassword}}
              disabled={{this.isDisabled}}
            >
              {{t "submit"}}
            </Button>
          </div>
        </form>
      </EmailLookupWrapper>
    </div>
  </template>
}
