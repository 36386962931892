import { t } from 'ember-intl';
import TuitionAssistanceFormsCoursesCourseReview from 'tio-employee/components/tuition-assistance/forms/courses/course-review';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type { TOC } from '@ember/component/template-only';

interface S {
  Args: {
    model: TasApplicationModel;
  };
}

const tmpl: TOC<S> = <template>
  <h3 class="font-semibold text-midnight text-lg md:text-xl">
    {{t "tuition_assistance.program_details.courses.application_review"}}
  </h3>
  <TuitionAssistanceFormsCoursesCourseReview @application={{@model}} />
</template>;

export default tmpl;
