import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import Check from 'ember-static-heroicons/components/outline-24/check';
import { Header, HStack, Section, VStack } from 'tio-ui/components/layout';

<template>
  <TioPageBreadcrumbs class="mb-4" as |b|>
    <b.crumb @route="authenticated.syf.dashboard" @label="Dashboard" />
    <b.crumb @route="authenticated.syf.insights.idr" @label="IDR" />
  </TioPageBreadcrumbs>
  <Header>{{t "syf.insights.idr.heading"}}</Header>
  <Section>
    <:body>
      <HStack>
        <VStack class="w-full md:w-1/2">
          <h2 class="text-lg font-semibold">{{t "syf.insights.idr.explore.heading"}}</h2>
          <p>{{t "syf.insights.idr.explore.one"}}</p>
          <p>{{t "syf.insights.idr.explore.two"}}</p>
        </VStack>
        <VStack class="w-full md:w-1/2">
          <h2 class="text-lg font-semibold">{{t "syf.insights.idr.advantage.heading"}}</h2>
          <div class="flex gap-2 items-start">
            <Check class="w-6 h-6 text-green-700" />
            <p>{{t "syf.insights.idr.advantage.one"}}</p>
          </div>
          <div class="flex gap-2 items-start">
            <Check class="w-6 h-6 text-green-700" />
            <p>{{t "syf.insights.idr.advantage.two"}}</p>
          </div>
          <div class="flex gap-2 items-start">
            <Check class="w-6 h-6 text-green-700" />
            <p>{{t "syf.insights.idr.advantage.three"}}</p>
          </div>
          <hr />
          <div>{{t "syf.insights.idr.advantage.label"}}</div>
          <Button
            @intent="primary"
            @appearance="outlined"
            {{on "click" (transitionTo "authenticated.idr")}}
          >
            {{t "syf.insights.idr.advantage.button"}}
          </Button>
        </VStack>
      </HStack>
    </:body>
  </Section>
</template>
