import Route from '@ember/routing/route';
import { service } from '@ember/service';
import queryString from 'query-string';
import ENV from 'tio-employee/config/environment';
import type SessionService from 'tio-employee/services/session';
import type SessionContextService from 'tio-employee/services/session-context';
import type RouterService from '@ember/routing/router-service';

export default class AuthByTokenRoute extends Route {
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;
  @service declare router: RouterService;

  async beforeModel() {
    const tokens = queryString.parse(location.hash);

    let token = tokens.tio as string;

    const headers = {
      'x-api-key': ENV.apiKey,
      'tio-temp-token': token,
      'Content-Type': 'application/json',
    };
    try {
      const response = await fetch(`${ENV.apiHost}/saml/token_exchange`, { headers });
      const { access_token } = await response.json();
      token = access_token;
    } catch (e) {
      console.error('Failed to exchange token:', e);
    }
    await this.authenticateSession(token);
    this.router.transitionTo(this.session.data.authenticated.routeAfterAuthentication || '/');
  }

  async authenticateSession(token: string) {
    await this.session.authenticate('authenticator:saml', {
      access_token: token,
      noTransitionAfterAuth: true,
    });

    // Required to have the roles loaded before syncing the roles below
    await this.sessionContext.load();
  }
}
