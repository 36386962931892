import { LinkTo } from '@ember/routing';
import MaterialIcon from 'tio-common/components/material-icon';
import { t } from 'ember-intl';
import Tile from '../tile';

<template>
  <LinkTo @route="authenticated.observability.upload">
    <Tile @headerText={{t "dashboard_tiles.add_student_loans"}} @isUrgent={{true}}>
      <:body>
        <div class="flex flex-col justify-center items-center grow">
          <div class="flex flex-row items-center mb-4">
            <MaterialIcon @icon="warning" class="m-3 text-red-700" />
            <span class="ml-2">{{t "dashboard_tiles.link_accounts"}}</span>
          </div>
          <div class="flex flex-row items-center mb-4">
            <div
              class="focus-visible:ring focus-visible:ring-offset-2 text-center font-semibold uppercase flex items-center justify-center border relative overflow-hidden px-3 py-2 border-ocean-600 hover:border-ocean-800 bg-ocean-600 hover:bg-ocean-800 text-white tio-anchor-button min-w-56"
            >
              <MaterialIcon @icon="add" class="mr-2" />
              {{t "dashboard_tiles.add_loans"}}
            </div>
          </div>
        </div>
      </:body>
    </Tile>
  </LinkTo>
</template>
