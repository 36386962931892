import type { TOC } from '@ember/component/template-only';

interface AppPageHeroSectionSignature {
  Blocks: {
    body: [];
    title: [];
  };
  Element: HTMLDivElement;
}

const AppPageHeroSectionComponent: TOC<AppPageHeroSectionSignature> = <template>
  <div class="bg-midnight text-white flex flex-col sm:flex-row gap-0 rounded-t-lg" ...attributes>
    <div class="p-10 text-2xl tracking-wide font-semibold text-center sm:text-left sm:w-1/3">
      {{yield to="title"}}
    </div>
    <div class="p-10 font-light text-center sm:text-left sm:w-2/3">
      {{yield to="body"}}
    </div>
  </div>
</template>;

export default AppPageHeroSectionComponent;
