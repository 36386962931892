import { LinkTo } from '@ember/routing';
import Tile from '../tile';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';

<template>
  <LinkTo @route="authenticated.repaying-student-debt.repayment-strategy-finder">
    <Tile @headerText={{t "common.strategy_finder.default"}}>
      <:description>
        {{t "dashboard_tiles.find_best_strategy"}}
      </:description>
      <:image>
        {{svgJar
          "marketing-strategy"
          width="100%"
          height="100%"
          role="img"
          desc=(t "svg.marketing_strategy")
        }}
      </:image>
    </Tile>
  </LinkTo>
</template>
