import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { datadogRum } from '@datadog/browser-rum';
import type SessionContextService from 'tio-employee/services/session-context';
import type RootLoggerService from 'tio-employee/services/root-logger';
import type ZendeskService from 'tio-employee/services/zendesk';
import type RouterService from '@ember/routing/router-service';
import type PartnerService from 'tio-employee/services/partner';
import type SessionService from 'tio-employee/services/session';
import type FeaturesService from 'tio-common/services/features';
import type Store from '@ember-data/store';
import type Transition from '@ember/routing/transition';
import type VueIntegrationService from 'tio-employee/services/vue-integration';
import ENV from 'tio-employee/config/environment';

function setupForethought(
  firstName: string,
  lastName: string,
  fullName: string,
  email = '',
  partnerName = ''
) {
  document.querySelector('#forethought-chat')?.remove();
  const script = document.createElement('script');
  script.setAttribute('src', 'https://solve-widget.forethought.ai/embed.js');
  script.setAttribute('id', 'forethought-widget-embed-script');
  script.setAttribute('data-api-key', '21bf8592-1b59-4758-84ef-f82c33c6462e');

  script.setAttribute('data-ft-First-Name', firstName);
  script.setAttribute('data-ft-Last-Name', lastName);
  script.setAttribute('data-ft-Full-Name', fullName);
  script.setAttribute('data-ft-Email', email);
  script.setAttribute('data-ft-Partner-Name', partnerName);

  document.head.append(script);
}

export default class AuthenticatedRoute extends Route {
  @service declare rootLogger: RootLoggerService;
  @service declare session: SessionService;
  @service declare sessionContext: SessionContextService;
  @service declare features: FeaturesService;
  @service declare zendesk: ZendeskService;
  @service declare router: RouterService;
  @service declare partner: PartnerService;
  @service declare store: typeof Store;
  @service declare vueIntegration: VueIntegrationService;

  // @ts-expect-error: this should be handled constructor
  logger = this.rootLogger.get('route:authenticated');

  async beforeModel(transition: Transition) {
    const loggedIn = this.session.requireAuthentication(transition, 'login');

    if (!loggedIn) {
      return;
    }

    if (!this.sessionContext.isLoaded) {
      try {
        await this.sessionContext.load();
      } catch (e) {
        this.logger.error('Could not load session context', e);
      }
    }

    if (!this.sessionContext.currentRole) {
      return this.router.transitionTo('/role-select');
    }

    try {
      await this.partner.loadTuitionAssistanceData();
    } catch (e) {
      this.logger.error('Could not load Tuition assistance data', e);
    }

    try {
      if (
        ENV.environment === 'production' &&
        this.sessionContext.currentEmployee.company.id != '19'
      ) {
        return;
      }

      setupForethought(
        this.sessionContext.user.person?.firstName,
        this.sessionContext.user.person?.lastName,
        this.sessionContext.user.person?.fullName,
        this.sessionContext.user.employees?.[0]?.email,
        this.sessionContext.user.employees?.[0]?.company.name
      );
    } catch (e) {
      // This is a non-blocking error. If Forethought fails to load, it shouldn't prevent the rest of the app from loading.
      this.logger.error('Could not setup Forethought chat', e);
    }

    try {
      this.zendesk.setWidgetLocale();
    } catch (e) {
      this.logger.error('Could not set zendesk user', e);
    }

    try {
      this.setupDatadogIdentity();
    } catch (e) {
      console.error('Error setting up DataDog user', e);
    }

    try {
      this.setupDatadogPrivateContext();
    } catch (e) {
      console.error('Error setting up DataDog private context', e);
    }

    // Checks to see if a route is saved in the session and transition to it
    this.session.transitionToRouteAfterAuthentication();

    // @ts-expect-error: seems like a typing issue or I am using the wrong type
    if (transition.intent?.url === '/') {
      if (this.sessionContext.currentRole.relationshipType === 'PARTICIPANT') {
        this.router.transitionTo('authenticated.dashboard');
      } else {
        this.router.transitionTo('authenticated.admin');
      }
    }

    this.vueIntegration.loginWithToken(this.session.data.authenticated.access_token);
  }

  model() {
    return this.store.findRecord('user', this.sessionContext.user.id);
  }

  setupDatadogIdentity() {
    const { user } = this.sessionContext;
    const currentRole = this.sessionContext.currentRole;

    datadogRum.setUser({
      id: user.id,
      name: user.person?.fullName,
      role: currentRole?.role,
      relationshipType: currentRole?.relationshipType,
    });
  }

  setupDatadogPrivateContext() {
    const { user } = this.sessionContext;
    const currentRole = this.sessionContext.currentRole;

    if (user?.id) {
      datadogRum.setGlobalContextProperty('tio.user.id', user.id);
    }
    if (user?.person?.fullName) {
      datadogRum.setGlobalContextProperty('tio.user.name', user.person.fullName);
    }
    if (currentRole?.company?.id) {
      datadogRum.setGlobalContextProperty('tio.partner.id', currentRole.company.id);
    }
    if (currentRole?.company?.name) {
      datadogRum.setGlobalContextProperty('tio.partner.name', currentRole.company.name);
    }
  }
}
