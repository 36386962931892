import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';

<template>
  <LinkTo @route="authenticated.questions-you-have.glossary">
    <Tile @headerText={{t "dashboard_tiles.glossary"}}>
      <:description>
        <p class="font-semibold pb-3 text-xl sm:text-lg xl:text-xl">
          {{t "dashboard_tiles.commonly_used_terms"}}
        </p>
        <span>{{t "dashboard_tiles.glossary_blurb"}}</span>
      </:description>
      <:image>
        {{svgJar
          "student-loan-glossary"
          width="100%"
          height="100%"
          role="img"
          desc=(t "svg.student_loan_glossary")
        }}
      </:image>
    </Tile>
  </LinkTo>
</template>
