import { t } from 'ember-intl';
import QuestionsGlossary from 'tio-employee/components/questions/glossary';
import { Header, Section, VStack } from 'tio-ui/components/layout';

<template>
  <VStack>
    <Header>{{t "questions_you_have.glossary.glossary"}}</Header>
    <Section>
      <:header>{{t "questions_you_have.glossary.glossary_subtitle"}}</:header>
      <:body>
        <VStack>
          <QuestionsGlossary />
        </VStack>
      </:body>
    </Section>
  </VStack>
</template>
