import { t } from 'ember-intl';
import EmailLookupWrapper from 'tio-employee/components/auth/email-lookup-wrapper';

<template>
  <EmailLookupWrapper @route="register/confirmation">
    <div class="flex flex-col p-6">
      <h1 class="text-lg mb-6">{{t "register.email_sent_modal_header"}}</h1>
      <p>{{t "register.email_sent_modal_body"}}</p>
    </div>
  </EmailLookupWrapper>
</template>
