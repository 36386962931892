import ApplicationAdapter from './application';

class TasEligibilityAdapter extends ApplicationAdapter {
  async updateTasEligibilityState(
    tasEligibilityId: string,
    eligibilityState: 'activate' | 'deactivate'
  ): Promise<void> {
    const url =
      this.buildURL('tas-eligibility', tasEligibilityId) + '/transition/state/' + eligibilityState;
    return await this.ajax(url, 'PATCH');
  }
}

export default TasEligibilityAdapter;
