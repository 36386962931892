import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import ForgivenessEstimatorResultInstructions from 'tio-employee/components/forgiveness-estimator/result-instructions';
import pageTitle from 'ember-page-title/helpers/page-title';
import type { TOC } from '@ember/component/template-only';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type RecomendationModel from 'tio-common/models/recommendation';

interface S {
  Args: {
    model: RecomendationModel;
  };
}

const tmpl: TOC<S> = <template>
  {{pageTitle (t "recommendations_estimator.next_steps")}}
  <TioPageBreadcrumbs class="mb-4" as |b|>
    <b.crumb @route="authenticated.idr.dashboard.index" @label="Dashboard" />
    <b.crumb
      @route="authenticated.idr.dashboard.forgiveness-estimator.index"
      @label="Forgiveness Estimator"
    />
    <b.crumb
      @route="authenticated.idr.dashboard.forgiveness-estimator.results.index"
      @model={{@model}}
      @label="Results"
    />
    <b.crumb
      @route="authenticated.idr.dashboard.forgiveness-estimator.results.next-steps"
      @label="Next Steps"
    />
  </TioPageBreadcrumbs>
  <ForgivenessEstimatorResultInstructions @recommendation={{@model}} />
  <div class="flex justify-center">
    <Button
      {{on "click" (transitionTo "authenticated.idr.dashboard")}}
      @intent="primary"
      @appearance="outlined"
    >
      {{t "recommendations_estimator.go_to_dashboard"}}
    </Button>
  </div>
</template>;

export default tmpl;
