import { action } from '@ember/object';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import LoanDetail from './linked-loans-summary/loan-detail';
import screenIs from 'tio-common/helpers/screen-is';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type PersonModel from 'tio-common/models/person';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
import { Section, VStack } from 'tio-ui/components/layout';
import Table from 'tio-common/components/table/index';

export interface AccountsLinkedLoansSummarySignature {
  Args: {
    accounts: AccountModel[];
    allowOverrideEdits: boolean;
    person: PersonModel;
    loanSummary: LoanSummary;
    didCancel?: () => void;
  };
}

export default class AccountsLinkedLoansSummary extends Component<AccountsLinkedLoansSummarySignature> {
  @service declare sessionContext: SessionContextService;
  @service declare store: typeof Store;

  @tracked customLoanType = '';
  @tracked customInterestRate = null;

  get allowOverrideEdits() {
    return this.args.allowOverrideEdits || false;
  }

  get loanErrors() {
    // TODO: The loan summary needs a complete overhaul. This isn't ideal but will work for now.
    return [];
    // return this.args.loanSummary.errors || [];
  }

  @action
  cancel() {
    this.args.didCancel?.();
  }

  @action
  filterBy<T, K extends keyof T>(property: K, value: T[K], array: T[]): T[] {
    return array.filter((item) => item[property] === value);
  }

  <template>
    <VStack>
      {{#if (screenIs "md")}}
        {{#each @accounts as |account|}}
          <Section class="overflow-auto">
            <:header>{{account.institutionName}}</:header>
            <:body>
              <Table>
                <:thead as |options|>
                  <options.tr>
                    <options.th>
                      {{t "pslf_dashboard.actions.dialog.loan_name"}}
                    </options.th>
                    <options.th>
                      {{t "pslf_dashboard.actions.dialog.repayment_plan"}}
                    </options.th>
                    <options.th>
                      {{t "pslf_dashboard.actions.dialog.loan_type"}}
                    </options.th>
                    <options.th>
                      {{t "pslf_dashboard.actions.dialog.interest_rate"}}
                    </options.th>
                    <options.th class="text-right">
                      {{t "pslf_dashboard.actions.dialog.current_balance"}}
                    </options.th>
                    <options.th />
                  </options.tr>
                </:thead>
                <:tbody as |options|>
                  {{#each account.activeLoans as |loan|}}
                    <LoanDetail
                      @style="row"
                      @loan={{loan}}
                      {{! @glint-expect-error: return to this }}
                      @loanErrors={{this.filterBy "loanId" loan.id this.loanErrors}}
                      @allowOverrideEdits={{@allowOverrideEdits}}
                      @options={{options}}
                    />
                  {{/each}}
                </:tbody>
              </Table>
            </:body>
          </Section>
        {{/each}}
      {{else}}
        {{#each @accounts as |account|}}
          <Section>
            <:header>{{account.institutionName}}</:header>
            <:body>
              <ul>
                {{#each account.activeLoans as |loan|}}
                  <LoanDetail
                    @style="list"
                    @loan={{loan}}
                    {{! @glint-expect-error: return to this }}
                    @loanErrors={{this.filterBy "loanId" loan.id this.loanErrors}}
                    @allowOverrideEdits={{@allowOverrideEdits}}
                  />
                {{/each}}
              </ul>
            </:body>
          </Section>
        {{/each}}
      {{/if}}
    </VStack>
  </template>
}
