import { t } from 'ember-intl';
import { Header, VStack } from 'tio-ui/components/layout';
import type { TOC } from '@ember/component/template-only';

interface StrategyFinderDashboardRouteSignature {
  Blocks: {
    default: [];
  };
}

const tmpl: TOC<StrategyFinderDashboardRouteSignature> = <template>
  <VStack>
    <Header>{{t "common.strategy_finder.default"}}</Header>
    {{outlet}}
  </VStack>
</template>;

export default tmpl;
