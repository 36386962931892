import { gt } from 'tio-ui/utilities';
import { t } from 'ember-intl';
import { Section, VStack } from 'tio-ui/components/layout';
import Check from 'ember-static-heroicons/components/outline-24/check';
import Component from '@glimmer/component';
import formatCentsToDollars from 'tio-common/helpers/format-cents-to-dollars';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type { SyfInsightsFourOhOneKRouteModel } from 'tio-employee/routes/authenticated/syf/insights/four-oh-one-k';
import type { TOC } from '@ember/component/template-only';

interface SyfInsightsFourOhOneKRouteSignature {
  Args: {
    model: SyfInsightsFourOhOneKRouteModel;
  };
}

interface NumberCircleSignature {
  Args: {
    number: string;
  };
  Element: HTMLDivElement;
}

const NumberCircle: TOC<NumberCircleSignature> = <template>
  <div
    class="shrink-0 bg-white border-2 border-violet-800 text-violet-800 rounded-full leading-10 h-10 w-10 inline-flex justify-center items-center"
  >
    <p class="text-xl font-semibold">
      {{@number}}
    </p>
  </div>
</template>;

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class SyfInsightsFourOhOneKRouteComponent extends Component<SyfInsightsFourOhOneKRouteSignature> {
  <template>
    <VStack>
      <TioPageBreadcrumbs as |b|>
        <b.crumb @route="authenticated.syf.dashboard" @label="Secure Your Future" />
        <b.crumb @route="authenticated.syf.insights.four-oh-one-k" @label="401k" />
      </TioPageBreadcrumbs>
      <Section>
        <:header>{{t "syf.insights.four_oh_one_k.heading"}}</:header>
        <:body>
          <div class="grid grid-cols-3">
            <div>
              <h2 class="text-lg mt-4">{{t "syf.insights.four_oh_one_k.insight.heading"}}</h2>
              <div class="flex mt-4">
                <div>
                  <Check class="text-green-700 w-4 h-4" />
                </div>
                <div class="ml-4">
                  {{t
                    "syf.insights.four_oh_one_k.insight.one"
                    salary=(formatCentsToDollars @model.matchParticipant.reportedSalary)
                    match=(formatCentsToDollars @model.insights.maxPossibleMatch)
                    match_statement=@model.matchPlan.matchStatement
                    htmlSafe=true
                  }}
                </div>
              </div>
              <div class="flex mt-4">
                <div>
                  <Check class="text-green-700 w-4 h-4" />
                </div>
                <div class="ml-4">
                  {{#if (gt @model.insights.defermentDeltaPerMonth 0)}}
                    {{t
                      "syf.insights.four_oh_one_k.insight.two"
                      monthly=(formatCentsToDollars @model.insights.defermentDeltaPerMonth)
                      htmlSafe=true
                    }}
                  {{else}}
                    {{t "syf.insights.four_oh_one_k.insight.two_no_action"}}
                  {{/if}}
                </div>
              </div>
            </div>
            <div class="col-span-2 ml-12">
              <h2 class="text-lg mt-4">{{t "syf.insights.four_oh_one_k.match.heading"}}</h2>
              <div class="my-4 flex gap-4">
                <NumberCircle @number="1" />
                <div>
                  {{t
                    "syf.insights.four_oh_one_k.match.one"
                    monthly=(formatCentsToDollars @model.matchParticipant.reportedMonthlyPayment)
                    annually=(formatCentsToDollars @model.insights.yearlyMatch)
                    htmlSafe=true
                  }}
                </div>
              </div>
              <div class="my-4 flex gap-4">
                <NumberCircle @number="2" />
                <div>
                  {{#if (gt @model.insights.defermentDeltaPerMonth 0)}}
                    {{t
                      "syf.insights.four_oh_one_k.match.two"
                      monthly=(formatCentsToDollars @model.insights.defermentDeltaPerMonth)
                      annually=(formatCentsToDollars @model.insights.defermentDelta)
                      annual_unlock=(formatCentsToDollars @model.insights.matchDelta)
                      htmlSafe=true
                    }}
                  {{else}}
                    {{t "syf.insights.four_oh_one_k.match.two_no_action"}}
                  {{/if}}
                </div>
              </div>
              <div class="my-4">
                {{t
                  "syf.insights.four_oh_one_k.match.three"
                  maximum=(formatCentsToDollars @model.insights.maxPossibleMatch)
                  htmlSafe=true
                }}
              </div>
            </div>
          </div>
        </:body>
      </Section>
    </VStack>
  </template>
}
