import { action } from '@ember/object';
import { service } from '@ember/service';
import type AccountModel from 'tio-common/models/account';
import Component from '@glimmer/component';
import type RouterService from 'tio-employee/services/router';
import SlrAccountLinked from 'tio-employee/components/slr/account-linked';
import { t } from 'ember-intl';
import { Header, Section } from 'tio-ui/components/layout';

interface SyfLinkingConfirmationRouteSignature {
  Args: {
    model: AccountModel;
  };
}

export default class SyfLinkingConfirmationRouteComponent extends Component<SyfLinkingConfirmationRouteSignature> {
  @service declare router: RouterService;

  @action
  didConfirm() {
    this.router.transitionTo('authenticated.account-activity.index');
  }

  <template>
    <Header>{{t "account_linking.add_account"}}</Header>
    <Section>
      <:body>
        <SlrAccountLinked @account={{@model}} @onConfirmation={{this.didConfirm}} />
      </:body>
    </Section>
  </template>
}
