import { Header, Section, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import AddDependent from 'tio-employee/components/tuition-assistance/forms/add-dependents';
import pageTitle from 'ember-page-title/helpers/page-title';
import TioHeader from 'tio-common/components/tio/page/hero/index';
import TuitionAssistanceDashboardApplyToPrograms from 'tio-employee/components/tuition-assistance/dashboard/apply-to-programs';
import TuitionAssistanceDashboardManageDependents from 'tio-employee/components/tuition-assistance/dashboard/manage-dependents';
import TuitionAssistanceDashboardMyPrograms from 'tio-employee/components/tuition-assistance/dashboard/my-programs';
import TuitionAssistanceDependentTable from 'tio-employee/components/tuition-assistance/dashboard/dependent-table';
import type EmployeeModel from 'tio-common/models/employee';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import type { TOC } from '@ember/component/template-only';

export interface DependentProgramDashboardSignature {
  Args: {
    model: {
      hasDependents: boolean;
      employee: EmployeeModel;
      activeInstances: TasProgramInstanceModel[];
      templates: TasProgramTemplate[];
      pastInstances: TasProgramInstanceModel[];
    };
  };
}

const tmpl: TOC<DependentProgramDashboardSignature> = <template>
  {{pageTitle (t "dashboard")}}
  <VStack>
    <Header>{{t "tuition_assistance.default"}}</Header>
    <Section>
      <:header>{{t "common.tuition_assistance.dependent_programs"}}</:header>
      <:body>
        {{#if @model.employee.hasDependents}}
          <h3 class="font-semibold my-2">{{t
              "common.tuition_assistance.dependent_table.table_title"
            }}</h3>
          <TuitionAssistanceDependentTable @employee={{@model.employee}} />
          <div class="flex -ml-2 mt-4">
            <AddDependent @employee={{@model.employee}} class="justify-end" />
          </div>
        {{else}}
          <div class="flex">
            <div class="w-1/2">
              <TioHeader
                @headerBeginning="Apply for"
                @highlightText="Tuition Assistance"
                @headerEnd="Apply for Dependent Tuition Assistance to further your dependents’ training and education."
                @description="Take control of your professional development, expand your opportunities, and lead a fulfilling and meaningful life through continued education. Your dependents are also eligible!"
              />
            </div>
            <div class="flex w-1/2 justify-center">
              <TuitionAssistanceDashboardManageDependents @employee={{@model.employee}} />
            </div>
          </div>
        {{/if}}
      </:body>
    </Section>
    <Section>
      <:body>
        <TuitionAssistanceDashboardMyPrograms
          @programStatus="active"
          @programInstances={{@model.activeInstances}}
          @sectionHeader={{t "tuition_assistance.dashboard.active_programs"}}
        />
      </:body>
    </Section>
    <Section>
      <:body>
        <TuitionAssistanceDashboardApplyToPrograms
          @programTemplates={{@model.templates}}
          @programInstances={{@model.activeInstances}}
          class="mb-12"
        />
      </:body>
    </Section>
    {{#if @model.pastInstances.length}}
      <Section>
        <:body>
          <TuitionAssistanceDashboardMyPrograms
            @programStatus="past"
            @programInstances={{@model.pastInstances}}
            @sectionHeader={{t "tuition_assistance.dashboard.past_programs"}}
            class="mb-12"
          />
        </:body>
      </Section>
    {{/if}}
  </VStack>
</template>;

export default tmpl;
