import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { dropTask } from 'ember-concurrency';
import { eq, or } from 'tio-ui/utilities';
import { htmlSafe } from '@ember/template';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ConfirmModal from 'tio-common/components/tio/confirm/confirm-modal';
import ContributionsAccountingPayments from 'tio-employee/components/contributions/accounting-payments';
import jsonPrettyPrint from 'tio-common/helpers/json-pretty-print';
import PlanSummary from 'tio-employee/components/plan/summary';
import ProfileFamilyInvites from 'tio-common/components/profile/family-invites';
import ProfileNotificationHistories from 'tio-common/components/profile/notification-histories';
import ProfilePslf from 'tio-common/components/profile/pslf';
import ProfileRecommendations from 'tio-common/components/profile/recommendations';
import ProfileRoleDelgation from 'tio-common/components/profile/role-delegation/delegation';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import UpdateEmployeeOverrideTable from 'tio-common/components/profile/update-employee-override-table';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TioQuicklinkButton from 'tio-common/components/tio/quicklink-button';
import TioQuicklinks from 'tio-common/components/tio/quicklinks';
import TuitionAssistanceProfileTuitionAssistance from 'tio-employee/components/tuition-assistance/profile/tuition-assistance';
import type ContributionModel from 'tio-common/models/contribution';
import type EmployeeModel from 'tio-common/models/employee';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import { Header, HStack, Section, VStack } from 'tio-ui/components/layout';
import type { TOC } from '@ember/component/template-only';
import { on } from '@ember/modifier';
import TioActiveStatusPill from 'tio-common/components/tio/active-status-pill';

const string = String;

interface UiDetailsBoxSignature {
  Args: {
    details: string;
    detailsLabel: string;
    goToPlans?: () => void;
  };
  Element: HTMLDivElement;
}

const UiDetailsBox: TOC<UiDetailsBoxSignature> = <template>
  <div class="rounded-md text-center shadow-sm" ...attributes>
    <div class="py-6">
      <span class="font-semibold text-xl">{{@details}}</span>
      <br />
      {{#if @goToPlans}}
        <button
          class="text-lg underline outline-none text-ocean-600 cursor-pointer"
          type="button"
          {{on "click" @goToPlans}}
        >
          {{t "partner.employee.plan_details"}}
        </button>
      {{else}}
        <span class="text-lg">{{@detailsLabel}}</span>
      {{/if}}
    </div>
  </div>
</template>;

export interface S {
  Args: {
    model: EmployeeModel;
    plan: ContributionModel;
  };
}

export default class EmployeeShowRoute extends Component<S> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  @tracked showEmployeeMenu = false;
  @tracked showActivationModal = false;
  @tracked changelog = '';
  @tracked showPlans = false;
  @tracked submitError?: unknown;
  @tracked hasSubmitted: boolean = false;

  @action
  goToPlans() {
    this.router.transitionTo('authenticated.admin.plans', this.args.model.company.id);
  }

  @action
  toggleShowPlans() {
    this.showPlans = !this.showPlans;
  }

  @action
  toggleActivationModal() {
    this.showEmployeeMenu = false;
    this.showActivationModal = !this.showActivationModal;
  }

  @action
  toggleEmployeeMenu() {
    this.showEmployeeMenu = !this.showEmployeeMenu;
  }

  get serverErrorMessages() {
    const genericErrorMessage = this.intl.t('error_occurred');
    // @ts-expect-error: errors is not typed
    if (this.submitError?.errors) {
      // @ts-expect-error: errors is not typed
      return this.submitError.errors.map((err) => {
        return err?.detail || genericErrorMessage;
      });
    } else if (this.submitError) {
      return [genericErrorMessage];
    }
    return [];
  }

  deactivateOrReactivateEmployee = dropTask(async () => {
    const employee = this.args.model;
    this.submitError = null;
    this.hasSubmitted = true;
    try {
      if (
        employee.participationStatus === 'participating' ||
        employee.participationStatus === 'pending'
      ) {
        await this.store
          .adapterFor('employee')
          .terminateEmployee(employee, { changelog: this.changelog });
      } else if (employee.participationStatus === 'deactivated') {
        await this.store
          .adapterFor('employee')
          .reinstateEmployee(employee, { changelog: this.changelog });
      }
      this.showActivationModal = false;
      this.changelog = '';
    } catch (err) {
      this.submitError = err;
      console.error(err);
    }
  });

  get payrollId() {
    return (
      (this.args.model.employeeAddition?.processed?.merged?.['payrollId'] as string) ||
      this.args.model.payrollId ||
      '-'
    );
  }

  get fullName() {
    const firstName =
      this.args.model.employeeAddition?.processed?.merged?.['firstName'] ||
      this.args.model.firstName;

    const lastName =
      this.args.model.employeeAddition?.processed?.merged?.['lastName'] || this.args.model.lastName;

    return `${firstName} ${lastName}`;
  }

  <template>
    <VStack>
      <TioPageBreadcrumbs as |b|>
        <b.crumb
          class="!relative !inline-block"
          @route="authenticated.admin.employees.index"
          @label="Employees"
        />
        <b.crumb
          class="!relative !inline-block"
          @route="authenticated.admin.employees.show"
          @label={{@model.fullName}}
        />
      </TioPageBreadcrumbs>
      <Header>
        {{t "partner.employee.employee_details"}}
        <TioQuicklinks
          @direction="right"
          @show={{this.showEmployeeMenu}}
          @toggle={{this.toggleEmployeeMenu}}
        >
          <TioQuicklinkButton
            @isDisabled={{if
              (or
                (eq @model.participationStatus "pending")
                (eq @model.participationStatus "participating")
              )
              true
              false
            }}
            @buttonText={{t "partner.employee.reactivate"}}
            @method={{this.toggleActivationModal}}
          />
          <br />
          <TioQuicklinkButton
            @isDisabled={{if
              (or
                (eq @model.participationStatus "pending")
                (eq @model.participationStatus "participating")
              )
              false
              true
            }}
            @buttonText={{t "partner.employee.deactivate"}}
            @method={{this.toggleActivationModal}}
          />
          <br />
        </TioQuicklinks>
      </Header>
      <HStack>
        <UiDetailsBox
          @details={{string @model.statistics.user.total_accounts}}
          @detailsLabel={{t "partner.employee.total_accounts"}}
          class="p-4 bg-white"
        />
        <UiDetailsBox
          @details={{string @model.statistics.user.total_loans}}
          @detailsLabel={{t "partner.employee.total_loans"}}
          class="p-4 bg-white"
        />
        <UiDetailsBox
          @details={{@model.participationStatus}}
          @detailsLabel={{t "partner.employee.status"}}
          class="p-4 bg-white"
        />
        <UiDetailsBox
          @details={{concat @model.plan.companyKey " - " @model.plan.name}}
          @detailsLabel={{t "partner.employee.plan_details"}}
          @goToPlans={{this.goToPlans}}
          class="p-4 bg-white"
        />
      </HStack>

      <Section>
        <:header>{{t "partner.employee.employee_profile"}}</:header>
        <:body>
          <VStack @collapsed={{true}}>
            <VStack>
              <HStack @collapsed={{true}} class="divide-transparent">
                <span class="capitalize font-semibold">{{this.fullName}}</span>
                <TioActiveStatusPill @isActive={{@model.isActive}} />
                {{#if @model.isActive}}
                  <span class="text-gray-500 ml-1">(
                    {{safeFormatDate
                      @model.createdAt
                      month="long"
                      day="2-digit"
                      year="numeric"
                    }})</span><br />
                {{else if @model.deactivatedOn}}
                  <span class="text-gray-500 ml-1">
                    ({{safeFormatDate
                      @model.deactivatedOn
                      month="long"
                      day="2-digit"
                      year="numeric"
                    }})</span><br />
                {{else}}
                  <span class="text-gray-500 ml-1">
                    ({{safeFormatDate
                      @model.updatedAt
                      month="long"
                      day="2-digit"
                      year="numeric"
                    }})</span><br />
                {{/if}}
              </HStack>
              <div class="flex gap-12">
                <Section>
                  <label class="flex capitalize">{{t "common.employees.fields.employee_id"}}</label>
                  <span>{{@model.id}}</span>
                </Section>
                <Section>
                  <label class="flex capitalize">{{t "common.employees.fields.payroll_id"}}</label>
                  <span>{{this.payrollId}}</span>
                </Section>
              </div>
              <UpdateEmployeeOverrideTable @employee={{@model}} />
            </VStack>
          </VStack>
        </:body>
      </Section>
      <Section>
        <:header>{{t "partner.employee.custom_partner_data"}}</:header>
        <:body>
          <VStack>
            {{#each-in @model.employeeAddition.allAttributes as |key value|}}
              <Section @collapsed={{true}}>
                <:header>{{key}}</:header>
                <:body>{{htmlSafe (jsonPrettyPrint value)}}</:body>
              </Section>
            {{/each-in}}
          </VStack>
        </:body>
      </Section>
      <Section @collapsed={{true}}>
        <:header>{{t "partner.employee.payment_transactions"}}</:header>
        <:body>
          <ContributionsAccountingPayments @employee={{@model}} as |payments|>
            {{! @glint-expect-error: not sure}}
            <payments.table class="w-full" />
            {{! @glint-expect-error: not sure}}
            {{#if payments.paymentsData.length}}
              <div class="w-full text-center text-sm p-6 text-gray-600">
                {{! @glint-expect-error: not sure}}
                {{payments.paymentsData.length}}
                {{t "of"}}
                {{! @glint-expect-error: not sure}}
                {{payments.paymentsData.length}}
                {{t "contributions.payments"}}
              </div>
            {{/if}}
          </ContributionsAccountingPayments>
        </:body>
      </Section>

      <PlanSummary
        {{! @glint-expect-error: this could be a bug. models are getting mixed }}
        @changelog={{@changelog}}
        @employee={{@model}}
        @plan={{@plan}}
        {{! @glint-expect-error: this could be a bug. models are getting mixed }}
        @model={{@model}}
        id="plan"
      />

      <ProfileRecommendations @user={{@model.user}} @recommendationType="STRATEGY_FINDER" />
      <ProfilePslf id="pslf_forms" @employee={{@model}} @isPartner={{true}} />
      <ProfileRoleDelgation @employee={{@model}} />

      <TuitionAssistanceProfileTuitionAssistance id="tuition-assistance" @employee={{@model}} />

      <ProfileNotificationHistories id="emails" @employee={{@model}} @user={{@model.user}} />

      {{#if @model.company.companySetting.allowFamilyPlan}}
        <ProfileFamilyInvites id="family" @employees={{@model.employees}} />
      {{/if}}
    </VStack>
    <ConfirmModal
      @show={{this.showActivationModal}}
      @cancel={{this.toggleActivationModal}}
      @submitAction={{this.deactivateOrReactivateEmployee}}
      @submitButtonText={{if
        (or
          (eq @model.participationStatus "pending") (eq @model.participationStatus "participating")
        )
        (t "partner.employee.deactivate")
        (t "partner.employee.reactivate")
      }}
      @modalBodyText={{if
        (or
          (eq @model.participationStatus "pending") (eq @model.participationStatus "participating")
        )
        (t "partner.employee.deactivate_text")
        (t "partner.employee.reactivate_text")
      }}
      @changelog={{this.changelog}}
      @hasSubmitted={{this.hasSubmitted}}
      @serverErrorMessages={{this.serverErrorMessages}}
    />
  </template>
}
