import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';

const DashboardWidgetsConsolidationRefinanceComponent = <template>
  <LinkTo @route="authenticated.repaying-student-debt.repayment-options.index">
    <Tile @headerText={{t "dashboard_tiles.consolidation_and_refinancing"}}>
      <:description>
        {{t "dashboard_tiles.learn_about_repayment"}}
      </:description>
      <:image>
        {{svgJar "questions" width="100%" height="100%" role="img" desc=(t "svg.questions")}}
      </:image>
    </Tile>
  </LinkTo>
</template>;

export default DashboardWidgetsConsolidationRefinanceComponent;
