import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { Modal, Footer } from 'tio-ui/components/modal';
import { not } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { Input } from 'tio-ui/components/forms';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import type EmployeeModel from 'tio-common/models/employee';
import type EmployeeService from 'tio-employee/services/employee';
import type StoreService from 'tio-common/services/store';
import UserPlus from 'ember-static-heroicons/components/outline-24/user-plus';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';

export interface AddDependentSignature {
  Args: {
    employee: EmployeeModel;
    // Primarily for test runner
    renderInPlace?: boolean;
  };
  Element: HTMLDivElement;
}
export interface DependentRelationship {
  type: string;
  name: string;
}

const relationships = [
  { type: 'DependentRelationship.CHILD', name: 'Child' },
  { type: 'DependentRelationship.SPOUSE', name: 'Spouse' },
  { type: 'DependentRelationship.PARTNER', name: 'Partner' },
];

export default class AddDependent extends Component<AddDependentSignature> {
  @service declare store: StoreService;
  @service declare employee: EmployeeService;

  @tracked show = false;
  @tracked firstName?: string;
  @tracked lastName?: string;
  @tracked birthDate?: string;
  @tracked relationship?: DependentRelationship;
  @tracked isSaving = false;

  get submitEnabled() {
    return this.firstName && this.lastName && this.birthDate && this.relationship;
  }

  get dependentRelationship() {
    return this.relationship!.type;
  }

  get birthDateErrors() {
    const now = new Date().toISOString();
    if (this.birthDate! > now) {
      return ['Birth Date cannot be in the future.'];
    }
    return [];
  }

  clearFormData() {
    this.firstName = '';
    this.lastName = '';
    this.birthDate = '';
    this.relationship = { type: '', name: '' };
  }

  @action
  openDependentDialog() {
    this.show = !this.show;
  }

  @action
  async submit() {
    this.isSaving = true;
    let employee = this.args.employee;
    let dependents = this.args.employee.dependents;
    let newDependent = this.store.createRecord('dependent', {
      firstName: this.firstName!,
      lastName: this.lastName!,
      birthDate: this.birthDate!,
      relationship: this.dependentRelationship,
      employee: employee,
    });
    dependents.push(newDependent);
    await newDependent.save();
    this.clearFormData();
    this.isSaving = false;
    this.show = !this.show;
  }

  @action
  cancel() {
    this.show = !this.show;
  }

  <template>
    <div class="flex w-full" ...attributes>
      <Button
        {{on "click" this.openDependentDialog}}
        @appearance="outlined"
        @intent="primary"
        data-test-open-add-dependent-dialog
      >
        <span class="flex items-center">
          <UserPlus class="w-5 mr-2" />
          <p class="mt-auto">{{t "common.tuition_assistance.add_dependent_button"}}</p>
        </span>
      </Button>
    </div>
    <Modal
      @isOpen={{this.show}}
      @onClose={{this.cancel}}
      @renderInPlace={{@renderInPlace}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Header>
        <h1 data-test-add-dependent-dialog>
          {{t "common.tuition_assistance.add_dependent_modal.header"}}
        </h1>
      </m.Header>
      <m.Body>
        <form class="bg-white md:col-span-2 pb-8">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <Input
                @label={{t "common.tuition_assistance.add_dependent_modal.first_name"}}
                @value={{this.firstName}}
                @onChange={{fn (mut this.firstName)}}
                data-test-add-dependent-first-name
              />
            </div>

            <div class="sm:col-span-3">
              <Input
                @label={{t "common.tuition_assistance.add_dependent_modal.last_name"}}
                @value={{this.lastName}}
                @onChange={{fn (mut this.lastName)}}
                data-test-add-dependent-last-name
              />
            </div>

            <div class="sm:col-span-6 mr-6">
              <div class="w-1/2 mt-2">
                <FormSelect
                  data-legacy-input
                  @label={{t "common.tuition_assistance.add_dependent_modal.relationship"}}
                  @options={{relationships}}
                  @selected={{this.relationship}}
                  @onChange={{fn (mut this.relationship)}}
                  {{! @glint-expect-error}}
                  @renderInPlace={{true}}
                  @containerClass="t-select-relationship"
                  as |relationship|
                >
                  {{! @glint-expect-error}}
                  <p>{{relationship.name}}</p>
                </FormSelect>
              </div>
            </div>

            <div class="sm:col-span-3">
              <div class="mt-2">
                <Input
                  @label={{t "common.tuition_assistance.add_dependent_modal.birth_date"}}
                  @type="date"
                  @value={{this.birthDate}}
                  @onChange={{fn (mut this.birthDate)}}
                  @errors={{this.birthDateErrors}}
                  data-test-add-dependent-birth-date
                />
              </div>
            </div>
          </div>
        </form>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{this.isSaving}}
          @onSubmit={{this.submit}}
          @onCancel={{this.cancel}}
          @submitText="Add"
          @submitDisabled={{not this.submitEnabled}}
        />
      </m.Footer>
    </Modal>
  </template>
}
