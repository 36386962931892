import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { htmlSafe } from '@ember/template';
import { Modal, Footer } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';
import Component from '@glimmer/component';
import type ConditionModel from 'tio-common/models/condition';
import type Owner from '@ember/owner';
import { contains } from 'tio-ui/utilities';
import { Checkbox } from 'tio-ui/components/forms';

export interface TuitionAssistanceFormsAcceptConditionsSignature {
  Args: {
    conditions: readonly ConditionModel[];
    onChange: (value: ConditionModel[]) => void;
  };
}

export default class TuitionAssistanceFormsAcceptConditionsComponent extends Component<TuitionAssistanceFormsAcceptConditionsSignature> {
  // TODO: Not sure this is the correct type.
  @tracked conditionToView?: ConditionModel;
  acceptedTerms!: ConditionModel[];

  constructor(owner: Owner, args: TuitionAssistanceFormsAcceptConditionsSignature['Args']) {
    super(owner, args);
    this.acceptedTerms = new TrackedArray([]);
  }

  get isPdfCondition() {
    return this.conditionToView?.hasPdf;
  }

  @action
  didChangeAcceptanceOfTerm(condition: ConditionModel, value?: string) {
    if (value) {
      this.acceptedTerms.push(condition);
    } else {
      const index = this.acceptedTerms.indexOf(condition);
      if (index !== -1) {
        this.acceptedTerms.splice(index, 1);
      }
    }
    this.args.onChange?.([...this.acceptedTerms]);
  }

  @action
  setConditionToView(condition: ConditionModel) {
    this.conditionToView = condition;
  }

  @action
  close() {
    this.conditionToView = undefined;
  }

  <template>
    <div class="flex flex-col items-center justify-center">
      {{#each @conditions as |condition|}}
        <div class="flex justify-between items-center my-2 w-full">
          <div class="flex items-center">
            <Checkbox
              @size="lg"
              @checked={{contains this.acceptedTerms condition}}
              {{! @glint-expect-error: mixing string and boolean }}
              @onChange={{fn this.didChangeAcceptanceOfTerm condition}}
              name="condition-prompt"
              class="inline mr-1"
            />
            <label for="condition-prompt" class="text-pretty">{{condition.prompt}}
            </label>
          </div>
          {{#if condition.hasPdf}}
            <a
              type="button"
              class="text-ocean-600 font-medium text-sm uppercase ml-2"
              href={{condition.pdfUrl}}
              target="_blank"
              rel="noopener noreferrer"
            >
              {{t "view"}}
            </a>
          {{else}}
            <button
              type="button"
              class="text-ocean-600 font-medium text-sm uppercase ml-2"
              {{on "click" (fn this.setConditionToView condition)}}
            >
              {{t "view"}}
            </button>
          {{/if}}
        </div>
      {{/each}}
    </div>

    {{#if this.conditionToView}}
      <Modal @isOpen={{true}} @onClose={{this.close}} class="min-h-64" @backdrop="faded" as |m|>
        <m.Header>
          <p class="font-semibold">{{this.conditionToView.prompt}}</p>
        </m.Header>
        <m.Body>
          {{htmlSafe this.conditionToView.fullText}}
        </m.Body>
        <m.Footer>
          <Footer @isRunning={{false}} @onSubmit={{this.close}} @submitText={{t "close"}} />
        </m.Footer>
      </Modal>
    {{/if}}
  </template>
}
