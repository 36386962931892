import TuitionAssistanceFormsPreApprovalApplicationReview from 'tio-employee/components/tuition-assistance/forms/pre-approval/application-review';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { t } from 'ember-intl';
import type TasGroupModel from 'tio-common/models/tas-group';
import { Section } from 'tio-ui/components/layout';
import type { TOC } from '@ember/component/template-only';

interface S {
  Args: {
    model: {
      programInstance: TasProgramInstanceModel;
      tasGroups: TasGroupModel[];
      activeInstances: TasProgramInstanceModel[];
    };
  };
}

const tmpl: TOC<S> = <template>
  <Section>
    <:header>{{t "tuition_assistance.preapproval_app.application_review"}}</:header>
    <:body>
      <TuitionAssistanceFormsPreApprovalApplicationReview
        @programInstance={{@model.programInstance}}
        @tasGroups={{@model.tasGroups}}
        @activeInstances={{@model.activeInstances}}
      />
    </:body>
  </Section>
</template>;

export default tmpl;
