import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { Section, VStack } from 'tio-ui/components/layout';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppReadSecurityButton from 'tio-employee/components/app/read-security-button';
import Component from '@glimmer/component';
import PslfMyDocumentsMyFormsTable from 'tio-employee/components/pslf/my-documents/my-forms-table';
import PslfMyDocumentsPslfUploadModal from 'tio-employee/components/pslf/my-documents/pslf-upload-modal';
import PslfMyDocumentsUploadsTable from 'tio-common/components/pslf/my-documents/uploads-table';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type PslfProfileModel from 'tio-common/models/pslf-profile';

interface S {
  Args: {
    model: PslfProfileModel;
  };
}

export default class PslfMyDocumentsRoute extends Component<S> {
  @tracked showUploadModal = false;
  @tracked limit = 10;

  @action
  openUploadModal() {
    this.showUploadModal = true;
  }

  @action
  closeUploadModal() {
    this.showUploadModal = false;
  }

  <template>
    <VStack>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb @route="authenticated.pslf.dashboard.index" @label="Dashboard" />
        <b.crumb @route="authenticated.pslf.my-documents" @label="Forms & Documents" />
      </TioPageBreadcrumbs>

      <Section>
        <:header>{{t "pslf.document_repo.my_pslf_forms"}}</:header>
        <:actions>
          <Button @intent="primary" {{on "click" this.openUploadModal}}>
            {{t "upload"}}
          </Button>
        </:actions>
        <:body>
          <PslfMyDocumentsMyFormsTable @limit={{this.limit}} @employee={{@model.employee}} />
        </:body>
      </Section>
      <Section>
        <:header>{{t "pslf.profiles.uploads"}}</:header>
        <:body>
          <PslfMyDocumentsUploadsTable
            @limit={{this.limit}}
            @profile={{@model}}
            @allowDelete={{true}}
          />
        </:body>
      </Section>
      <AppReadSecurityButton />
    </VStack>
    {{#if this.showUploadModal}}
      <PslfMyDocumentsPslfUploadModal
        class="mx-5"
        @onCancel={{this.closeUploadModal}}
        @didSave={{this.closeUploadModal}}
        @profile={{@model}}
        @employee={{@model.employee}}
      />
    {{/if}}
  </template>
}
