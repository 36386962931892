import { array } from '@ember/helper';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import ProgressTracker from 'tio-common/components/tio/progress-tracker';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TuitionAssistanceFormsCertificateSubmitForReview from 'tio-employee/components/tuition-assistance/forms/certificate/submit-for-review';
import TuitionAssistanceFormsChangeRequestAlert from 'tio-employee/components/tuition-assistance/forms/change-request-alert';
import TuitionAssistanceLimitsSummary from 'tio-common/components/tuition-assistance/limits-summary';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsCancelRequest from 'tio-employee/components/tuition-assistance/program-details/cancel-request';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import type { Step } from 'tio-common/components/tio/progress-tracker';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type TasApplication from 'tio-common/models/tas-application';

interface TAProgramInstanceCourseApplicationsShowCertificateRouteComponentSignature {
  Args: {
    model: TasApplication;
  };
}

export default class TuitionAssistanceProgramInstanceCourseApplicationsShowCertificateRouteComponent extends Component<TAProgramInstanceCourseApplicationsShowCertificateRouteComponentSignature> {
  @service declare store: typeof Store;
  @service declare router: RouterService;
  @service declare progressBuilder: ProgressBuilder;

  get certificateCourse() {
    return this.args.model?.tasCourses?.[0];
  }

  get steps() {
    return this.progressBuilder.applicationStepList(this.args.model) as Step[];
  }

  <template>
    {{pageTitle (t "tuition_assistance.program_details.default")}}

    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>
      <TioPageBreadcrumbs as |b|>
        <b.crumb @route="authenticated.tas.dashboard" @label={{t "dashboard"}} />
        <b.crumb
          @route="authenticated.tuition-assistance.programs.instance.course-applications.show"
          @label={{@model.tasProgramInstance.programName}}
        />
      </TioPageBreadcrumbs>

      <Section>
        <div class="my-8">
          <ProgressTracker class="hidden md:block" @steps={{this.steps}} />
        </div>
        <TuitionAssistanceFormsChangeRequestAlert
          @transitionState="EVIDENCE_NOT_APPROVED"
          @transitionLog={{@model.transitionLog}}
        />
        <div class="flex flex-wrap mb-6 mt-4 gap-6">
          <TuitionAssistanceProgramDetailsProgramInfoCard
            @programInstance={{@model.tasProgramInstance}}
            @showAttachments={{true}}
          />
          <div class="flex flex-col flex-wrap gap-4 w-full md:w-max">
            <TuitionAssistanceLimitsSummary
              @application={{@model}}
              @employee={{@model.tasProgramInstance.employee}}
              @labelClass="col-span-5"
              @valueClass="col-span-2 text-violet-800"
              as |Summary|
            >
              {{#unless Summary.isUnlimitedMaximumAnnualBenefit}}
                <Summary.Container
                  @title={{t "tuition_assistance.program_details.program_benefits"}}
                  @titleClass="font-semibold"
                  class="md:max-w-md"
                >
                  <Summary.MaxAnnualBenefitLineItem />
                </Summary.Container>
              {{/unless}}
            </TuitionAssistanceLimitsSummary>
            <TuitionAssistanceProgramDetailsApplicationInformation
              @application={{@model}}
              @isAdminView={{false}}
              @canRequestRepayment={{false}}
              @requestingApp="employee"
            />
          </div>
        </div>
        {{! @glint-expect-error investigate. Is this optional or not?}}
        <TuitionAssistanceFormsCertificateSubmitForReview @course={{this.certificateCourse}} />

        <TuitionAssistanceProgramDetailsAppDetailsAppHistory
          @application={{@model}}
          @viewType="employee"
        />
        <TuitionAssistanceProgramDetailsCancelRequest
          @application={{@model}}
          @modelTypes={{array "tas-application" "tas-program-instance"}}
          @linkText={{t "tuition_assistance.program_details.cancel_app.delete_application"}}
          @canCancel={{@model.canCancelApplication}}
        />
      </Section>
    </VStack>
  </template>
}
