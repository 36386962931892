import { array } from '@ember/helper';
import { Header, VStack } from 'tio-ui/components/layout';
import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import TuitionAssistanceProgramDetailsCancelRequest from 'tio-employee/components/tuition-assistance/program-details/cancel-request';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type { TOC } from '@ember/component/template-only';

export interface S {
  Args: {
    model: TasProgramInstanceModel;
  };
}
const tmpl: TOC<S> = <template>
  {{pageTitle (t "tuition_assistance.preapproval_app.default")}}
  <VStack>
    <Header>{{t "tuition_assistance.default"}}</Header>
    {{outlet}}
    <TuitionAssistanceProgramDetailsCancelRequest
      @application={{@model}}
      @modelTypes={{array "tas-program-instance"}}
      @canCancel={{true}}
      @linkText={{t "tuition_assistance.program_details.cancel_app.delete_program"}}
    />
  </VStack>
</template>;

export default tmpl;
