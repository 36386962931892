import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type StoreService from 'tio-common/services/store';

export default class AuthenticatedAdminEmployeesShowRoute extends Route {
  @service declare store: StoreService;

  includes = [
    'company',
    'user',
    'user.accounts',
    'user.recommendations',
    'plan',
    'jurisdictive-relationships.delegate-of',
    'tags',
    'employee-addition',
    'tas-participant',
    'tas-eligibilities.tas-program-template',
  ];

  model(params: { employee_id: string }) {
    const query = {
      include: this.includes.join(','),
      'fields[user]': ['created-at', 'updated-at', 'id'].join(','),
    };

    const employee = this.store.findRecord('employee', params.employee_id, {
      backgroundReload: true,
      ...query,
    });

    return employee;
  }
}
