import { eq, or } from 'tio-ui/utilities';
import { getObservableProductRoute } from 'tio-employee/services/observability';
import { getStatus } from 'tio-common/helpers/observability';
import { hash } from '@ember/helper';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import ActionableTranslation from 'tio-ui/components/actionable-translation';
import Badge from 'tio-ui/components/badge';
import Component from '@glimmer/component';
import DocumentArrowUp from 'ember-static-heroicons/components/outline-24/document-arrow-up';
import Envelope from 'ember-static-heroicons/components/outline-24/envelope';
import ExtractionSummary from 'tio-employee/components/observability/extraction-summary';
import ObservabilityConfirmController from 'tio-employee/controllers/authenticated/observability/upload';
import type { Intent } from 'tio-ui/utilities';
import type { ObservableSource } from 'tio-employee/services/observability';
import type IntlService from 'ember-intl/services/intl';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';

interface ObservabilityConfirmSignature {
  Args: {
    controller: ObservabilityConfirmController;
    model: ObservableDocumentModel;
  };
}

const getStatusIntent = (status: string): Intent | undefined => {
  switch (status) {
    case 'PROCESSING':
      return 'primary';
    case 'INVALID':
    case 'FAILED':
      return 'error';
    case 'PROCESSED':
    case 'APPLIED':
      return 'success';
    case 'IN_DISPUTE':
    case 'PARTIALLY_APPLIED':
      return 'warning';
  }
};

const getSubmittedIcon = (via: string) => {
  if (via === 'EMAIL') {
    return Envelope;
  }
  return DocumentArrowUp;
};

export default class ObservabilityConfirm extends Component<ObservabilityConfirmSignature> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare sessionContext: SessionContextService;

  get productSource(): ObservableSource {
    return <ObservableSource>this.args.controller.source;
  }

  get documentCreated() {
    const observableDocument = this.args.model;
    const createdAtDate = new Date(observableDocument.createdAt);
    const date = createdAtDate.toLocaleDateString();
    const time = createdAtDate.toLocaleTimeString();
    const via = this.intl.t(`observability.${observableDocument.via}`);
    return { via, date, time };
  }

  get employeeEmail() {
    return this.sessionContext.user?.primaryEmail?.email;
  }

  get subtitleSections() {
    const result = this.intl.t('observability.confirmation.subtitle', {
      email: this.employeeEmail,
    });
    return result.split('\n');
  }

  get failedSubtitleSections() {
    const result = this.intl.t('observability.confirmation.subtitle', {
      documentType: this.documentTypeName,
    });
    return result.split('\n');
  }

  get amendUrl() {
    return this.router.urlFor('authenticated.observability.dispute');
  }

  get documentType(): 'nslds' | 'statement' | 'transaction_history' | undefined {
    const observableDocument = this.args.model;
    switch (observableDocument.provider) {
      case 'ObservableProvider.STATEMENT':
        return 'statement';
      case 'ObservableProvider.NSLDS':
        return 'nslds';
      case 'ObservableProvider.ACCOUNT_TXN_HISTORY':
        return 'transaction_history';
    }
  }

  get documentTypeName() {
    switch (this.documentType) {
      case 'statement':
        return this.intl.t('observability.statement');
      case 'transaction_history':
        return this.intl.t('observability.transaction_history');
      case 'nslds':
        return this.intl.t('observability.nslds');
      default:
        return this.intl.t('observability.default_document');
    }
  }

  get transactionHistoryFailed() {
    const observableDocument = this.args.model;

    const conditions = [
      ['syf_payments'].includes(this.productSource),
      observableDocument.extractionState === 'ExtractionState.PROCESSED',
      observableDocument.reflectionState === 'ReflectionState.NOT_APPLIED',
    ];
    return conditions.every(Boolean);
  }

  get manualTransactionUrl() {
    // only SYF for now; generate URLS for other products here if necessary
    return this.router.urlFor('authenticated.syf.payments.new');
  }

  get showFurtherReviewStatement() {
    const observableDocument = this.args.model;
    return [
      observableDocument.provider === 'ObservableProvider.ACCOUNT_STATEMENT',
      observableDocument.reflectionState === 'ReflectionState.PARTIALLY_APPLIED',
    ].every(Boolean);
  }

  get showSubtitle() {
    const observableDocument = this.args.model;
    return observableDocument.provider === 'ObservableProvider.ACCOUNT_TXN_HISTORY';
  }

  // NB: the router's urlFor method seems to preserve route params including dynamic segments
  // here so no need to pass the observable document model or its ID; hard to find an explicit
  // characterization of this in the ember docs, though - james 20250115
  get disputeUrl(): string {
    return this.router.urlFor('authenticated.observability.dispute');
  }

  get documentPartiallyApplied() {
    const observableDocument = this.args.model;
    return observableDocument.reflectionState === 'ReflectionState.PARTIALLY_APPLIED';
  }

  get documentDisputable() {
    const observableDocument = this.args.model;
    const providerTypeDisputable = [
      'ObservableProvider.ACCOUNT_STATEMENT',
      'ObservableProvider.NSLDS',
    ].includes(observableDocument?.provider);

    return (
      providerTypeDisputable && observableDocument?.subsistenceState === 'SubsistenceState.IMPLICIT'
    );
  }

  <template>
    <VStack>
      {{#if this.transactionHistoryFailed}}
        <Section>
          <:header>{{t
              "observability.confirmation.failed_title"
              documentType=this.documentTypeName
            }}</:header>
          <:body>
            <p class="my-4">{{t
                "observability.confirmation.failed_subtitle"
                documentType=this.documentTypeName
              }}</p>
            <p class="my-4"><ActionableTranslation
                @t="observability.confirmation.manual_prompt"
                @links={{hash manualPaymentsLink=(hash t="here" href=this.manualTransactionUrl)}}
              /></p>
          </:body>
        </Section>
      {{else}}
        <Header>{{t "observability.confirmation.header" provider=this.documentType}}</Header>
        <Section>
          <:header>
            <hgroup class="mb-4">
              <h1>{{t "observability.confirmation.title" provider=this.documentType}}</h1>
              {{#if this.showSubtitle}}
                <p class="text-small font-normal">
                  {{#each this.subtitleSections as |section|}}
                    <span class="block mt-2">{{section}}</span>
                  {{/each}}
                </p>
              {{/if}}
            </hgroup>
            <h2 class="inline">{{@model.observableAsset.filename}}</h2>
            <Badge @intent={{getStatusIntent (getStatus @model)}} class="ml-4">
              {{t "observable_document.status" state=(getStatus @model)}}
            </Badge>
            <p class="italic flex items-center text-small font-normal mt-2">
              {{#let (getSubmittedIcon @model.via) as |SubmittedIcon|}}
                <SubmittedIcon class="w-6 mr-2" />
              {{/let}}
              {{t
                "observability.dispute.document.uploaded"
                via=this.documentCreated.via
                date=this.documentCreated.date
                time=this.documentCreated.time
              }}
            </p>
          </:header>
          <:body>
            <ExtractionSummary @document={{@model}} class="w-full md:w-2/3" />
            <footer>
              {{#if (or this.documentDisputable this.documentPartiallyApplied)}}
                <p class="my-8">
                  {{#if this.documentDisputable}}
                    {{! TODO: genericize this instead of stealing it from the confirmation page }}
                    <ActionableTranslation
                      @t="observability.confirmation.amend"
                      @links={{hash clickHere=(hash t="click_here_lowercase" href=this.disputeUrl)}}
                    />
                  {{else if this.documentPartiallyApplied}}
                    {{t "observability.document.summary.partially_applied"}}
                  {{/if}}
                </p>
              {{/if}}
              {{#if this.showFurtherReviewStatement}}
                <p class="my-8">
                  {{t "observability.document.summary.partially_applied"}}
                </p>
              {{/if}}
              {{#if (eq this.productSource "syf_enrollment")}}
                <LinkTo
                  @route="authenticated.syf.linking-confirmation"
                  @model={{@model.financialInstitution}}
                  class="tio-btn tio-btn-primary"
                >
                  {{t "observability.confirmation.continue" source=this.productSource}}
                </LinkTo>
              {{else if (eq this.productSource "syf_payments")}}
                <LinkTo @route="authenticated.syf.dashboard" class="tio-btn tio-btn-primary">
                  {{t "observability.confirmation.continue" source=this.productSource}}
                </LinkTo>
              {{else}}
                <LinkTo
                  @route={{getObservableProductRoute this.productSource}}
                  class="tio-btn tio-btn-primary"
                >
                  {{t "observability.confirmation.continue" source=this.productSource}}
                </LinkTo>
              {{/if}}
            </footer>
          </:body>
        </Section>
      {{/if}}
    </VStack>
  </template>
}
