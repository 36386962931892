import { service } from '@ember/service';
import { VStack } from 'tio-ui/components/layout';
import Component from '@glimmer/component';
import SecuritySettings from 'tio-employee/components/app/profile/my-profile/security-settings';
import type ProfileController from 'tio-employee/controllers/authenticated/profile';
import type SessionContextService from 'tio-employee/services/session-context';
import type UserModel from 'tio-common/models/user';

interface S {
  Args: {
    controller: ProfileController;
    model: UserModel;
  };
}
export default class ProfileRoute extends Component<S> {
  @service sessionContext!: SessionContextService;

  //  NOTE: This route should only be called if a user directly
  //  visits the '/' path within this app *while logged in*.
  get employee() {
    return this.sessionContext.currentEmployee;
  }

  <template>
    <VStack class="w-full">
      <SecuritySettings @user={{@model}} @employee={{this.employee}} />
    </VStack>
  </template>
}
